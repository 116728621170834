<template>
  <div
    v-if="isModalOpened"
    class="p-fixed pos-all-0 z-1003"
  >
    <div class="p-fixed pos-all-0 bg-form-darker opacity-8" @click="closeModal"></div>
    <div class="profile-modal p-absolute abs-center w-100">
      <a
        @click.prevent="closeModal()"
        class="p-absolute top-0 right--44px d-block w-20px h-20px"
      >
        <Icon class="w-100 h-100" name="cancel-btn" />
      </a>
      <div class="inside d-grid h-100 bs-controller br-5 overflow-hidden">
        <ProfileSidebar
          :links="links"
          :activeLinks="activeLinks"
        />
        <ProfileRouter
          :activeLinks="activeLinks"
        />
      </div>
    </div>
  </div>
  <DeleteAccount v-if="deleteAccountModal" />
</template>

<script>
import Icon from '@/components/app/Icon';
import ProfileSidebar from '@/components/profile/Sidebar';
import ProfileRouter from '@/components/profile/Router';
import DeleteAccount from '@/components/profile/DeleteAccount';
import { mapGetters } from 'vuex';
export default {
  components: {
    Icon,
    ProfileSidebar,
    ProfileRouter,
    DeleteAccount
  },
  data () {
    return {
      links: [
        { title: 'Settings', url: 'settings', icon: 'settings' }
        // { title: 'Plan', url: 'plan', icon: 'wallet' }
        // { title: 'Notifications', url: 'notifications', icon: 'notification' },
        // { title: 'Statistics', url: 'statistics', icon: 'chart' },
        // { title: 'FAQ', url: 'faq', icon: 'message-question' },
        // { title: 'Support', url: 'support', icon: 'message' }
      ]
    };
  },
  computed: {
    ...mapGetters(['deleteAccountModal']),
    isModalOpened () {
      return !!this.$route.query.profile && !this.deleteAccountModal;
    },
    activeLinks () {
      const activeLinks = {};
      this.links.forEach(l => {
        activeLinks[l.url] = (l.url === this.$route.query.profile);
      });
      return activeLinks;
    }
  },
  methods: {
    closeModal () {
      const query = Object.assign({}, this.$route.query);
      delete query.profile;
      this.$router.replace({ query });
    }
  }
};
</script>

<style lang="scss" scoped>
  .profile-modal {
    max-width: 1120px;
    height: calc(100vh - 96px);

    .inside {
      grid-template-columns: 176px auto;
    }
  }
</style>
