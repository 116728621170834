import router from '@/router/indexNew.js';

export default {
  state: {
    endpoints: [
      {
        url: '/api/endpoints/linkedin/search_people',
        searchType: 'name',
        social: 'linkedin'
      },
      {
        url: '/api/endpoints/instagram/search_people',
        searchType: 'name',
        social: 'instagram'
      },
      {
        url: '/api/endpoints/facebook/search_profiles/v2',
        searchType: 'name',
        social: 'facebook'
      },
      {
        url: '/api/endpoints/twitter/search/users',
        searchType: 'name',
        social: 'twitter'
      },
      {
        url: '/api/endpoints/social_mapper/linkedin/v2',
        searchType: 'face_and_name',
        social: 'linkedin'
      },
      {
        url: '/api/endpoints/social_mapper/instagram/v2',
        searchType: 'face_and_name',
        social: 'instagram'
      },
      {
        url: '/api/endpoints/social_mapper/facebook/v2',
        searchType: 'face_and_name',
        social: 'facebook'
      },
      {
        url: '/api/endpoints/social_mapper/twitter/v2',
        searchType: 'face_and_name',
        social: 'twitter'
      },
      {
        url: '/api/endpoints/facebook/to_entity',
        searchType: 'alias',
        social: 'facebook'
      },
      {
        url: '/api/endpoints/twitter/user/v2',
        searchType: 'alias',
        social: 'twitter'
      },
      {
        url: '/api/endpoints/facebook/search_profiles/v2',
        searchType: 'name_and_location',
        social: 'facebook'
      },
      {
        url: '/api/endpoints/linkedin/search_people_new',
        searchType: 'name_and_company',
        social: 'linkedin'
      },
      {
        url: '/api/endpoints/facebook/search_profiles/v2',
        searchType: 'name_and_company',
        social: 'facebook'
      },
      {
        url: '/api/endpoints/linkedin/lookup_by_email/v2',
        searchType: 'email',
        social: 'linkedin'
      },
      {
        url: '/api/endpoints/orientsearch/webdata_by_email',
        searchType: 'email',
        social: 'all'
      },
      {
        url: '/api/endpoints/orientsearch/webdata_by_phone',
        searchType: 'phone',
        social: 'all'
      }
    ]
  },
  mutations: {
    GET_SEARCH_TYPES () {
      const searchBy = [];
      if (this.name) {
        searchBy.push('name');
      }
      if (this.alias) {
        searchBy.push('alias');
      }
      if (this.email) {
        searchBy.push('email');
      }
      if (this.phone) {
        searchBy.push('phone');
      }
      if (this.profileUrl) {
        searchBy.push('profile_url');
      }
      if (this.profileID) {
        searchBy.push('profile_id');
      }
      if (this.name && (this.photo || this.photoUrl)) {
        searchBy.push('name,photo');
      }
      if (this.name && this.location) {
        searchBy.push('name,location');
      }
      if (this.name && this.education) {
        searchBy.push('name,education');
      }
      if (this.name && this.company) {
        searchBy.push('name,company');
      }
      return searchBy;
    }
  },
  getters: {
  },
  actions: {
    async setNewSearch ({ dispatch, state, commit, rootState }, data) {
      try {
        commit('SET_LOADER_BIG', true);
        const searchTypes = await dispatch('getSearchTypes', data);
        if (!Object.keys(searchTypes).length) return;
        const endpoints = await dispatch('selectEndpoints', searchTypes);
        const search = await dispatch('createNewSearchNEW', endpoints);

        search.nodes = [];
        const entities = [];
        for (const key of Object.keys(endpoints)) {
          const obj = {};
          obj.value = endpoints[key].value;
          obj.fields = [];
          obj.fields.push({
            label: 'search-type',
            name: 'search-type',
            value: key
          });
          obj.fields.push({
            label: key,
            name: key,
            value: endpoints[key].value
          });
          entities.push(obj);
        }
        await dispatch('createEntities', { search, entities });
        const response = await dispatch('fetchResultsNEW', { endpoints, entities });
        const allResults = await dispatch('makeResultsArray', response);

        if (!allResults.length) return;

        const results = await dispatch('convertResultsForSave', allResults);
        await dispatch('saveProfiles', { search, entities: results });
        router.push({ path: `/case/${router.currentRoute.value.params.id}/${search.rid}` });
        commit('SET_LOADER_BIG', false);
      } catch (error) {
        console.error(error);
        commit('SET_LOADER_BIG', false);
      }
    },
    async getSearchTypes (ctx, data) {
      const searchBy = {};
      data.forEach(option => {
        if (option.type === 'name') {
          searchBy.name = option.title;
        }
        if (option.type === 'alias') {
          searchBy.alias = option.title;
        }
        if (option.type === 'email') {
          searchBy.email = option.title;
        }
        if (option.type === 'phone') {
          searchBy.phone = option.title;
        }
        if (option.type === 'profileUrl') {
          searchBy.profileUrl = option.title;
        }
        if (option.type === 'profileID') {
          searchBy.profileID = option.title;
        }
        if (searchBy.name && option.type === 'photo') {
          searchBy.face_and_name = option.image || option.title;
        }
        if (searchBy.name && option.type === 'location') {
          searchBy.name_and_location = option.title;
        }
        if (data.name && data.education) {
          searchBy['name,education'] = option.title;
        }
        if (searchBy.name && option.type === 'company') {
          searchBy.name_and_company = option.title;
        }
      });
      return searchBy;
    },
    async selectEndpoints ({ state, dispatch }, searchTypes) {
      const endpoints = state.endpoints;
      const selectedEndpoints = {};
      for (const searchType of Object.keys(searchTypes)) {
        selectedEndpoints[searchType] = {};
        selectedEndpoints[searchType].endpoints = endpoints.filter(tr => tr.searchType === searchType);
        if (searchType === 'name') {
          selectedEndpoints[searchType].value = searchTypes[searchType];
          selectedEndpoints[searchType].endpoints.forEach(endpoint => {
            if (endpoint.social === 'facebook') {
              endpoint.url = endpoint.url + `?fullname=${searchTypes[searchType]}&limit=250`;
            } else {
              endpoint.url = endpoint.url + `?query=${searchTypes[searchType]}&limit=250`;
            }
          });
        }
        if (searchType === 'face_and_name') {
          if (typeof (searchTypes.face_and_name) === 'string') {
            selectedEndpoints[searchType].value = searchTypes.face_and_name;
            selectedEndpoints[searchType].endpoints.forEach(endpoint => {
              endpoint.url = endpoint.url + `?fullname=${searchTypes.name}&photo=${searchTypes.face_and_name}`;
            });
          } else {
            const obj = {
              name: searchTypes.name,
              photo: searchTypes.face_and_name
            };
            const photoUrl = await dispatch('uploadPhoto', obj);
            selectedEndpoints[searchType].value = photoUrl.photo;
            selectedEndpoints[searchType].endpoints.forEach(endpoint => {
              endpoint.url = endpoint.url + `?fullname=${searchTypes.name}&photo=${photoUrl.photo}`;
            });
          }
        }
        if (searchType === 'alias') {
          selectedEndpoints[searchType].value = searchTypes[searchType];
          selectedEndpoints[searchType].endpoints.forEach(endpoint => {
            if (endpoint.social === 'twitter') {
              endpoint.url = endpoint.url + `?username=${searchTypes[searchType]}`;
            } else {
              endpoint.url = endpoint.url + `?query=${searchTypes[searchType]}`;
            }
          });
        }
        if (searchType === 'name_and_location') {
          selectedEndpoints[searchType].value = searchTypes[searchType];
          selectedEndpoints[searchType].endpoints.forEach(endpoint => {
            endpoint.url = endpoint.url + `?fullname=${searchTypes.name}&city=${searchTypes.name_and_location}&limit=250`;
          });
        }
        if (searchType === 'phone') {
          selectedEndpoints[searchType].value = searchTypes[searchType];
          selectedEndpoints[searchType].endpoints.forEach(endpoint => {
            endpoint.url = endpoint.url + `?query=${searchTypes[searchType]}&limit=250`;
          });
        }
        if (searchType === 'name_and_company') {
          selectedEndpoints[searchType].value = searchTypes[searchType];
          selectedEndpoints[searchType].endpoints.forEach(endpoint => {
            if (endpoint.social === 'linkedin') {
              endpoint.url = endpoint.url + `?query=${searchTypes.name}&CC_name=${searchTypes.name_and_company}&limit=250`;
            } else {
              endpoint.url = endpoint.url + `?fullname=${searchTypes.name}&company=${searchTypes.name_and_company}&limit=250`;
            }
          });
        }
        if (searchType === 'email') {
          selectedEndpoints[searchType].value = searchTypes[searchType];
          selectedEndpoints[searchType].endpoints.forEach(endpoint => {
            endpoint.url = endpoint.url + `?query=${searchTypes[searchType]}`;
          });
        }
      }
      return selectedEndpoints;
    },
    async createNewSearchNEW ({ dispatch, rootState, getters }, endpoints) {
      if (!getters.getCurrentCase) {
        return;
      }
      try {
        const payload = {
          method: 'POST',
          url: '/api/user/recnew',
          cancelToken: rootState.results.resultsCancelToken.token,
          body: {
            fid: getters.getCurrentCase.oid,
            tag: '',
            query: {}
          }
        };
        for (const key of Object.keys(endpoints)) {
          payload.body.query[key] = endpoints[key].value;
        }
        Object.keys(payload.body.query).forEach((queryName, idx, arr) => {
          const splitter = (idx === arr.length - 1) ? '' : ' ';
          const fieldName = queryName.charAt(0).toUpperCase() + queryName.slice(1);
          payload.body.tag += `${fieldName}: ${payload.body.query[queryName]}${splitter}`;
        });

        const resp = await dispatch('ajaxWithTokenRefresh', payload);
        if (resp.status === 200) {
          await dispatch('addSearchToCase', resp.data);
          return resp.data;
        }
      } catch (e) {
        console.error(e);
      }
    },
    async fetchResultsNEW ({ dispatch, state, commit }, { endpoints, entities }) {
      const arrOfPromises = [];
      for (const key of Object.keys(endpoints)) {
        endpoints[key].endpoints.forEach(endpoint => {
          const payload = {
            method: 'GET',
            url: endpoint.url,
            body: { 'search-type': key }
          };
          const result = dispatch('ajaxWithTokenRefresh', payload);
          arrOfPromises.push(result);
        });
      }
      return Promise.all(arrOfPromises).then(response => {
        return response;
      });
    },
    convertResultsForSave (ctx, results) {
      const arrForSave = [];
      results.forEach(result => {
        const obj = {};
        obj.level = 1;
        obj.typeid = result.typeid;
        obj.fields = [];
        if (obj.typeid === 'maltego.instagram.profile') {
          obj.value = result.alias;
        } else if (obj.typeid === 'maltego.facebook.profile') {
          obj.value = result.person || result.id;
        } else if (obj.typeid === 'maltego.linkedin.profile') {
          obj.value = result.person || (result.first_name ? (result.first_name + ' ' + result.last_name) : '') || (result.firstName ? (result.firstName + ' ' + result.lastName) : '') || result.url;
        } else {
          obj.value = result.name;
        }
        if (result.lat && result.lng) {
          obj.lat = +result.lat;
          obj.lng = +result.lng;
        }
        Object.keys(result).forEach(key => {
          if (Array.isArray(result[key]) && !result[key].length) return;
          if (result[key] && typeof (result[key]) === 'object' && !Object.keys(result[key]).length) return;
          if (key === 'typeid') return;
          if (key === 'lat') return;
          if (key === 'lng') return;
          if (result[key]) {
            const field = {};
            field.name = key;
            field.label = key;
            field.value = result[key];
            obj.fields.push(field);
          }
        });
        arrForSave.push(obj);
      });
      return arrForSave;
    },
    async uploadPhoto ({ dispatch }, obj) {
      if (typeof (obj.photo) === 'string') {
        return {
          photo: obj.photo
        };
      }
      const fd = new FormData();
      fd.append('image', obj.photo, obj.photo.name);

      const data = {
        filename: obj.photo.name,
        filesize: obj.photo.size,
        filetype: obj.photo.type,
        modifytime: obj.photo.lastModified,
        fullname: obj.name,
        uploadtime: Date.now()
      };

      fd.append('data', JSON.stringify(data));
      fd.append('skip_ml', true);

      try {
        const payload = {
          method: 'POST',
          url: '/api/v2/photos',
          body: fd
        };
        const resp = await dispatch('ajaxWithTokenRefresh', payload);

        if (resp.status === 201) {
          const photo = resp.data;
          return {
            photo: photo.url
          };
        }
      } catch (e) {
        console.error(e);
      }
    },
    async makeResultsArray ({ dispatch }, response) {
      const allResults = [];
      response.forEach(async res => {
        let typeid;
        typeid = await dispatch('getTypeOfSocialnetwork', res.config.url);
        const searchType = JSON.parse(res.config.data)['search-type'];
        if (res.data.error) return;
        if (res.data.result) {
          res.data.result.forEach(async result => {
            if (result.class) {
              typeid = await dispatch('getTypeOfSocialnetwork', result.class);
              if (!typeid) return;
            }
            if (typeid === 'maltego.affiliation.Twitter') {
              const objToSaveForTwitter = await dispatch('makeObjectForTwitterData', result);
              objToSaveForTwitter.typeid = typeid;
              objToSaveForTwitter['search-type'] = searchType;
              allResults.push(objToSaveForTwitter);
            } else {
              result.typeid = typeid;
              result['search-type'] = searchType;
              const objToSave = {};
              Object.keys(result).forEach(key => {
                if (!result[key]) return;
                if (key === 'location' && Array.isArray(result[key])) {
                  objToSave[key] = result[key][0];
                  return;
                }
                if (Array.isArray(result[key])) return;
                if (typeof (result[key]) === 'object') return;
                if (typeof (result[key]) === 'number') {
                  result[key] = String(result[key]);
                }
                objToSave[key] = result[key];
              });
              if (result.fields) {
                Object.keys(result.fields).forEach(key => {
                  if (!result.fields[key]) return;
                  if (Array.isArray(result.fields[key])) return;
                  if (typeof (result.fields[key]) === 'object') return;
                  if (typeof (result.fields[key]) === 'number') {
                    result.fields[key] = String(result.fields[key]);
                  }
                  objToSave[key] = result.fields[key];
                });
              }
              allResults.push(objToSave);
            }
          });
        } else if (searchType !== 'alias' && typeid === 'maltego.affiliation.Twitter') {
          res.data.forEach(result => {
            result.modules.forEach(async r => {
              if (!r.user.data) return;
              const objToSaveForTwitter = await dispatch('makeObjectForTwitterData', r.user.data);
              objToSaveForTwitter.typeid = typeid;
              objToSaveForTwitter['search-type'] = searchType;
              allResults.push(objToSaveForTwitter);
            });
          });
        } else if (searchType === 'alias' && typeid === 'maltego.affiliation.Twitter') {
          if (Object.keys(res.data).length) {
            const objToSaveForTwitter = await dispatch('makeObjectForTwitterData', res.data);
            objToSaveForTwitter.typeid = typeid;
            objToSaveForTwitter['search-type'] = searchType;
            allResults.push(objToSaveForTwitter);
          }
        }
      });
      await dispatch('getLatLonNEW', allResults);
      return allResults;
    },
    makeObjectForTwitterData (ctx, result) {
      const objToSaveForTwitter = {};
      objToSaveForTwitter.created_at = result.created_at;
      objToSaveForTwitter.id = result.id_str;
      objToSaveForTwitter.name = result.name;
      objToSaveForTwitter.image = result.profile_image_url;
      objToSaveForTwitter.alias = result.screen_name;
      if (result.description) {
        objToSaveForTwitter.description = result.description;
      }
      if (result.followers_count) {
        objToSaveForTwitter.followers_count = String(result.followers_count);
      }
      if (result.friends_count) {
        objToSaveForTwitter.friends_count = String(result.friends_count);
      }
      if (result.location) {
        objToSaveForTwitter.location = result.location;
      }
      return objToSaveForTwitter;
    },
    async getLatLonNEW (ctx, profiles) {
      try {
        if (!profiles.length || profiles.find(ent => ent.lat)) return;
        const locations = [];
        const entitiesWithLocation = [];
        profiles.forEach(p => {
          if (!p.location) return;
          locations.push(p.location);
          entitiesWithLocation.push(p);
        });
        const response = await ctx.dispatch('ajaxWithTokenRefresh', {
          method: 'POST',
          url: '/api/geocoder/run?threads=10',
          body: locations,
          headers: {
            'Accept-Language': 'en-US;q=0.8,en;q=0.7'
          }
        });
        if (response.status === 202) {
          return new Promise((resolve) => {
            const interval = setInterval(async () => {
              try {
                const resp = await ctx.dispatch('ajaxWithTokenRefresh', {
                  method: 'POST',
                  url: '/api/geocoder/get?id=' + response.data
                });
                if (resp.status === 206) {
                  const locations = resp.data.data;
                  Object.keys(locations).forEach(idx => {
                    const location = locations[idx];
                    if (location) {
                      const profile = entitiesWithLocation[idx];
                      profile.lat = location.lat;
                      profile.lng = location.lon;
                      if (location.address?.country) {
                        profile.country = location.address.country;
                      }
                    }
                  });
                }
              } catch (e) {
                clearInterval(interval);
                resolve();
              }
            }, 3000);
          });
        }
      } catch (error) {
        console.error(error);
      }
    },
    getTypeOfSocialnetwork (ctx, str) {
      let typeid;
      if (str.indexOf('instagram') !== -1) {
        typeid = 'maltego.instagram.profile';
      } else if (str.indexOf('facebook') !== -1) {
        typeid = 'maltego.facebook.profile';
      } else if (str.indexOf('twitter') !== -1) {
        typeid = 'maltego.affiliation.Twitter';
      } else if (str.indexOf('linkedin') !== -1) {
        typeid = 'maltego.linkedin.profile';
      }
      return typeid;
    }
  }
};
