<template>
  <div
    class="monitoring-beta bg-white b-left-double bc-link br-3 p-16 mt-6"
    v-if="alertNote"
  >
    <div class="d-flex jc-space-between mb-8">
      <div class="text-h5 color-link"><slot name="title"></slot></div>
      <button
        class="lh-24"
        @click="closeAlert"
      >
        <Icon name="cancel-btn" />
      </button>
    </div>
    <div class="text-base fw-400">
      <slot name="text"></slot>
    </div>
  </div>
</template>

<script>
import Icon from '@/components/app/Icon';

export default {
  components: {
    Icon
  },
  props: {
    localStorage: { default: false }
  },
  data () {
    return {
      alertNote: !JSON.parse(localStorage.getItem(this.localStorage))
    };
  },
  methods: {
    closeAlert () {
      if (this.localStorage) localStorage.setItem(this.localStorage, true);
      this.alertNote = false;
    }
  }
};
</script>
