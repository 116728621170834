<template>
  <router-link
    to="/"
    class="d-block p-relative d-flex align-center"
  >
    <img
      class="d-block"
      :class="`w-${imgWidth}px`"
      src="@/assets/images/logo.svg" alt=""
    >
    <div
      class="fw-700 color-black"
      :class="`fs-${fontSize} ml-${marginLeft}`"
    >GAMAYUN</div>
  </router-link>
</template>

<script>
export default {
  props: {
    imgWidth: {
      default: 40
    },
    fontSize: {
      default: 26
    },
    marginLeft: {
      default: 12
    }
  }
};
</script>
