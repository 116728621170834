<template>
  <div class="overflow-hidden">
    <img
      v-show="loadImage"
      :src="src"
      class="d-block w-100 h-100"
      :class="'object-' + objectFit"
      @load="loadImage = true"
    />
    <div
      v-if="!loadImage"
      class="p-relative bg-form-darker"
      :class="[classRelative]"
    >
      <Icon
        class="p-absolute abs-center"
        :class="[noImage.class, classImage]"
        :name="noImage.name"
      />
    </div>
  </div>
</template>

<script>
import Icon from '@/components/app/Icon';

export default {
  name: 'Avatar',
  components: {
    Icon
  },
  props: {
    src: {
      type: String,
      required: false,
      default: ''
    },
    objectFit: {
      default: 'cover'
    },
    connection: {
      default: false
    },
    classRelative: {
      default: 'h-0 pt-square'
    },
    classImage: {
      default: ''
    }
  },
  data () {
    return {
      loadImage: false
    };
  },
  computed: {
    noImage () {
      const noImageData = {};
      if (this.connection) {
        noImageData.name = 'no-connection';
        noImageData.class = 'w-60';
      } else {
        noImageData.name = 'no-avatar';
        noImageData.class = 'w-40';
      }
      return noImageData;
    }
  }
};
</script>
