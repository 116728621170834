<template>
  <div class="p-relative">
    <a
      id="header-menu-login"
      @click.prevent="dropdown = !dropdown"
      class="d-flex align-center px-16 h-48px br-40"
      :class="{ 'bg-white color-link bs-light' : !dropdown, 'bg-link bs-none color-white' : dropdown }"
    >
      <span class="fs-13 fw-600 mr-10">{{ accountState.name }}</span>
      <span>
        <Icon
          :class="{ 'rotate--180 mb-2': dropdown }"
          name="arrow-down"
        />
      </span>
    </a>

    <div
      class="p-absolute min-w top-100 right-0 z-101 mt-24"
      v-if="dropdown"
    >
      <div class="p-absolute right-0 bottom-100 mr-12">
        <img class="d-block" src="@/assets/icons/tooltip-arrow-white.svg" />
      </div>
      <ul class="relative bs-default bg-white fs-14 br-8">
        <li
          v-for="(link, index) in links"
          :key="index"
          class="test-header-link"
          :class="{ 'px-16 pt-16 mb-8': index === 0, 'px-16 my-8': index !== 0 }"
        >
          <a
            class="color-text color-link_hover fs-14 lh-24"
            @click.prevent="clickMenu(link.url)"
          >
            {{ link.title }}
          </a>
        </li>

        <li class="b-top bc-body px-16 py-12 mt-16">
          <a
            class="color-warning color-link_hover fs-14 lh-24 test-header-link-logout"
            @click.prevent="logoutMethod"
          >
            Logout
          </a>
        </li>
      </ul>
    </div>
    <div
      class="p-fixed pos-all-0 z-100"
      v-if="dropdown" @click="dropdown = !dropdown"
    ></div>
  </div>
</template>

<script>
import Icon from '@/components/app/Icon.vue';
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'LoginLink',
  components: {
    Icon
  },
  data () {
    return {
      dropdown: false,
      links: [
        { title: 'Settings', url: 'settings' }
        // { title: 'Plan', url: 'plan' }
        // { title: 'Notifications', url: 'notifications' },
        // { title: 'Statistics', url: 'statistics' },
        // { title: 'FAQ', url: 'faq' },
        // { title: 'Support', url: 'support' }
      ]
    };
  },
  computed: {
    ...mapGetters(['accountState'])
  },
  methods: {
    ...mapActions(['logout']),

    clickMenu (url) {
      this.dropdown = false;
      this.$router.push({ query: { ...this.$route.query, profile: url } });
    },
    logoutMethod () {
      this.logout();
      this.$router.push('/login');
      location.reload();
    }
  }
};
</script>

<style lang="scss" scoped>
  .min-w {
    min-width: 240px;
  }
</style>
