export default {
  state: {
    relationSearches: [],
    relationSearchesMap: {},
    openedSearchId: null
  },
  mutations: {
    addRelationSearches (state, arr) {
      arr.forEach(search => {
        if (!state.relationSearchesMap[search.id]) {
          search.title = search.title.replace('{end}', "'s connections").replace('{middle}', ' & ');
          state.relationSearchesMap[search.id] = search;
          state.relationSearches.push(search);
        }
      });
    },
    clearRelationSearches (state) {
      state.relationSearches = [];
      state.relationSearchesMap = {};
    },
    openedRelationsSearchId (state, id) {
      state.openedSearchId = id;
    },
    deleteConnectionFromRelationSearches (state, value) {
      state.relationSearches = state.relationSearches.filter(item => item !== value);
      delete state.relationSearchesMap[value];
    }
  },
  getters: {
    sortedRelationSearches (state) {
      return state.relationSearches.sort((rel1, rel2) => rel2.created_at - rel1.created_at);
    },
    openedRelationsSearchId (state) {
      return state.openedSearchId;
    }
  },
  actions: {}
};
