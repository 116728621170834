import axios from 'axios';
import { sortDataFromUserDetailsFb, sortDataFromUserDetailsTw, sortDataFromUserDetailsIn, sortDataFromUserDetailsLn } from '@/utils/sortData';
import { getProfileLocation } from '@/utils/helpers';

export default {
  state: {
    detailsCancelToken: axios.CancelToken.source()
  },
  mutations: {
    SET_DETAILS_CANCEL_TOKEN: (state) => { state.detailsCancelToken = axios.CancelToken.source(); }
  },
  actions: {
    async setLatLon (ctx) {
      if (!ctx.getters.getCurrentResults.length || ctx.getters.getCurrentResults.find(ent => ent.lat)) return;
      const locations = [];
      const entitiesWithLocation = [];
      ctx.getters.getCurrentResults.forEach(ent => {
        const loc = getProfileLocation(ent);
        if (loc.length) {
          locations.push(loc);
          entitiesWithLocation.push(ent);
        }
      });
      try {
        const response = await ctx.dispatch('ajaxWithTokenRefresh', {
          method: 'POST',
          url: '/api/geocoder/run?threads=10',
          body: locations,
          headers: {
            'Accept-Language': 'en-US;q=0.8,en;q=0.7'
          }
        });
        if (response.status === 202) {
          const interval = setInterval(async () => {
            try {
              const resp = await ctx.dispatch('ajaxWithTokenRefresh', {
                method: 'POST',
                url: '/api/geocoder/get?id=' + response.data
              });
              if (resp.status === 206) {
                const locations = resp.data.data;
                Object.keys(locations).forEach(idx => {
                  const location = locations[idx];
                  if (location) {
                    const ent = entitiesWithLocation[idx];
                    ent.lat = location.lat;
                    ent.lon = location.lon;
                    ent.country = location.address.country;
                  }
                });
              }
            } catch (e) {
              clearInterval(interval);
            }
          }, 3000);
        }
      } catch (e) {
        console.error(e);
      }
    },
    async setProfileInfo (ctx, profile) {
      ctx.commit('SET_DETAILS_CANCEL_TOKEN');
      let url = '';
      let socialLink = '';

      if (profile.typeid === 'maltego.facebook.profile') {
        url = `/proxy/sl-api/facebook/user/v4?query=${profile.value}&type=user_details&post_count=0&photos=0`;
        socialLink = 'facebook';
        if (!profile.value) return;
      } else if (profile.typeid === 'maltego.affiliation.Twitter') {
        const username = profile.fields['twitter.screen-name'] || profile.username;
        url = `/proxy/sl-api/twitter/user?lang=en&username=${username}`;
        socialLink = 'twitter';
        if (!username) return;
      } else if (profile.typeid === 'maltego.instagram.profile') {
        url = `/proxy/sl-api/instagram/user?query=${profile.value}`;
        socialLink = 'instagram';
        if (!profile.value) return;
      } else {
        url = `/proxy/sl-api/linkedin/user?query=${profile.value}`;
        socialLink = 'linkedin';
        if (!profile.value) return;
      }

      const userDetails = await ctx.dispatch('ajaxWithTokenRefresh', {
        method: 'GET',
        url: url
      });
      ctx.commit('updateBalance', userDetails.headers['x-user-balance']);

      let details;
      if (socialLink === 'facebook') {
        if (!Object.keys(userDetails.data).length) return;
        details = await sortDataFromUserDetailsFb(userDetails.data);
      } else if (socialLink === 'twitter') {
        if (!Object.keys(userDetails.data).length) return;
        details = await sortDataFromUserDetailsTw(userDetails.data);
      } else if (socialLink === 'instagram') {
        if (!Object.keys(userDetails.data.result).length) return;
        details = await sortDataFromUserDetailsIn(userDetails.data.result);
      } else {
        if (!Object.keys(userDetails.data.result).length) return;
        details = await sortDataFromUserDetailsLn(userDetails.data.result);
      }

      try {
        const payload = {
          method: 'PATCH',
          url: '/api/user/nodes',
          body: {
            node_id: profile.oid,
            fields: [{
              name: 'user-details',
              value: JSON.stringify(details),
              label: 'user-details',
              rule: null
            }]
          },
          cancelToken: ctx.state.detailsCancelToken.token
        };
        await ctx.dispatch('ajaxWithTokenRefresh', payload);
        profile.fields['user-details'] = JSON.stringify(details);
      } catch (error) {
        console.error(error);
      }
    }
  }
};
