<template>
  <button class="d-flex align-center profile-tag" v-if="entity?.fields?.relation?.value" @click="openEntityInfo(true)">
    <div class="d-flex align-center mr-12">
      <Icon name="trash" bg="#FFFFFF" class="trash-icon" @click.stop="removeTag(entity)" />
      <Icon name="connections" class="sidebar-avatar connection-icon" />
    </div>
    <span class="typography-base fw-500 tagged-profile-name">{{ entity.value }}</span>
    <div class="right-icon right-icon--23">
      <input type="checkbox" name="" :id="entity.oid + '-' + index" class="sidebar-checkbox" :checked="entity.checked">
      <label class="sidebar-checkbox__label" :class="'label-' + tag.oid" @click.stop="checkEntity()"></label>
    </div>
  </button>
  <button class="d-flex align-center profile-tag" v-else @click="openEntityInfo(false)">
    <div class="d-flex align-center mr-12">
      <Icon name="trash" bg="#FFFFFF" class="trash-icon" @click.stop="removeTag(entity)" />
      <img :src="sourceAvatar" alt="" class="sidebar-avatar" @load="avatarLoadHandler" @error="avatarErrorHandler" :class="loadImage ? 'd-block' : 'd-none'">
      <img src="@/assets/icons/avatar.png" alt="" class="sidebar-avatar" :class="loadImage ? 'd-none' : 'd-block'">
      <Icon :name="socialNetworkIcon" class="ml-12" v-if="socialNetworkIcon" />
    </div>
    <span class="typography-base fw-500 tagged-profile-name">{{ name }}</span>
    <div class="right-icon right-icon--23">
      <input type="checkbox" name="" :id="entity.oid + '-' + index" class="sidebar-checkbox" :checked="entity.checked">
      <label class="sidebar-checkbox__label" :class="'label-' + tag.oid" @click.stop="checkEntity()"></label>
    </div>
  </button>
</template>

<script>
import Icon from './app/Icon';
import { getEntityAvatar, getEntityName } from '../utils/helpers';

export default {
  name: 'TagEntity',
  props: ['entity', 'tag', 'index'],
  emits: ['loading'],
  data () {
    return {
      sourceAvatar: '',
      loadImage: false
    };
  },
  computed: {
    name () {
      return getEntityName(this.entity);
    },
    socialNetworkIcon () {
      try {
        for (const socialNet of ['instagram', 'Twitter', 'facebook', 'linkedin']) {
          if (this.entity.typeid.includes(socialNet)) {
            return 'person-card-' + socialNet.toLowerCase();
          }
        }
        return '';
      } catch (e) {
        return '';
      }
    }
  },
  methods: {
    avatarLoadHandler () {
      this.loadImage = true;
    },
    avatarErrorHandler () {
      this.loadImage = false;
    },
    openEntityInfo (isEdge = false) {
      if (this.$route.name === 'Report') {
        this.$router.push(`/case/${this.$route.params.id}`);
      }
      if (isEdge) {
        this.$store.commit('setLoadUrl', true);
        this.$store.commit('setSearching', true);
      }
      this.$store.commit('openResults', false);
      this.$store.commit('openConnections', false);
      this.$store.commit('openMultiprofile', false);
      this.$store.commit('clearAllSavedInTagsEntities');
      const mutation = (isEdge) ? 'addConnectionToSavedInTagsPage' : 'addProfileToSavedInTagsPage';
      this.$store.commit(mutation, this.entity);
      this.$store.commit('showAllSavedInTags', false);
      this.$store.commit('openSavedInTagsPage', true);
      document.getElementsByClassName('content')[0].scrollIntoView();
    },
    checkEntity () {
      this.$store.commit('checkEdge', this.entity.oid);
      this.$store.commit('checkResultsProfile', this.entity.oid);
      this.$store.commit('checkConnectionEntity', this.entity.oid);
      this.$store.commit('checkSelectedEntities', this.entity);
    },
    async removeTag (entity = null) {
      try {
        const findTaggedEntity = (entities) => {
          Object.keys(entities).forEach(oid => {
            const ent = entities[oid];
            if (ent.tags && ent.tags.length) {
              const index = ent.tags.indexOf(this.tag.oid);
              if (index !== -1) {
                payload.body.targets.push({ type: 'entity', id: ent.oid });
              }
            }
          });
        };

        this.$emit('loading', this.tag.oid);
        const payload = {
          method: 'DELETE',
          url: '/api/user/tags',
          body: {
            id: this.tag.oid,
            targets: []
          }
        };
        const updateResultsTags = {
          attachProfile: entity,
          tag: this.tag
        };

        if (entity) {
          payload.body.targets.push({ type: 'entity', id: entity.oid });
        } else {
          const resultsMap = this.$store.getters.resultsMap;
          findTaggedEntity(resultsMap);
          Object.keys(resultsMap).forEach(oid => { findTaggedEntity(resultsMap[oid].children || []); });
          findTaggedEntity(this.$store.getters.connectionEntitiesMap);
          findTaggedEntity(this.$store.getters.edgesMap);
        }

        await this.$store.dispatch('ajaxWithTokenRefresh', payload);
        this.$store.commit('deleteTagsInResults', updateResultsTags);
        this.$store.commit('deleteTagsInConnections', updateResultsTags);
        this.$store.commit('deleteTagsInConnectionsBetween', updateResultsTags);
        this.$store.commit('deleteTagsInEdges', updateResultsTags);

        this.$emit('loading', '');
      } catch (error) {
        console.error(error);
      }
    }
  },
  components: {
    Icon
  },
  mounted () {
    this.sourceAvatar = getEntityAvatar(this.entity);
  }
};
</script>

<style lang="scss" scoped>
  .profile-tag {
    .trash-icon {
      display: none;
    }
    &:hover .trash-icon {
      display: block;
    }
    &:hover img {
      display: none;
    }
    &:hover .connection-icon {
      display: none;
    }
  }
</style>
