<template>
  <div class="modal-background"></div>
    <div class="modal-wrap">
      <div class="modal py-48">
          <button class="cancel-btn" @click="this.$emit('closeModal')"><Icon name="cancel-btn"/></button>
          <h2 class="text-h2 lh-32">You have reached the limit of cases</h2>
          <p class="fs-18 fw-400 lh-24 mt-12 color-text">Delete the old case or buy new ones</p>
          <div class="d-flex jc-space-between mt-24">
            <button class="opacity-btn wide-btn fs-13" @click="this.$emit('closeModal')">Cancel</button>
            <button class="primary-btn wide-btn fs-13" @click="buyCases">Buy new ones</button>
        </div>
      </div>
  </div>
</template>

<script>
import Icon from '@/components/app/Icon';

export default {
  name: 'CasesLimitReachedModal',
  components: {
    Icon
  },
  emits: ['closeModal'],
  methods: {
    buyCases () {
      this.$emit('closeModal');
      this.$router.push({ query: { ...this.$route.query, profile: 'plan' } });
    }
  }
};
</script>
