<template>
  <div class="inform-sidebar">
    <div>
      <div class="d-flex mb-48 inform-sidebar__logo">
        <img src="@/assets/icons/new-logo.png" alt="" class="mr-24">
        <a href="/" class="typography-head color-black">GAMAYUN</a>
      </div>
      <template v-if="$route.name === 'Login'">
        <div class="inform-sidebar__note mb-48">
          <h2 class="typography-subhead color-black mb-4">Welcome to our product!</h2>
        </div>
        <div class="inform-sidebar__desc">
          <img src="@/assets/icons/arrows.png" alt="" class="mr-24">
          <div>
            <p class="mb-24">Gamayun is a user-friendly web-based solution for conducting online investigations in social media. Using our internal currency system, the user can take advantage of the product’s easy-to-use features to quickly and effectively bring an investigation to conclusion.</p>
            <p class="mb-24">Thanks to its ease of use and fast operational speed, Gamayun is predominantly aimed at users who require a tool for conducting quick background checks on individuals based on their social media activity. With no special training or prior experience required, Gamayun’s intuitive interface and affordable price is an ideal OSINT solution for a wide variety of specialisms including journalism, law enforcement, insurance and recruitment.</p>
          </div>
        </div>
      </template>
      <template v-else>
        <div class="inform-sidebar__note mb-48">
          <span :class="showMainInfoRegistration && $route.name !== 'Create Password' ? 'typography-subhead color-black' : ''">Personal info</span>
          <span :class="showConfirmPhoneBlock ? 'typography-subhead color-black' : ''">Number confirmation</span>
          <span :class="showCompanyInfoForm || showConfirmEmailBlock ? 'typography-subhead color-black' : ''">Email Confirmation</span>
          <span :class="$route.name === 'Create Password' ? 'typography-subhead color-black' : ''">Password</span>
        </div>
        <div class="inform-sidebar__desc">
          <img src="@/assets/icons/arrows.png" alt="" class="mr-24">
          <div v-if="showMainInfoRegistration && $route.name !== 'Create Password'">
            <p class="mb-24">Know Your Client (KYC) is our product distribution policy for safeguarding the product against unauthorized use.</p>
            <p class="mb-24">In order to create an account, it is mandatory to fill in every field indicated. Please note that when registering, it is important to specify your current phone number and corporate email address.</p>
          </div>
          <div v-else-if="showConfirmPhoneBlock">
            <p class="mb-24">After signup, we will check your business in accordance with our KYC procedure to make sure your use cases apply our terms of services.</p>
            <p class="mb-24">We reserve the right to refuse access, terminate accounts, or cancel orders at our sole discretion.</p>
          </div>
          <div v-if="showCompanyInfoForm || showConfirmEmailBlock">
            <p class="mb-24">Once your phone number and email have been verified, you can begin your trial period of full access to the product.</p>
          </div>
          <div v-if="$route.name === 'Create Password'">
            <p class="mb-24">The product trial grants you 300 SLP, and the base plan 3000 SLP. A brief breakdown of transform costs are as follows:</p>
            <p class="mb-24">Profile search (12 SLP), Extended user details (5 SLP), Explore connection (15 SLP), Extend connection info (15 SLP), and Find connection between two profiles (30 SLP). Each operation has its SLP price marked within the platform.</p>
            <p class="mb-24">When your test period expires, you will be prompted to select and pay for a plan, which must be completed for you to continue using the product.</p>
          </div>
        </div>
      </template>
    </div>
    <div class="inform-sidebar__footer">
      <a href="https://www.youtube.com/channel/UCcFD986JKvXXU88w0SRfvow" class="header-link" target="_blank">
        <Icon name="youtube" color="#000000"/>
      </a>
      <a href="https://twitter.com/_SocialLinks_" class="header-link" target="_blank">
        <Icon name="twitter" color="#000000"/>
      </a>
      <a href="https://www.linkedin.com/company/10294269/" class="header-link" target="_blank">
        <Icon name="linkedin" color="#000000"/>
      </a>
      <a href="https://www.facebook.com/Social-Links-Maltego-Transforms-810934209023184" class="header-link" target="_blank">
        <Icon name="facebook" color="#000000"/>
      </a>
    </div>
  </div>
</template>

<script>
import Icon from './Icon.vue';
import { mapGetters } from 'vuex';

export default {
  components: {
    Icon
  },
  computed: {
    ...mapGetters(['showConfirmPhoneBlock', 'showMainInfoRegistration', 'showCompanyInfoForm', 'showConfirmEmailBlock'])
  }
};
</script>
