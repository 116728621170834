import {
  FacebookConnectionRequest,
  InstagramConnectionRequest,
  LinkedInConnectionRequest,
  TwitterConnectionRequest
} from './connectionsRequest';
import {
  FacebookConnectionOriginProfile,
  InstagramConnectionOriginProfile,
  LinkedInConnectionOriginProfile,
  TwitterConnectionOriginProfile
} from './connectionsOriginProfile';
import {
  FacebookConnectionProfile,
  InstagramConnectionProfile,
  LinkedInConnectionProfile,
  TwitterConnectionProfile
} from './connectedProfile';

export class ConnectionsBuilder {
  constructor (profile, lastProfile, searchID, socialNet) {
    this.profile = profile;
    this.lastProfile = lastProfile;
    this.searchID = searchID;
    this.socialNet = socialNet || this.getSocialNetName();
  }

  getSocialNetName () {
    for (const socialNet of ['instagram', 'twitter', 'linkedin', 'facebook']) {
      const re = new RegExp(socialNet, 'i');
      if (re.test(this.profile.typeid)) {
        return socialNet;
      }
    }
  }

  buildRequest () {
    switch (this.socialNet) {
      case 'instagram': return new InstagramConnectionRequest(this.profile);
      case 'twitter': return new TwitterConnectionRequest(this.profile);
      case 'linkedin': return new LinkedInConnectionRequest(this.profile);
      case 'facebook': return new FacebookConnectionRequest(this.profile);
    }
  }

  buildOriginProfile () {
    switch (this.socialNet) {
      case 'instagram': return new InstagramConnectionOriginProfile(this.profile, this.socialNet, this.lastProfile, this.searchID);
      case 'twitter': return new TwitterConnectionOriginProfile(this.profile, this.socialNet, this.lastProfile, this.searchID);
      case 'linkedin': return new LinkedInConnectionOriginProfile(this.profile, this.socialNet, this.lastProfile, this.searchID);
      case 'facebook': return new FacebookConnectionOriginProfile(this.profile, this.socialNet, this.lastProfile, this.searchID);
    }
  }

  buildConnectedProfile () {
    switch (this.socialNet) {
      case 'instagram': return new InstagramConnectionProfile(this.profile, this.socialNet);
      case 'twitter': return new TwitterConnectionProfile(this.profile, this.socialNet);
      case 'linkedin': return new LinkedInConnectionProfile(this.profile, this.socialNet);
      case 'facebook': return new FacebookConnectionProfile(this.profile, this.socialNet);
    }
  }
}
