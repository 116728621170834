import axios from 'axios';
import router from '@/router/indexNew.js';
import { getEntityName } from '@/utils/helpers';
import { ConnectionsBuilder } from '@/utils/connectionsBuilder';

export default {
  state: {
    connections: [],
    connectionsDict: {},
    connectionEntitiesMap: {},
    from: null,
    connectionsCancelToken: axios.CancelToken.source(),
    postTypes: {
      facebook: 'maltego.facebook.post',
      instagram: 'maltego.instagram.photo',
      linkedin: 'maltego.linkedin.post',
      twitter: 'maltego.Twit'
    },
    opened: false,
    searching: false,
    between: false,
    connectionsBetween: [],
    connectionsBetweenDict: {},
    mergedConnectionsBetween: [],
    selectedProfile: null,
    isFilterActive: false,
    lastVisibleOIDs: [],
    timers: [],
    searchCompleted: true,
    merged: false,
    history: {},
    searchID: null,
    anotherErrors: false,
    callConnFromMultiprofile: false,
    connectionsPath: null
  },
  mutations: {
    setConnectionsPath (state, value) {
      state.connectionsPath = value;
    },
    setAnotherErrors (state, value) {
      state.anotherErrors = value;
    },
    setConnectionsSearchID (state, value) {
      state.searchID = value;
    },
    setCallConnFromMultiprofile (state, value) {
      state.callConnFromMultiprofile = value;
    },
    addToHistory (state, { profiles, relations, parentProfile, id }) {
      state.history[id] = { profiles, relations, parentProfile };
    },
    setConnectionsMergingState (state, value) {
      state.merged = value;
    },
    allConnectionsFound (state, value) {
      state.searchCompleted = value;
    },
    updateConnectionEntitiesMap (state, entities) {
      entities.forEach(ent => {
        if (ent.oid && !state.connectionEntitiesMap[ent.oid]) {
          state.connectionEntitiesMap[ent.oid] = ent;
        }
      });
    },
    addTimer (state, timerID) {
      state.timers.push(timerID);
    },
    removeTimer (state, timerID) {
      if (!timerID) {
        state.timers.forEach(timerID => { clearInterval(timerID); });
        state.timers = [];
        return;
      }
      const idx = state.timers.indexOf(timerID);
      if (idx !== -1) {
        clearInterval(timerID);
        state.timers.splice(idx, 1);
      }
    },
    addMergedConnectionsBetween (state, array) {
      state.mergedConnectionsBetween = array;
    },
    updateMergedConnectionBetween (state, { conn, key, val }) {
      state.mergedConnectionsBetween.forEach(connection => {
        if ((connection.id === conn) || (connection.username === conn)) {
          connection[key] = val;
        }
      });
    },
    activateFilter (state, value) {
      state.isFilterActive = value;
    },
    updateConnectionsVisibility (state, { buttons, relationExists }) {
      if (!relationExists) {
        state.connections.forEach(conn => {
          conn.visible = true;
        });
      } else {
        const clickedButtons = Object.keys(buttons.relation).filter(key => buttons.relation[key]);
        state.connections.forEach(conn => {
          let relations = conn.fields && conn.fields.connection_types && conn.fields.connection_types.value;
          relations = relations.split(',');
          conn.visible = clickedButtons.every(rel => relations.indexOf(rel) > -1);
          if (!conn.visible) {
            conn.show_posts = false;
          }
        });
      }
    },
    checkAllVisibleConnections (state, checked) {
      if (typeof checked !== 'boolean') return;
      state.connections.forEach(conn => {
        if (conn.visible) {
          conn.edge.checked = checked;
        }
      });
    },
    checkAllConnections (state, checked) {
      state.connections.forEach(conn => {
        conn.edge.checked = checked;
      });
      state.connectionsBetween.forEach(conn => {
        conn.edge.checked = checked;
      });
      state.mergedConnectionsBetween.forEach(conn => {
        conn.edge.checked = checked;
      });
    },
    saveLastVisibleConnectionOIDs (state, entities) {
      entities.forEach(ent => {
        state.lastVisibleOIDs.push(ent.oid);
      });
    },
    removeLastVisibleConnectionOIDs (state) {
      state.lastVisibleOIDs = [];
    },
    selectConnectionProfile (state, value) {
      state.selectedProfile = value;
    },
    showConnectionsByIDs (state, OIDs) {
      state.connections.forEach(conn => {
        conn.visible = OIDs.includes(conn.oid);
      });
    },
    clearConnections (state, clearTaggedEntities = true) {
      state.connections = [];
      state.connectionsDict = {};
      if (clearTaggedEntities) {
        state.connectionEntitiesMap = {};
      } else {
        const connectionEntitiesMap = {};
        Object.keys(state.connectionEntitiesMap).forEach(oid => {
          const ent = state.connectionEntitiesMap[oid];
          if (ent.tags?.length) {
            connectionEntitiesMap[oid] = ent;
          }
        });
        state.connectionEntitiesMap = connectionEntitiesMap;
      }
      state.connectionsCancelToken = axios.CancelToken.source();
    },
    searchConnections (state, value) {
      state.searching = value;
    },
    setProfileToConnect (state, value) {
      state.from = value;
    },
    openConnections (state, value) {
      state.opened = value;
    },
    addConnectionToDict (state, { key, val }) {
      if (!state.connectionsDict[key]) {
        state.connectionsDict[key] = val;
      }
    },
    addConnectionToDictBetween (state, { key, val }) {
      state.connectionsBetweenDict[key] = val;
    },
    addConnection (state, conn) {
      state.connections.push(conn);
    },
    addConnectionBetween (state, conn) {
      state.connectionsBetween.push(conn);
    },
    updateConnection (state, { conn, key, val }) {
      const connection = state.connectionsDict[conn] || state.connectionEntitiesMap[conn];
      if (connection) {
        connection[key] = val;
      }
    },
    updateConnectionBetween (state, { conn, key, val }) {
      state.connectionsBetweenDict[conn][key] = val;
    },
    updateBetweenState (state, value) {
      state.between = value;
    },
    clearConnectionsBetween (state) {
      state.merged = false;
      state.connectionsBetween = [];
      state.connectionsBetweenDict = {};
      state.mergedConnectionsBetween = [];
      state.connectionsCancelToken = axios.CancelToken.source();
    },
    checkConnectionEntity (state, oid) {
      const ent = state.connectionEntitiesMap[oid];
      if (ent) {
        ent.checked = !ent.checked;
      }
    },
    checkConnection (state, oid) {
      const checkConnection = (array) => {
        for (const conn of array) {
          if (conn.oid === oid) {
            conn.checked = !conn.checked;
            return true;
          }
        }
      };
      const found = checkConnection(state.connections);
      if (!found) {
        checkConnection(state.connectionsBetween);
        checkConnection(state.mergedConnectionsBetween);
      }
    },
    checkAllConnectionProfiles (state, value) {
      state.connections.forEach(conn => { conn.checked = value; });
      state.mergedConnectionsBetween.forEach(conn => { conn.checked = value; });
    },
    updateTagsInConnections (state, payload) {
      const addTags = (array) => {
        array.forEach(conn => {
          if (payload.searchItems.has(conn.oid)) {
            if (conn.tags && conn.tags.length) {
              conn.tags.push(payload.tag);
            } else {
              conn.tags = [];
              conn.tags.push(payload.tag);
            }
          }
        });
      };
      addTags(state.connections);
      addTags(state.mergedConnectionsBetween);
    },
    deleteTagsInConnectionsBetween (state, payload) {
      const removeTag = (profile) => {
        const index = profile.tags.indexOf(payload.tag.oid);
        if (index !== -1) {
          profile.tags.splice(index, 1);
        }
      };
      if (payload.attachProfile) {
        const profile = state.connectionsBetween.find(conn => {
          return payload.attachProfile.oid === conn.oid;
        });
        if (profile) {
          removeTag(profile);
        }
        return;
      }
      state.connectionsBetween.forEach(conn => {
        if (conn.tags && conn.tags.length) {
          removeTag(conn);
        }
      });
    },
    deleteTagsInConnections (state, payload) {
      if (payload.attachProfile) {
        const profile = state.connections.find(conn => {
          return payload.attachProfile.oid === conn.oid;
        });
        if (profile) {
          const index = profile.tags.indexOf(payload.tag.oid);
          if (index !== -1) {
            profile.tags.splice(index, 1);
          }
        }
      } else {
        state.connections.forEach(conn => {
          if (conn.tags && conn.tags.length) {
            const index = conn.tags.indexOf(payload.tag.oid);
            if (index !== -1) {
              conn.tags.splice(index, 1);
            }
          }
        });
      }
    }
  },
  getters: {
    sortedConnections: state => state.connections.sort((a, b) => b.score - a.score),
    visibleConnections: state => state.connections.filter(connection => connection.visible),
    mergedConnectionsBetween: state => state.mergedConnectionsBetween,
    connectionsBetween: state => state.connectionsBetween,
    connections: state => state.connections,
    connectionEntitiesMap: state => state.connectionEntitiesMap,
    connectionsSearchCompleted: state => state.searchCompleted,
    connectionsSearchID: state => state.searchID,
    anotherErrors: state => state.anotherErrors,
    sortedConnForMultiprofile: state => state.connections.sort((a, b) => b.fields.score.value - a.fields.score.value),
    getSearchingHACK: state => state.searching
  },
  actions: {
    async backToResults ({ commit, getters, rootState }) {
      commit('openResults', true);
      commit('openConnections', false);
      commit('openSavedInTagsPage', false);
      commit('removeTimer');
      getters.currentSearchResults.forEach(ent => { ent.visible = ent.level === 1; });
      if (!rootState.results.onOverview) commit('toggleViewController');
    },
    async areEqual (ctx, { obj1, obj2 }) {
      let a = JSON.stringify(obj1);
      let b = JSON.stringify(obj2);
      if (!a) { a = ''; }
      if (!b) { b = ''; }
      return (a.split('').sort().join('') === b.split('').sort().join(''));
    },
    async updateEntities ({ state, dispatch }, entities) {
      if (!entities || !entities.length) {
        return;
      }
      try {
        const body = {
          rid: entities[0].rid,
          ents: []
        };
        entities.forEach((ent, idx) => {
          body.ents.push({ oid: ent.oid, fields: [] });
          Object.keys(ent.fields).forEach(key => {
            body.ents[idx].fields.push(ent.fields[key]);
          });
        });

        const payload = {
          method: 'POST',
          url: '/api/user/entupd',
          cancelToken: state.connectionsCancelToken.token,
          body: body
        };
        await dispatch('ajaxWithTokenRefresh', payload);
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    async addPosts ({ state, dispatch }, { previousConnection, connection }) {
      const postsToUpdate = [];
      for (const [id, post] of Object.entries(connection.posts)) {
        const savedPost = previousConnection.posts[id];
        if (savedPost) {
          post.typeid = savedPost.typeid;
          post.rid = savedPost.rid;
          post.oid = savedPost.oid;
          post.src = savedPost.src;
        }
        if (!savedPost) {
          post.rid = connection.rid;
          connection.posts[id] = post;
        } else if (!await dispatch('areEqual', { obj1: savedPost, obj2: post })) {
          for (const [key, val] of Object.entries(post)) {
            if (!connection.posts[id]) {
              connection.posts[id] = post;
            } else {
              connection.posts[id][key] = val;
            }
          }
          if (post.oid) {
            postsToUpdate.push(post);
          }
        }
      }
      await dispatch('updateEntities', postsToUpdate);
    },
    async filterConnections ({ state }, { originProfile, allProfiles }) {
      const searchProfile = originProfile.entity;
      const socialNet = originProfile.type;
      const connections = originProfile.connections;
      if (state.between) {
        return connections.filter(conn => {
          let equal, existInBetween;
          if (socialNet === 'instagram') {
            equal = conn.username === searchProfile.value;
            existInBetween = allProfiles.find(ent => ent.value === conn.username);
          } else if (socialNet === 'linkedin') {
            equal = conn.url === searchProfile.value;
            existInBetween = allProfiles.find(ent => ent.value === conn.url);
          } else if (socialNet === 'twitter') {
            const fields = searchProfile.fields;
            let searchProfileID = fields?.['twitter.id'] || fields?.['affiliation.uid'];
            searchProfileID = searchProfileID?.value || searchProfile?.value;
            equal = conn.id.toString() === searchProfileID.toString();
            existInBetween = allProfiles.find(ent => {
              let entID = ent.fields && (ent.fields['twitter.id'] || ent.fields['affiliation.uid']);
              entID = (entID && entID.value) || ent.value;
              return entID.toString() === conn.id.toString();
            });
          } else {
            equal = conn.id.toString() === searchProfile.value.toString();
            existInBetween = allProfiles.find(ent => ent.value.toString() === conn.id.toString());
          }
          return !equal && existInBetween;
        });
      }
      return connections.filter(conn => (conn.id !== state.from.value) && (conn.username !== state.from.value));
    },
    async getConnectionsData ({ state, dispatch, commit }, { originProfile, response, allProfiles }) {
      const profile = originProfile.entity;
      const socialNet = originProfile.type;
      const searchId = originProfile.searchID;
      originProfile.parseResponse(response);
      const connections = await dispatch('filterConnections', { originProfile, allProfiles });
      const connectionsToUpdate = [];
      const connectionsToSave = [];

      for (const connection of connections) {
        originProfile.setPostsOrPhotosProperties(profile.rid);
        originProfile.setPostsOrPhotosProperties(profile.rid, true);

        const postIDs = Object.keys(connection.connection_posts || {});
        const photoIDs = Object.keys(connection.connection_photos || {});
        const uniqPostIDs = [...new Set(postIDs.concat(photoIDs))];

        connection.parent = profile;
        connection.rid = profile.rid;
        connection.visible = (!(state.isFilterActive || state.selectedProfile));
        connection.typeid = profile.typeid;
        connection.level = 3;
        connection.fields = {};

        const builder = new ConnectionsBuilder(connection, false, searchId, socialNet);
        const connectionProfile = builder.buildConnectedProfile();
        connectionProfile.addRelation(originProfile.profileInfo.relatives);
        connectionProfile.addConnectionTypes(uniqPostIDs);
        connectionProfile.stringifyFieldValue('relations');
        connectionProfile.stringifyFieldValue('tagged_count');

        const connectionsDict = (state.between) ? state.connectionsBetweenDict : state.connectionsDict;
        let previousConnection = connectionsDict[connection.id || connection.username];

        const postExists = !!uniqPostIDs.length;
        if (postExists) {
          connection.post_exists = postExists;
          connectionProfile.addToFields('post_exists', postExists.toString());
        }

        if (previousConnection) {
          previousConnection = Object.assign({}, previousConnection);
          previousConnection.posts = {};
          connectionProfile.setValues([
            { name: 'show_posts', value: previousConnection.show_posts },
            { name: 'checked', value: previousConnection.checked },
            { name: 'visible', value: previousConnection.visible },
            { name: 'rid', value: previousConnection.rid },
            { name: 'oid', value: previousConnection.oid }
          ]);

          // Saving user details when searching for connections in facebook
          if (previousConnection.fields['user-details']) {
            connectionProfile.addToFields('user-details', previousConnection.fields['user-details']);
          }
        }

        const mutationTail = (state.between) ? 'Between' : '';
        connection.posts = {};
        if (!previousConnection) {
          connectionProfile.setValue('posts', originProfile.getPostsDict(uniqPostIDs));
          connectionProfile.setValue('show_posts', false);
          connectionProfile.setValue('checked', false);
          commit('addConnectionToDict' + mutationTail, { key: connection.id || connection.username, val: connection });
          commit('addConnection' + mutationTail, connection);
          connectionsToSave.push(connection);
        } else if (!await dispatch('areEqual', { obj1: previousConnection, obj2: connection })) {
          await dispatch('addPosts', { previousConnection, connection });
          for (const [key, val] of Object.entries(connection)) {
            if (key !== 'posts') {
              commit('updateConnection' + mutationTail, { conn: connection.id || connection.username, key, val });
            }
          }
          if (connection.oid) {
            connectionsToUpdate.push(connection);
          }
        }
      }
      await dispatch('updateEntities', connectionsToUpdate);
      const relations = await dispatch('createRelations', connectionsToSave);

      if (!state.between) {
        await dispatch('saveEntities', { entities: connectionsToSave, relations, searchId });
        await dispatch('savePosts', { connectionProfiles: connections, searchId });
      } else if (originProfile.last && !state.merged) {
        const mergedConnectionsBetween = await dispatch('mergeConnectionsBetween');
        if (mergedConnectionsBetween.length) {
          await dispatch('saveEntities', { entities: mergedConnectionsBetween, relations: [mergedConnectionsBetween[0].edge], searchId });
          await dispatch('savePosts', { connectionProfiles: mergedConnectionsBetween, searchId });
        }
      }

      await dispatch('stopSearching', originProfile.last);
    },
    async createRelations ({ state }, connectionProfiles) {
      const relations = [];
      const time = Date.now();
      connectionProfiles.forEach(connProfile => {
        const relation = {};
        relation.fields = {};
        relation.crtunix = connProfile.crtunix = time;
        relation.weight = connProfile.score;
        relation.level = 2;
        relation.parent_id = connProfile.parent.oid;
        const parentProfileName = getEntityName(connProfile.parent);
        const childProfileName = getEntityName(connProfile);
        relation.value = `${parentProfileName} & ${childProfileName}`;
        relation.fields.parent_name = { name: 'parent_name', value: parentProfileName };
        relation.fields.child_name = { name: 'child_name', value: childProfileName };
        relation.fields.relation = { name: 'relation', value: 'true' };
        relations.push(relation);
        if (state.between) {
          relation.fields.connections_between = { name: 'connections_between', value: 'true' };
          connProfile.edge = relation;
        }
      });
      return relations;
    },
    async savePosts ({ dispatch, state }, { connectionProfiles, searchId }) {
      const postsToSave = [];
      connectionProfiles.forEach(conn => {
        Object.keys(conn.posts).forEach(key => {
          const post = conn.posts[key];
          post.src = conn.oid;
          post.rsid = searchId;
          post.parent_id = conn.edge.oid;
          post.level = 4;
          if (!post.oid) {
            postsToSave.push(conn.posts[key]);
          }
        });
      });
      await dispatch('saveEntities', { entities: postsToSave, searchId });
    },
    async mergeConnectionsBetween ({ state, commit, getters }) {
      commit('setConnectionsMergingState', true);
      if (!state.connectionsBetween.length) {
        return [];
      }
      if (state.connectionsBetween.length === 1) {
        commit('addMergedConnectionsBetween', state.connectionsBetween);
        return getters.mergedConnectionsBetween;
      }
      const connection1 = state.connectionsBetween[0];
      const connection2 = state.connectionsBetween[1];
      const connection = JSON.parse(JSON.stringify(connection1));

      const score = Math.max(connection1.score, connection2.score);
      connection.score = score;
      connection.level = 3;
      connection.parent_ref = 0;
      connection.fields.score = { name: 'score', value: score.toString() };
      connection.fields.connections_between = { name: 'connections_between', value: 'true' };

      const connectionTypes1 = connection1.fields.connection_types.value.split(',');
      const connectionTypes2 = connection2.fields.connection_types.value.split(',');
      const connectionTypes = connectionTypes1;
      connectionTypes2.forEach(type => {
        if (!connectionTypes.includes(type)) {
          connectionTypes.push(type);
        }
      });
      connection.fields.connection_types = { name: 'connection_types', value: connectionTypes.toString() };

      const posts = { ...connection2.posts };
      Object.keys(posts).forEach(postID => {
        posts[postID].reverse = true;
        posts[postID].fields.reverse = { name: 'reverse', value: 'true' };
      });
      connection.posts = { ...connection1.posts, ...posts };

      const likedPostsCount = +connection1.fields.liked_count.value + (+connection2.fields.liked_count.value);
      const taggedPostsCount = +connection1.fields.tagged_count.value + (+connection2.fields.tagged_count.value);
      const commentedPostsCount = +connection1.fields.commented_count.value + (+connection2.fields.commented_count.value);
      const reactedPostsCount = +connection1.fields.reacted_count.value + (+connection2.fields.reacted_count.value);
      const repostPostsCount = +connection1.fields.repost_count.value + (+connection2.fields.repost_count.value);
      const mentionedPostsCount = +connection1.fields.mentioned_count.value + (+connection2.fields.mentioned_count.value);
      connection.fields.liked_count.value = likedPostsCount.toString();
      connection.fields.tagged_count.value = taggedPostsCount.toString();
      connection.fields.commented_count.value = commentedPostsCount.toString();
      connection.fields.reacted_count.value = reactedPostsCount.toString();
      connection.fields.repost_count.value = repostPostsCount.toString();
      connection.fields.mentioned_count.value = mentionedPostsCount.toString();

      if (connection1.relations && connection2.relations) {
        const relations1 = [...connection1.relations];
        const relations2 = [...connection2.relations];
        connection.relations = relations1.concat(relations2);
      }

      if (connection1.connection_photos && connection2.connection_photos) {
        connection.connection_photos = { ...connection1.connection_photos, ...connection2.connection_photos };
      }

      if (connection1.connection_posts && connection2.connection_posts) {
        connection.connection_photos = { ...connection1.connection_posts, ...connection2.connection_posts };
      }

      commit('addMergedConnectionsBetween', [connection]);
      commit('updateConnectionEntitiesMap', [connection]);
      return getters.mergedConnectionsBetween;
    },
    async saveEntities ({ dispatch, rootState, state, commit, getters }, { entities, relations = [], step = 500, searchId }) {
      if (!entities || !entities.length) {
        return;
      }

      const resultsSearchID = entities[0].rid;
      let start = 0;
      let end = step;

      while (entities.length && start <= entities.length) {
        const ents = entities.slice(start, end);
        let entitiesToSave = relations.slice(start, end);

        if (entitiesToSave.length) {
          ents.forEach((ent, idx) => {
            ent.parent_ref = idx;
            entitiesToSave.push(ent);
          });
        } else {
          entitiesToSave = ents;
        }

        start = end;
        end += step;

        const nodes = [];

        try {
          entitiesToSave.forEach(entity => {
            const ent = {
              value: entity.value,
              level: entity.level,
              weight: entity.weight || 100,
              rsid: searchId,
              fields: []
            };
            if (entity.typeid) {
              ent.typeid = entity.typeid;
            }
            if (entity.parent_id) {
              ent.parent_id = entity.parent_id;
            }
            if (entity.crtunix) {
              ent.crtunix = entity.crtunix;
            }
            if (typeof entity.parent_ref === 'number') {
              ent.parent_ref = entity.parent_ref;
            }
            Object.keys(entity.fields).forEach(key => { ent.fields.push(entity.fields[key]); });
            nodes.push(ent);
          });

          const payload = {
            method: 'POST',
            url: '/api/user/searches/' + resultsSearchID,
            cancelToken: state.connectionsCancelToken.token,
            body: { nodes }
          };

          const resp = await dispatch('ajaxWithTokenRefresh', payload);
          await dispatch('setCurrentConnections', 'hard');

          if (resp.status === 200) {
            resp.data.nodes.forEach((oid, i) => { entitiesToSave[i].oid = oid; });
            if (relations.length) {
              const half = Math.ceil(entitiesToSave.length / 2);
              const relations = entitiesToSave.splice(0, half);
              const connectionProfiles = entitiesToSave.splice(-half);
              connectionProfiles.forEach((ent, idx) => {
                ent.edge = relations[idx];
                ent.edge.child_id = ent.oid;
              });
              commit('updateConnectionEntitiesMap', connectionProfiles);
              commit('addEdges', relations);
            } else {
              commit('updateConnectionEntitiesMap', entitiesToSave);
            }

            commit('SET_LOADER_BIG', false);
            router.push(state.connectionsPath);
          }
        } catch (e) {
          commit('SET_LOADER_BIG', false);
          console.error(e);
        }
      }

      const sidebarRelations = getters.sortedRelationSearches;
      if (relations.length && entities.length && sidebarRelations.length) {
        commit('addToHistory', {
          relations,
          profiles: entities,
          parentProfile: entities[0].parent,
          id: searchId
        });
        commit('openedRelationsSearchId', searchId);
      }
    },
    async stopSearching ({ commit, state, getters, rootState }, openConnections) {
      if (openConnections && !state.callConnFromMultiprofile) {
        commit('setSearching', false);
        commit('searchConnections', false);
        if (!rootState.multiprofile.opened) {
          commit('openConnections', state.between || !state.connectionsCancelToken.token.reason);
          commit('activateRelationsSection', true);
        }
        document.title = getters.currentCaseName + ' — Connection search results';
      }
    },
    async loadConnections ({ commit, dispatch, getters, state }, relation) {
      const relationProfiles = [];
      const relations = [];
      let parentProfile;
      const history = state.history[relation.id];

      if (history) {
        relationProfiles.push(...history.profiles);
        relations.push(...history.relations);
        parentProfile = history.parentProfile;
        relations.forEach((rel) => {
          rel.visible = true;
          rel.checked = false;
        });
        relationProfiles.forEach(profile => {
          profile.visible = true;
          profile.checked = false;
          commit('addConnectionToDict', { key: profile.id || profile.oid, val: profile });
          commit('addConnection', profile);
        });
      }

      if (!history) {
        const payload = {
          method: 'POST',
          url: '/api/user/relations/list',
          body: {
            rsid: relation.id,
            search_id: relation.search_id,
            limit: 1000,
            offset: 0
          }
        };
        while (true) {
          const response = await dispatch('ajaxWithTokenRefresh', payload);
          parentProfile = response.data.targets[0];
          const foundRelations = response.data.relations || [];
          const foundProfiles = response.data.profiles || [];
          foundRelations.forEach((rel, idx) => {
            rel.visible = true;
            rel.checked = false;
            relations.push(rel);
            const profile = foundProfiles[idx];
            profile.visible = true;
            profile.checked = false;
            profile.edge = rel;
            profile.posts = {};
            profile.parent = parentProfile;
            relationProfiles.push(profile);
          });
          if (foundProfiles.length < payload.body.limit) {
            break;
          }
          payload.body.offset += payload.body.limit;
        }

        commit('addToHistory', {
          profiles: relationProfiles,
          relations,
          parentProfile,
          id: relation.id
        });
        await dispatch('convertEntityFieldsToMap', relationProfiles);
        await dispatch('convertEntityFieldsToMap', relations);
        await dispatch('convertEntityFieldsToMap', [parentProfile]);
        relationProfiles.forEach(profile => {
          commit('addConnectionToDict', { key: profile.oid, val: profile });
          commit('addConnection', profile);
        });
      }
      commit('setProfileToConnect', parentProfile);
      commit('updateConnectionEntitiesMap', relationProfiles);
      commit('addEdges', relations);
      commit('allConnectionsFound', true);
    },
    async openConnections ({ commit, dispatch, getters, state }, relation) {
      commit('openedRelationsSearchId', relation.id);
      commit('clearConnectionsBetween');
      commit('clearConnections', false);
      commit('clearEdges', false);
      commit('allConnectionsFound', false);
      commit('setLoadUrl', true);
      commit('setSearching', true);
      Array.from(document.getElementsByClassName('profile-sidebar')).forEach(el => {
        el.classList.remove('profile-sidebar--show');
      });
      commit('searchConnections', true);
      commit('uncheckAllResults');
      commit('checkAllConnections', false);
      commit('openResults', false);
      commit('openSavedInTagsPage', false);
      commit('updateBetweenState', false);
      commit('openMultiprofile', false);

      await dispatch('loadConnections', relation);
    },
    async getConnectionSearches ({ dispatch, getters }, data) {
      try {
        const payload = {
          method: 'POST',
          url: '/api/user/relations/searches/list',
          body: {
            case_id: getters.currentCase.oid,
            limit: 50,
            offset: 0
          }
        };
        const allSearches = [];
        while (true) {
          const response = await dispatch('ajaxWithTokenRefresh', payload);
          const searches = response.data.Searches || [];
          allSearches.push(...searches);
          if (searches.length < payload.body.limit) {
            break;
          }
          payload.body.offset += payload.body.limit;
        }
        return allSearches;
      } catch (e) {
        console.error(e);
        return [];
      }
    },
    async saveConnectionSearch ({ dispatch, commit }, data) {
      try {
        const search = {
          title: data.title,
          case_id: data.case_id,
          search_id: data.search_id,
          targets: data.targets
        };
        const response = await dispatch('ajaxWithTokenRefresh', {
          method: 'POST',
          url: '/api/user/relations/searches',
          body: search
        });
        search.created_at = Date.now();
        search.id = response.data;
        commit('addRelationSearches', [search]);
        return response.data;
      } catch (e) {
        console.error(e);
        return '';
      }
    },
    async clearConnections ({ state, commit }) {
      if (state.between) {
        commit('clearConnectionsBetween');
      } else {
        commit('clearConnections', false);
      }
    },
    async getConnectionsSearchTitle ({ state }, profiles) {
      if (!state.between) {
        return `${getEntityName(profiles[0])}{end}`;
      }
      return `${getEntityName(profiles[0])}{middle}${getEntityName(profiles[1])}`;
    },
    async fetchConnections ({ state, dispatch }, { url, taskID }) {
      const payload = {
        method: 'GET',
        url,
        cancelToken: state.connectionsCancelToken.token
      };

      if (taskID) {
        payload.url = payload.url + '&task_id=' + taskID;
      }

      return await dispatch('ajaxWithTokenRefresh', payload);
    },
    async finishConnectionsSearch ({ dispatch, commit, state }, { openConnections, timerID }) {
      await dispatch('stopSearching', openConnections);
      commit('allConnectionsFound', !state.timers.length);
      if (timerID) commit('removeTimer', timerID);
      await dispatch('setCurrentConnections', 'hard');
      commit('SET_LOADER_BIG', false);
      router.push(state.connectionsPath);
    },
    async manageConnectionsResponseErrors ({ commit }, error) {
      console.error(error);
      if (error.response?.data?.code === 305) {
        commit('setNoSubscribe', true);
        commit('setConditionsOfSubscribeModal', true);
      } else if (error.response?.data?.code === 306) {
        commit('setNoSlPoints', true);
        commit('setConditionsOfSubscribeModal', true);
      } else {
        commit('setAnotherErrors', true);
      }
    },
    async fetchManyConnectionRequests ({ dispatch, commit }, { taskID, url, originProfile, allProfiles, numberOfRepetitions = 8 }) {
      let counter = 0;
      const isLastProfile = originProfile.entity === allProfiles[allProfiles.length - 1];
      const timerID = setInterval(async () => {
        const response = await dispatch('fetchConnections', { url, taskID });
        commit('updateBalance', response.headers['x-user-balance']);
        if (response.status === 200) {
          const data = response.data;
          const result = data.result;
          const responseStatus = data.status || '';

          if (result?.connections?.length && ['SUCCESS', 'PENDING'].includes(responseStatus)) {
            await dispatch('getConnectionsData', { originProfile, response, allProfiles });
          }

          if (responseStatus === 'SUCCESS') {
            await dispatch('finishConnectionsSearch', { openConnections: isLastProfile, timerID });
          } else if ((responseStatus !== 'PENDING' || (responseStatus === 'PENDING' && !result))) {
            if (counter > numberOfRepetitions) {
              await dispatch('finishConnectionsSearch', { openConnections: isLastProfile, timerID });
            }
            counter++;
          }
        } else {
          await dispatch('finishConnectionsSearch', { openConnections: isLastProfile, timerID });
        }
      }, 30000);
      commit('addTimer', timerID);
    },
    async findConnections ({ state, dispatch, commit, rootState }, preProfiles) {
      const profiles = JSON.parse(JSON.stringify(preProfiles));
      commit('SET_LOADER_BIG', true);

      profiles.forEach(profile => {
        const newFields = {};
        Object.keys(profile.fields).forEach(key => {
          newFields[key] = { name: key, value: profile.fields[key] };
        });
        profile.fields = newFields;
      });

      try {
        if (!await dispatch('enoughBalance', 15)) return;

        commit('updateBetweenState', profiles.length > 1);
        await dispatch('clearConnections');

        if (!rootState.multiprofile.opened) {
          commit('allConnectionsFound', false);
          commit('setSearching', true);
          commit('searchConnections', true);
          commit('openSavedInTagsPage', false);
          commit('openMultiprofile', false);
          commit('uncheckAllResults');
          commit('checkAllConnections', false);
          commit('openResults', false);
        }

        if (!state.between) commit('setProfileToConnect', profiles[0]);
        const title = await dispatch('getConnectionsSearchTitle', profiles);
        const searchId = await dispatch('saveConnectionSearch', {
          title,
          case_id: router.currentRoute.value.params.id,
          search_id: profiles[0].rid,
          targets: [profiles[0].oid]
        });

        commit('setConnectionsPath', '/case/' + router.currentRoute.value.params.id + '/' + profiles[0].rid + '/' + searchId);

        for (const profile of profiles) {
          let taskID = profile.task_id;
          const isLastProfile = profile === profiles[profiles.length - 1];
          const builder = new ConnectionsBuilder(profile, isLastProfile, searchId);
          const originProfile = builder.buildOriginProfile();
          const socialNet = builder.socialNet;
          const url = builder.buildRequest().url;
          const resp = await dispatch('fetchConnections', { url, taskID });
          commit('updateBalance', resp.headers['x-user-balance']);
          if (resp.status === 200) {
            taskID = profile.task_id = resp.data.task_id;
            if (taskID) {
              await dispatch('fetchManyConnectionRequests', {
                taskID, url, originProfile, allProfiles: profiles
              });
            } else if (['SUCCESS', 'PENDING'].includes(resp.data.status) || !socialNet !== 'facebook') {
              await dispatch('getConnectionsData', { originProfile, response: resp, allProfiles: profiles });
              commit('allConnectionsFound', !state.timers.length);
            }
            commit('allConnectionsFound', true);
          } else if (resp.status >= 300 || resp.status === 204) {
            commit('setAnotherErrors', true);
            await dispatch('finishConnectionsSearch', { openConnections: isLastProfile });
          } else {
            if (state.between) {
              const mergedConnectionsBetween = await dispatch('mergeConnectionsBetween');
              if (mergedConnectionsBetween.length) {
                await dispatch('saveEntities', { entities: mergedConnectionsBetween, relations: [mergedConnectionsBetween[0].edge], searchId });
                await dispatch('savePosts', { connectionProfiles: mergedConnectionsBetween, searchId });
              }
            }
            await dispatch('finishConnectionsSearch', { openConnections: isLastProfile });
          }
        }
      } catch (error) {
        await dispatch('manageConnectionsResponseErrors', error);
        await dispatch('finishConnectionsSearch', { openConnections: true });
      }
    }
  }
};
