<template>
  <div class="no-phone-flags">
    <input
      type="tel"
      name="phone"
      id="phone"
      autocomplete="tel"
      maxlength="16"
      placeholder="+1 ____ - ___ - __ - __"
      class="input bg-form"
      :class="[!inputClass ? 'input' : inputClass, { 'error-border': showPhoneError && !phoneValid }]"
      v-model="phone"
      @input="paintBorder"
    />
  </div>
  <div class="error mt-12" v-if="showPhoneError && !phoneValid"><Icon name="warning" class="mr-13"/>Not valid number</div>
</template>

<script>
import intlTelInput from 'intl-tel-input';
import utils from 'intl-tel-input/build/js/utils';
import Icon from './Icon.vue';
import { mapGetters } from 'vuex';
import { eventListener } from '../../utils/helpers';

export default {
  name: 'InputPhone',
  props: ['inputClass', 'phoneState'],
  components: {
    Icon
  },
  emits: ['itiPhoneState', 'writePhone'],
  data () {
    return {
      phone: '',
      // intlTelInput library state
      itiPhone: null,
      showPhoneError: false
    };
  },
  async mounted () {
    const input = document.querySelector('#phone');
    this.itiPhone = intlTelInput(input, {
      nationalMode: false,
      formatOnDisplay: false,
      utilsScript: utils
    });
    this.$emit('itiPhoneState', this.itiPhone);
    if (this.phoneState) {
      this.phone = this.phoneState;
      this.itiPhone.setNumber(this.phoneState);
    }

    // Checking and displaying the error only after clicking on the "accept" button"
    eventListener.on('checkPhoneError', () => {
      if (!this.phoneValid) {
        this.showPhoneError = true;
        this.$emit('checkPhoneError', true);
      } else {
        this.showPhoneError = false;
        this.$emit('checkPhoneError', false);
      }
      this.paintBorder();
    });

    if (this.mainInfoFromReg) {
      this.phone = this.mainInfoFromReg.phone;
    }
  },
  computed: {
    ...mapGetters(['mainInfoFromReg']),
    phoneValid () {
      return this.phone && this.itiPhone ? this.itiPhone.isValidNumber() : true;
    }
  },
  methods: {
    paintBorder () {
      this.$emit('writePhone', this.phone);
      this.showPhoneError && !this.phoneValid
        ? document.querySelector('.iti__selected-flag').classList.add('error-border')
        : document.querySelector('.iti__selected-flag').classList.remove('error-border');
    }
  }
};
</script>
