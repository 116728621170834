export default {
  state: {
    profile: null
  },
  getters: {
    getProfile (state) {
      return state.profile;
    }
  },
  mutations: {
    SET_PROFILE: (state, value) => { state.profile = value; }
  },
  actions: {
    setProfile (ctx, profile) {
      ctx.commit('SET_PROFILE', profile);
    }
  }
};
