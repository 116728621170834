<template>
  <div id="login-layout">
    <header class="mobile-header">
      <img src="@/assets/icons/new-logo.png" alt="" class="mr-12">
      <a id="sign-logo" href="" class="typography-1 fw-700 color-black">GAMAYUN</a>
    </header>
    <InformSidebar />
    <router-view />
    <footer class="mobile-footer mt-65">
      <div class="inform-sidebar__footer">
        <a href="https://www.youtube.com/channel/UCcFD986JKvXXU88w0SRfvow" class="header-link" target="_blank">
          <Icon name="youtube" color="#000000"/>
        </a>
        <a href="https://twitter.com/_SocialLinks_" class="header-link" target="_blank">
          <Icon name="twitter" color="#000000"/>
        </a>
        <a href="https://www.linkedin.com/company/10294269/" class="header-link" target="_blank">
          <Icon name="linkedin" color="#000000"/>
        </a>
        <a href="https://www.facebook.com/Social-Links-Maltego-Transforms-810934209023184" class="header-link" target="_blank">
          <Icon name="facebook" color="#000000"/>
        </a>
      </div>
    </footer>
  </div>
</template>

<script>
import InformSidebar from '../components/app/InformSidebar.vue';
import Icon from '../components/app/Icon.vue';

export default {
  components: {
    InformSidebar,
    Icon
  }
};
</script>
