<template>
  <div class="sidebar__body">
    <div class="sidebar__subhead sidebar-line">
      <h2 class="typography-5 color-theme break-word mb-6" v-if="$route.path === '/' || $route.name.includes('Monitoring')">
        Hi, {{accountState.name}}!
      </h2>
      <Tooltip
        :text="currentCaseName"
        :limit="26"
        className="typography-5 color-theme mb-6 wbreak-break-word"
      />
      <span class="typography-base">Any questions? <a id="sidebar-write-to-us" class="color-theme fw-600" @click="$store.commit('openIssuesModal', true)">Write to us</a></span>
    </div>
    <nav class="sidebar__options" v-if="$route.path === '/' || $route.name === 'Plan' || $route.name.includes('Monitoring')">
      <div :class="$route.path === '/' ? 'sidebar__options-item--active' : ''" class="sidebar__options-item column-start">
        <div class="d-flex justify-between align-center w-100">
          <a
            v-if="visibleInvestigations.length === 0 && $route.path === '/'"
            @click.prevent="createNewCase"
            href="#"
            class="sidebar-router router-link-active d-flex align-center border-0"
            id="sidebar-cases"
          >
            <div class="icon-wrap mr-12">
              <Icon name="case" />
            </div>
            <span>Cases</span>
          </a>
          <router-link
            v-else
            to="/"
            class="sidebar-router d-flex align-center border-0"
            id="sidebar-cases"
          >
            <div class="icon-wrap mr-12">
              <Icon name="case" />
            </div>
            <span>Cases</span>
          </router-link>
          <Feature visible="VUE_APP_NSA_1560">
            <a id="sidebar-add-case" class="link-type mr-6" @click="checkCaseLimit"><Icon name="plus"/></a>
          </Feature>
          <Feature visible="!VUE_APP_NSA_1560">
            <a id="sidebar-add-case" class="link-type mr-6" @click="createNewCase"><Icon name="plus" /></a>
          </Feature>
        </div>

        <Feature visible="VUE_APP_NSA_1560">
          <div v-if="casesLimit" class="d-flex jc-space-between ai-self-stretch">
            <div class="uppercase fs-11">{{ getAllInvestigationsCount }}/{{ casesLimit}} cases left</div>
            <div class="uppercase fs-11 cursor-pointer color-link" @click="upgradeCases">upgrade</div>
          </div>
        </Feature>

        <Feature visible="!VUE_APP_NSA_1352">
          <ul class="sidebar-sublist mt-12 pl-24 p-0" v-if="visibleInvestigations && visibleInvestigations.length">
            <li v-for="inv in visibleInvestigations" :key="inv.oid" class="mt-6">
              <router-link :to="'/case/' + inv.oid" class="link-height justify-between typography-8 lh-20 open-case test-sidebar-case">
                <div class="color-2 hover-link">{{inv.tag}}</div>
                <div class="">{{date(inv.crtunix)}}</div>
              </router-link>
            </li>
            <li class="d-flex justify-between typography-7 mt-8" v-if="getAllInvestigationsCount > 4">
              <span class="counter">{{visibleInvestigations.length}}/{{getAllInvestigationsCount}} left</span>
              <a
                id="sidebar-hide-cases"
                class="color-theme fw-600 more-link link-type"
                @click="hide" v-if="visibleInvestigations && visibleInvestigations.length > 5"
              >
                Hide
              </a>
              <a
                id="sidebar-show-cases"
                class="color-theme fw-600 more-link link-type"
                @click="showMore"
                v-if="visibleInvestigations && visibleInvestigations.length <= 5 && getInvestigations.length > 5"
              >
                Show all
              </a>
            </li>
          </ul>
        </Feature>
        <Feature visible="VUE_APP_NSA_1352">
          <ul class="sidebar-sublist is-cases mt-12 pl-24 p-0" v-if="visibleInvestigations && visibleInvestigations.length">
            <li
              v-for="(investigation, index) in visibleInvestigations"
              :key="investigation.oid"
              class="sidebar-sublist__item"
            >
              <SidebarCase
                :investigation="investigation"
                :index="index"
              />
            </li>
            <li class="d-flex justify-between typography-7 mt-8" v-if="getAllInvestigationsCount > 4">
              <span class="counter">{{visibleInvestigations.length}}/{{getAllInvestigationsCount}} left</span>
              <a
                id="sidebar-hide-cases"
                class="color-theme fw-600 more-link link-type"
                @click="hide" v-if="visibleInvestigations && visibleInvestigations.length > 5"
              >
                Hide
              </a>
              <a
                id="sidebar-show-cases"
                class="color-theme fw-600 more-link link-type"
                @click="showMore"
                v-if="visibleInvestigations && visibleInvestigations.length <= 5 && getInvestigations.length > 5"
              >
                Show all
              </a>
            </li>
          </ul>
        </Feature>
      </div>
      <Feature visible="VUE_APP_NSA_1291">
        <div
          v-if="accountState.subscription.name !== 'Trial'"
          :class="{'sidebar__options-item--active': isMonitoringActive}"
          class="sidebar__options-item column-start">
          <div class="d-flex justify-between align-center w-100">
            <router-link
              id="sidebar-monitoring"
              :to="monitoringLink"
              class="sidebar-router d-flex align-center border-0"
              :class="{'router-link-active router-link-exact-active': isMonitoringActive}"
            >
              <div class="icon-wrap mr-12">
                <Icon name="monitoring-icon" />
              </div>
              <span>Monitoring</span>
              <div id="sidebar-add-monitoring" :class="{'beta--active': isMonitoringActive}" class="beta">beta</div>
            </router-link>
            <router-link to="/monitoring/settings" class="link-type mr-6 border-0"><Icon name="plus" /></router-link>
          </div>
          <ul class="sidebar-sublist mt-12 pl-24 p-0" v-if="monitoredCasesToShow.length > 0">
            <li v-for="inv in monitoredCasesToShow" :key="inv" class="mt-6">
              <router-link
                :to="{ name: 'MonitoringReview', params: { case_id: inv.oid } }"
                class="link-height justify-between typography-8 lh-20 sidebar-router-link test-sidebar-monitoring"
                active-class="sidebar-router-link--active"
              >
                <div v-if="inv.tag.length <= 28">Case {{ inv.tag }}</div>
                <div v-else>Case {{ inv.tag.substring(0, 28) + '...' }}</div>
                <div class="">{{date(inv.crtunix)}}</div>
              </router-link>
            </li>
            <li class="d-flex justify-between typography-7 mt-8" v-if="monitoredCases.length > step">
              <span class="counter">
                {{monitoredCases.length - monitoringCasesToShowCount}}/{{monitoredCases.length}} left
              </span>
              <a
                id="sidebar-hide-monitoring-cases"
                class="color-theme fw-600 more-link link-type"
                @click="monitoringCasesToShowCount = step" v-if="monitoredCasesToShow.length > step"
              >
                Hide
              </a>
              <a
                id="sidebar-show-monitoring-cases"
                class="color-theme fw-600 more-link link-type"
                @click="monitoringCasesToShowCount = monitoredCases.length"
                v-if="monitoredCasesToShow.length < monitoredCases.length"
              >
                Show all
              </a>
            </li>
          </ul>
          <SidebarAlert local-storage="monitoringBeta">
            <template v-slot:title>Monitoring (beta) is now available</template>
            <template v-slot:text>
              Gamayun now has a new monitoring feature for receiving subject profile updates without performing manual checks.
              <br><br>
              Users are informed of alterations via email at set intervals, and the whole history of profile changes is automatically stored with the case, meaning you can easily add them to a report if necessary.
              <br><br>
              As the function is still in its beta version, it currently only supports LinkedIn profiles, with other major platforms being added to the feature soon.
              <br><br>
              We’d greatly appreciate any feedback users can give us on the feature’s usability and functionality.
            </template>
          </SidebarAlert>
        </div>
      </Feature>
      <router-link id="sidebar-plan" :to="planLink" class="sidebar-router sidebar__options-item">
        <div class="icon-wrap mr-12">
          <Icon name="plan" />
        </div>
        <span>Plan</span>
      </router-link>
      <!-- <a href="" class="sidebar__options-item">
        <div class="icon-wrap mr-12">
          <Icon name="gallery" />
        </div>
        <span>Face gallery</span>
      </a> -->
    </nav>
    <nav class="sidebar__options" v-if="$route.name === 'Case' || $route.name === 'Report'">
      <div :class="isResultsSectionActive ? 'sidebar__options-item--active' : ''" class="sidebar-line">
        <a id="sidebar-profile-results" class="sidebar__options-item border-0 link-type sidebar-add-search" @click="addNewSearch">
          <div class="icon-wrap mr-12">
            <Icon name="new-search" :color="isResultsSectionActive ? colorTheme : colorGrey" />
          </div>
          <span :class="isResultsSectionActive ? 'color-theme' : 'grey-color'">Profile search results</span>
          <Icon name="plus" class="right-icon" :color="isResultsSectionActive ? colorTheme : colorGrey" />
        </a>
        <ul class="sidebar-sublist pl-24 pr-30 mb-12" v-if="searchHistory.length">
         <li class="d-flex justify-between pointer" v-for="history in loadedProfilesSearches" :key="history" @mouseover="hoveredHistory = history" @mouseleave="hoveredHistory = null">
            <a
              @click="openResults(history)"
              :class="openSearch && openSearch.rid === history.rid && $route.name === 'Case' && !activateRelationsSection ? 'color-theme fw-600' : ''"
              class="ellipsis-text main-hover-link sidebar-open-results test-sidebar-search"
            >
              <template v-for="(value, name) in history.query" :key="value">
                <span
                  class="typography-base fw-500"
                  v-if="value"
                  @click="changeDocumentTitle"
                >
                  {{name[0].toUpperCase() + name.replace('_', ' ').substring(1)}}: {{getReadableSearchQuery(name, value)}} &nbsp;
                </span>
              </template>
            </a>
            <span
              @click.stop="deleteSearchHistory(history)"
              :class="getClasses(history)"
              v-show="!historyLoader"
              class="trash-hover pointer test-sidebar-search-remove"
            >
              <Icon name="trash" bg="none"/>
            </span>
            <div class="right-icon right-icon--0 d-flex loading-circles" v-if="historyLoader === history.rid">
              <span class="load-circle load-circle--1"></span>
              <span class="load-circle load-circle--2"></span>
              <span class="load-circle load-circle--3"></span>
            </div>
          </li>
            <li class="d-flex justify-between typography-7 mt-8" v-if="searchHistory.length > 4">
            <span class="counter">{{loadedProfilesSearches.length}}/{{searchHistory.length}}</span>
            <a class="color-theme fw-600 more-link link-type test-sidebar-search-hide" @click="profilesSearchesLimit = step" v-if="profilesSearchesLimit > loadedProfilesSearches.length">Hide</a>
            <a class="color-theme fw-600 more-link link-type test-sidebar-search-show" @click="profilesSearchesLimit += currentHistory.length " v-if="profilesSearchesLimit <= loadedProfilesSearches.length">Show all</a>
          </li>
        </ul>
      </div>
      <div class="sidebar-line" :class="isRelationsSectionActive ? 'sidebar__options-item--active' : ''" v-if="loadedRelationSearches.length">
        <div class="sidebar__options-item border-0 pe-none">
          <div class="icon-wrap mr-12">
            <Icon name="connections" :color="isRelationsSectionActive ? colorTheme : colorGrey" />
          </div>
          <span :class="isRelationsSectionActive ? 'color-theme' : 'grey-color'">
            Find connections results
          </span>
        </div>
        <ul class="sidebar-sublist pl-24 mb-12 pr-30">
          <li
            class="d-flex justify-between pointer"
            v-for="search in loadedRelationSearches"
            :key="search.id"
            @mouseover="hoveredRelation = search"
            @mouseleave="hoveredRelation = null"
          >
            <a
              class="ellipsis-text main-hover-link sidebar-open-connections test-sidebar-relation"
              @click="openConnections(search);"
              :class="isRelationsSectionActive && (search.id === openedRelationsSearchId) ? 'color-theme fw-600' : ''"
            >
              <span class="typography-base fw-500">{{search.title}}</span>
            </a>
            <span
              @click.stop="deleteRelation(search)"
              class="trash-hover test-sidebar-relation-remove" :class="getClasses(search)"
              v-if="!loading">
              <Icon name="trash" bg="none"/>
            </span>
            <div class="right-icon right-icon--0 d-flex loading-circles" v-if="loading === search.id">
              <span class="load-circle load-circle--1"></span>
              <span class="load-circle load-circle--2"></span>
              <span class="load-circle load-circle--3"></span>
            </div>
          </li>
          <li class="d-flex justify-between typography-7 mt-8" v-if="sortedRelationSearches.length > 4">
            <span class="counter">{{loadedRelationSearches.length}}/{{sortedRelationSearches.length}}</span>
            <a
              id="sidebar-more-connections"
              class="color-theme fw-600 more-link link-type"
              @click="relationSearchesLimit += sortedRelationSearches.length"
              v-if="sortedRelationSearches.length !== loadedRelationSearches.length"
            >
              Show all
            </a>
            <a
              id="sidebar-hide-connections"
              class="color-theme fw-600 more-link link-type"
              @click="relationSearchesLimit = step"
              v-if="relationSearchesLimit > loadedRelationSearches.length"
            >
              Hide
            </a>
          </li>
        </ul>
      </div>
      <SidebarTags v-if="!tagsLoading" />
     <span @click="changeDocumentTitle('report')" id="sidebar-report">
       <router-link :to="'/case/' + currentCase.oid + '/report'" class="sidebar__options-item test-sidebar-report" v-if="!tagsLoading">
         <div class="icon-wrap mr-12">
           <Icon name="report" />
         </div>
         <span>Report</span>
       </router-link>
     </span>
    </nav>
  </div>

  <Feature visible="VUE_APP_NSA_1566">
    <DeleteModal v-if="openDeleteModal.status" :closeButton="openDeleteModal.closeButton" @closeModal="closeDeleteModal"/>
  </Feature>

  <Feature visible="VUE_APP_NSA_1560">
    <CasesLimitReachedModal v-if="openCasesLimitModal" @closeModal="openCasesLimitModal = false"/>
  </Feature>
</template>

<script>
import Feature from '@/components/app/Feature';
import Icon from './Icon';
import SidebarCase from '@/components/SidebarCaseBlock';
import SidebarTags from '../SidebarTagsBlock';
import SidebarAlert from '@/components/sidebar/Alert';
import Tooltip from '@/components/UI/Tooltip';
import DeleteModal from '@/components/modals/DeleteModal';
import CasesLimitReachedModal from '@/components/modals/CasesLimitReachedModal';
import { mapGetters } from 'vuex';
import { eventListener, getReadableSearchQuery, dateFormat } from '../../utils/helpers';

export default {
  name: 'SidebarOld',
  components: {
    Feature,
    Icon,
    SidebarCase,
    SidebarTags,
    SidebarAlert,
    Tooltip,
    DeleteModal,
    CasesLimitReachedModal
  },
  data () {
    return {
      limit: 5,
      profilesSearchesLimit: 4,
      openModal: false,
      relationSearchesLimit: 4,
      historySearchesLimit: 4,
      monitoringCasesToShowCount: 4,
      step: 4,
      hoverIcon: '',
      hoveredRelation: null,
      hoveredHistory: null,
      loading: false,
      historyLoader: false,
      colorGrey: '#A0B2BE',
      colorTheme: '#60BCE3',
      showTooltip: false,
      openDeleteModal: {
        status: false,
        closeButton: 'Make new search'
      },
      openCasesLimitModal: false
    };
  },
  mounted () {
    eventListener.on('changeLimitCasesInSidebar', () => {
      if (this.visibleInvestigations.length > 5) {
        this.limit = this.getInvestigations.length;
      }
    });
  },
  computed: {
    ...mapGetters(['accountState', 'getInvestigations', 'currentHistory', 'results', 'openSearch', 'currentCase',
      'getAllInvestigationsCount', 'currentCaseName', 'loadingSearches', 'tagsLoading', 'sortedRelationSearches', 'sortedHistorySearches', 'sortedConnections', 'connections', 'connectionsBetween', 'mergedConnectionsBetween']),
    activateRelationsSection () {
      return this.$store.state.isRelationsSectionActive;
    },
    openedRelationsSearchId () {
      return this.$store.getters.openedRelationsSearchId;
    },
    loadedRelationSearches () {
      return this.sortedRelationSearches.slice(0, this.relationSearchesLimit);
    },
    loadedProfilesSearches () {
      return this.searchHistory.slice(0, this.profilesSearchesLimit);
    },
    loadedSearchesHistory () {
      return this.sortedHistorySearches.slice(0, this.historySearchesLimit);
    },
    visibleInvestigations () {
      return this.getInvestigations.slice(0, this.limit);
    },
    searchHistory () {
      return this.currentHistory;
    },
    isResultsSectionActive () {
      return this.$route.name === 'Case' && !this.activateRelationsSection;
    },
    isRelationsSectionActive () {
      return this.$route.name !== 'Report' && this.activateRelationsSection;
    },
    planLink () {
      if (this.accountState.subscription.name === 'Trial') return '/paywall';
      else return '/plan';
    },
    isMonitoringActive () {
      return this.$route.path.includes('/monitoring');
    },
    monitoredCases () {
      return this.$store.getters.monitoredCasesSorted;
    },
    monitoredCasesToShow () {
      return this.monitoredCases.slice(0, this.monitoringCasesToShowCount);
    },
    monitoringLink () {
      let link = '/monitoring/settings';
      if (this.monitoredCases.length > 0 || this.$route.path === '/monitoring/settings') link = '/monitoring';
      return link;
    },
    casesLimit () {
      const getLimit = this.accountState.subscription.options['gamayun.cases.limit'];
      return getLimit || null;
    },
    maltegoSubscription () {
      return this.accountState.subscription.maltego && this.accountState.subscription.trial;
    }
  },
  methods: {
    getClasses (index) {
      if (this.hoveredRelation !== null && this.hoveredRelation === index) {
        return 'hovered-relation';
      }
      if (this.hoveredHistory !== null && this.hoveredHistory === index) {
        return 'hovered-history';
      }
    },
    async deleteSearchHistory (item) {
      const isActiveSearchHistory = this.openSearch && this.openSearch.rid === item.rid && this.$route.name === 'Case' && !this.activateRelationsSection;
      this.historyLoader = item.rid;
      const payload = {
        method: 'POST',
        url: '/api/user/recdel',
        body: {
          rid: item.rid
        }
      };
      await this.$store.dispatch('ajaxWithTokenRefresh', payload);
      this.$store.commit('deleteCurrentSearchHistory', item);
      this.historyLoader = false;
      this.$store.commit('setSearching', false);
      const relationsToDelete = this.loadedRelationSearches.filter(d => d.search_id === item.rid);
      relationsToDelete.forEach(item => this.deleteRelation(item, true));
      if (isActiveSearchHistory) this.addNewSearch();

      if (this.searchHistory.length === 0 && process.env.VUE_APP_NSA_1566) {
        if (!this.isResultsSectionActive) this.addNewSearch();
        this.openDeleteModal.status = true;
      }
    },
    async deleteRelation (item, isFromParent) {
      this.loading = item.id;
      const payload = {
        method: 'POST',
        url: '/api/user/relations/searches/delete',
        body: {
          id: item.id
        }
      };
      await this.$store.dispatch('ajaxWithTokenRefresh', payload);
      this.$store.commit('deleteConnectionFromRelationSearches', item);
      this.loading = false;
      if (!isFromParent && item.id === this.openedRelationsSearchId) {
        const activeSearchHistory = this.loadedProfilesSearches.filter(s => s.rid === item.search_id);
        this.openResults(activeSearchHistory[0]);
      }
    },
    getReadableSearchQuery (queryName, query) {
      return getReadableSearchQuery(queryName, query);
    },
    async openConnections (search) {
      this.$store.commit('activateRelationsSection', true);
      if (this.$route.name === 'Report') {
        this.$router.push({ path: '/case/' + this.$route.params.id });
      }
      await this.$store.dispatch('openConnections', search);
      this.$nextTick(async () => { await this.$store.dispatch('stopSearching', true); });
      document.getElementsByClassName('content')[0].scrollIntoView();
    },
    changeDocumentTitle () {
      this.$store.commit('openSavedInTagsPage', false);
      if ((this.$route.name === 'Case') || (this.$route.name === 'CasePage')) {
        document.title = this.$store.getters.currentCaseName + ((!this.$store.state.tags.openSavedInTagsPage) ? ' — Profile search results' : ' — Tags');
      }
    },
    date (val) {
      return dateFormat(val);
    },
    async showMore () {
      await this.$store.dispatch('loadInvestigations');
      this.limit = this.getInvestigations.length;
    },
    showMoreSearches () {
      this.profilesSearchesLimit = this.searchHistory.length;
    },
    hide () {
      this.limit = 5;
    },
    async createNewCase () {
      await this.$store.dispatch('createNewCase');
    },
    checkCaseLimit () {
      this.casesLimit && this.casesLimit <= this.getAllInvestigationsCount ? this.openCasesLimitModal = true : this.createNewCase();
    },
    upgradeCases () {
      if (this.casesLimit && this.casesLimit <= this.getAllInvestigationsCount) this.$router.push({ query: { ...this.$route.query, profile: 'plan' } });
    },
    async openResults (history) {
      // Switching from the report page
      if (this.$route.name === 'Report') {
        this.$router.push({ path: '/case/' + this.$route.params.id });
      }

      this.$store.dispatch('removeZeroResultsUrlParameter');
      this.$store.commit('hideSearch', true);
      await this.$store.dispatch('removeAllMapMarkers');
      if (!this.$store.state.results.onOverview) this.$store.commit('toggleViewController');
      this.$store.commit('activateRelationsSection', false);
      this.$store.commit('clearCurrentSearchResults');
      this.$store.commit('openSavedInTagsPage', false);
      this.$store.commit('selectProfile', null);
      this.$store.commit('removeLastVisibleOIDs');

      if (this.loadingSearches.has(history.rid)) {
        this.results.forEach(res => {
          if ((res.rid === history.rid) && (res.level === 1)) {
            res.visible = true;
            this.$store.commit('setCurrentSearchResults', res);
          } else res.visible = false;
        });
        document.getElementsByClassName('content')[0].scrollIntoView();
        if (!this.results.find(res => res.visible)) {
          this.$store.dispatch('addZeroResultsUrlParameter');
        }
      } else {
        this.$store.commit('setSearching', true);
        this.$store.commit('setLoadUrl', true);
        this.results.forEach(res => { res.visible = false; });

        const payload = {
          method: 'POST',
          url: '/api/user/searches/nodes',
          body: {
            search_id: history.rid,
            levels: [1],
            limit: 2000,
            offset: 0
          }
        };
        const response = await this.$store.dispatch('ajaxWithTokenRefresh', payload);
        this.$store.commit('addToLoadedSearches', payload.body.search_id);
        this.$store.dispatch('convertEntityFieldsToMap', response.data);
        this.$store.commit('updateResultsMap', response.data);

        response.data.forEach(res => {
          res = this.$store.getters.resultsMap[res.oid];
          res.checked = false;
          res.visible = true;
          this.$store.commit('addMoreResults', res);
          this.$store.commit('setCurrentSearchResults', res);
        });

        if (!response.data.length) {
          this.$store.dispatch('addZeroResultsUrlParameter');
        }

        this.$store.commit('addToLoadedSearches', history.rid);
        this.$store.commit('setSearching', false);
        this.$store.commit('setLoadUrl', false);
      }

      this.$store.commit('openResults', true);
      this.$store.commit('openedSearch', history);

      // Closing the display of the list of links, if they are open
      this.$store.commit('openConnections', false);
      this.$store.commit('openMultiprofile', false);
      this.$store.commit('setOpenFastReport', false);
      this.$store.dispatch('getLatLon', this.$store.state.results.currentSearchResults);
      eventListener.emit('clearFilter');
    },
    addNewSearch () {
      if (this.$route.name === 'Report') {
        this.$router.push(`/case/${this.$route.params.id}`);
      }
      this.$store.commit('openedSearch', null);
      this.$store.commit('openResults', false);
      this.$store.commit('openConnections', false);
      this.$store.commit('openSavedInTagsPage', false);
      this.$store.commit('openMultiprofile', false);
      this.$store.commit('setOpenFastReport', false);
    },
    async loadMore () {
      await this.$store.dispatch('loadInvestigations');
      this.limit = this.getInvestigations.length;
    },
    clickOpenConnections (search) {
      this.$store.commit('setFindConnectionEdit', false);

      this.openConnections(search);
    },
    closeDeleteModal () {
      this.openDeleteModal.status = false;
    }

  }
};
</script>

<style scoped lang="scss">
.trash-hover {
  display: none;
}
.hovered-history {
  display: list-item;
  svg {
    &:hover {
      path {
        fill: #60BCE3;
      }
    }
  }
}
.hovered-relation {
  display: list-item;
    svg {
    &:hover {
      path {
        fill: #60BCE3;
      }
    }
  }
}
.loading-circles {
  position: inherit !important;
  margin-top: 10px;
}
.fw-600 {
  span {
    font-weight: 600 !important;
  }
  .loading-circles {
    position: inherit !important;
    margin-top: 10px;
  }
  .fw-600 {
    span {
      font-weight: 600 !important;
    }
  }
  .load-circle {
    animation-name: bounce_circleG;
  }
  @keyframes bounce_circleG {
    50% {
      background-color: #51AEE2;
    }
  }
}
.counter {
  color: #A0B2BE !important;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 24px;
  letter-spacing: 0.08em;
}
.sidebar__options-item {
  .monitoring-beta {
    background-color: #F8FCFF !important;
  }

  &--active,
  &:hover {
    .monitoring-beta {
      background-color: #ffffff !important;
    }

    .counter {
      color: #A0B2BE !important;
    }
  }
}
.beta {
  background: rgba(180, 214, 255, 0.31);
  border-radius: 4px;
  padding: 2px 8px;
  font-style: normal;
  font-weight: 600;
  font-size: 11px;
  line-height: 16px;
  letter-spacing: 0.08em;
  text-transform: uppercase;
  color: #A0B2BE;
  margin-left: 8px;
  &--active {
    color: #60BCE3
  }
}

.tooltip-container {
  position: relative;
}
</style>
