import axios from 'axios';
import router from '@/router/indexNew.js';
import { arrayToObject } from '@/utils/helpers';

export default {
  state: {
    relations: [],
    targets: [],
    connectionsCancelToken: axios.CancelToken.source()
  },
  getters: {
    getAllRelations (state) {
      return state.relations;
    },
    getCurrentRelations (state) {
      const searchId = String(router.currentRoute.value.params.searchId);
      const connectionId = String(router.currentRoute.value.params.connectionId);

      if (state.relations[searchId + connectionId]) return state.relations[searchId + connectionId];
      else return [];
    },
    getVisibleRelations (state, getters) {
      if (getters.getCurrentRelations) {
        return getters.getCurrentRelations.filter(relation => relation.visible);
      } else return [];
    },
    getCheckedRelations (state, getters) {
      if (getters.getCurrentRelations) {
        return getters.getCurrentRelations.filter(relation => relation.checked);
      } else return [];
    },
    getCurrentRelTargets (state) {
      const searchId = String(router.currentRoute.value.params.searchId);
      const connectionId = String(router.currentRoute.value.params.connectionId);
      if (state.targets[searchId + connectionId]) return state.targets[searchId + connectionId];
      else return [];
    }
  },
  mutations: {
    SET_CURRENT_RELATIONS: (state, value) => { Object.assign(state.relations, value); },
    SET_CURRENT_REL_TARGETS: (state, value) => { Object.assign(state.targets, value); },
    SET_UPDATE_RELATIONS: (state, info) => {
      state.relations[info.path].map(relation => {
        if (relation.oid === info.relations.oid) Object.assign(relation, info.relations);
      });
    },
    SET_HARD_UPDATE_RELATIONS: (state, info) => { state.relations[info.path] = info.relations; }
  },
  actions: {
    async setCurrentRelations ({ commit, state, dispatch }, info) {
      let searchId = router.currentRoute.value.params.searchId;
      let connectionId = router.currentRoute.value.params.connectionId;

      if (info) {
        searchId = info.searchId;
        connectionId = info.connectionId;
      }

      if ((searchId && connectionId) && !state.relations[searchId + '' + connectionId]) {
        const response = await dispatch('ajaxWithTokenRefresh', {
          method: 'POST',
          url: '/api/user/relations/list',
          body: {
            limit: 5000,
            offset: 0,
            search_id: searchId,
            rsid: connectionId
          }
        });

        const childProfiles = {};
        const profiles = response.data?.profiles || [];
        profiles.forEach(p => {
          const childProfile = {};
          arrayToObject(p, 'fields', 'name');
          childProfile[p.parent_id] = p;
          Object.assign(childProfiles, childProfile);
        });

        const parentProfile = { ...response.data.targets[0] };
        arrayToObject(parentProfile, 'fields', 'name');

        const relations = {};
        relations[searchId + connectionId] = response.data.relations || [];
        relations[searchId + connectionId].forEach(r => {
          r.checked = false;
          r.visible = true;
          r.parentProfile = parentProfile;
          r.childProfile = childProfiles[r.oid];
          r.typeid = parentProfile.typeid;
          arrayToObject(r, 'fields', 'name');
        });
        relations[searchId + connectionId].sort((a, b) => b.weight - a.weight);

        const targets = {};
        targets[searchId + connectionId] = { ...parentProfile };

        commit('SET_CURRENT_RELATIONS', relations);
        commit('SET_CURRENT_REL_TARGETS', targets);
      }
    },
    setUpdateRelations ({ commit, state }, relations) {
      const searchID = String(router.currentRoute.value.params.searchId);
      const connectionId = String(router.currentRoute.value.params.connectionId);
      const path = searchID + connectionId;

      if (relations) {
        if (relations.oid) commit('SET_UPDATE_RELATIONS', { path, relations });
        else {
          if (relations.length < 5) {
            relations.forEach(relations => commit('SET_UPDATE_RELATIONS', { path, relations }));
          } else {
            commit('SET_HARD_UPDATE_RELATIONS', { path, relations });
          }
        }
      }
    },
    async setLoadNodes (ctx, connection) {
      const response = await ctx.dispatch('ajaxWithTokenRefresh', {
        method: 'POST',
        url: '/api/user/searches/nodes',
        body: {
          parent_id: connection.oid,
          search_id: connection.rid,
          levels: [4],
          limit: 1000,
          offset: 0
        }
      });

      response.data.forEach(node => {
        arrayToObject(node, 'fields', 'name');
      });
      connection.nodes = response.data;
    }
  }
};
