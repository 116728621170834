<template>
  <div class="router-container custom-scrollbar">
    <Settings v-if="activeLinks['settings']" />
    <Plan v-if="activeLinks['plan']" />
    <Statistics v-if="activeLinks['statistics']" />
    <Notifications v-if="activeLinks['notifications']" />
    <Faq v-if="activeLinks['faq']" />
    <Support v-if="activeLinks['support']" />
  </div>
</template>

<script>
import Settings from '@/components/profile/settings/Template';
import Plan from '@/components/profile/plan/Template';
import Statistics from '@/components/profile/statistics/Template';
import Notifications from '@/components/profile/notifications/Template';
import Faq from '@/components/profile/faq/Template';
import Support from '@/components/profile/support/Template';

export default {
  components: {
    Settings,
    Plan,
    Statistics,
    Notifications,
    Faq,
    Support
  },
  props: ['activeLinks']
};
</script>

<style lang="scss" scoped>
  .router-container {
    height: calc(100vh - 96px);
    overflow-x: hidden;
  }
</style>
