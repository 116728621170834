<template>
  <div class="p-48">
    <h4 class="text-h4">Enter the code</h4>
    <p class="text-h5 color-text mt-4">You received via SMS on {{ phone }}</p>
    <input type="number" min="0" placeholder="001001" class="input my-12" v-model="code">

    <div class="d-flex jc-space-between">
      <div class="text-base">Didn't get the sms?</div>
      <div class="text-link cursor-pointer" @click="sendAgain">Send again</div>
    </div>

    <div class="d-flex jc-space-between mt-16">
      <button class="br-5 bw-2 b-style-solid py-10 px-16 fw-600 fs-13 lh-16 let-space-08 transition w-416px color-link-darker bc-link-darker bg-link-darker_hover color-white_hover jc-center" @click="this.$emit('closePhone')">
        <Icon name="arrow-left" />
        &nbsp;&nbsp;Back
      </button>
      <button class="bttn-primary w-416px" :disabled="!code" @click="verifyPhone">Confirm number</button>
    </div>

    <div v-if="notification" class="d-flex jc-space-between mt-24 p-16" :class="error ? 'color-warning input-error' : 'color-link-darker input-info'">
      <div>
        <h5 class="text-h5 text-initial" :class="error ? 'color-warning' : 'color-link'">{{ notificationTitle }}</h5>
        <p class="text-base text-initial fw-400 pt-8" :class="{'color-warning': error}">{{ notificationText }}</p>
      </div>
      <Icon class="w-12px h-12px cursor-pointer" :color="error ? '#FF7A7A' : '#51AEE2'" name="cancel-btn" @click="notification = false" />
    </div>
  </div>
</template>

<script>
import Icon from '@/components/app/Icon.vue';

export default ({
  name: 'Phone',
  props: ['phone'],
  emits: ['closePhone'],
  components: {
    Icon
  },
  data () {
    return {
      code: null,
      notification: true,
      notificationTitle: 'Please confirm phone number',
      notificationText: "We've updated your profile information with the exception of your phone number.",
      error: false
    };
  },
  methods: {
    async verifyPhone () {
      const payload = {
        method: 'POST',
        url: '/api/v3/account/verify',
        body: {
          transport: 1,
          code: this.code
        }
      };

      try {
        const response = await this.$store.dispatch('ajaxWithTokenRefresh', payload);
        this.$emit('closePhone');
        console.log(response);
      } catch (error) {
        this.notificationTitle = 'Something went wrong';
        this.notificationText = 'Contact support team for help';
        this.notification = true;
        this.error = true;
        console.log(error);
      }
    },
    async sendAgain () {
      const payload = {
        method: 'PATCH',
        url: '/api/v3/account/update',
        body: {
          phone: this.phone
        }
      };
      try {
        const response = await this.$store.dispatch('ajaxWithTokenRefresh', payload);
        this.notificationTitle = 'Please confirm phone number';
        this.notificationText = "We've updated your profile information with the exception of your phone number.";
        this.notification = true;
        console.log(response);
      } catch (error) {
        this.notificationTitle = 'Something went wrong';
        this.notificationText = 'Contact support team for help';
        this.notification = true;
        this.error = true;
      }
    }
  }
});
</script>
