<template>
  <div class="dropdown-menu">
    <button class="login-btn" @click="dropdown = !dropdown">
      <Icon name="login"/>
      <span class="pl-16 pr-6">{{ accountState.name }}</span>
      <Icon name="arrow-down-white" color="#fff" />
    </button>
    <ul class="dropdown-block typography-base color-2" v-if="dropdown">
      <li><a id="logout" href="" @click="logout">Logout</a></li>
    </ul>
    <div class="background-wrapper" v-if="dropdown" @click="dropdown = !dropdown"></div>
  </div>
</template>

<script>
import Icon from '@/components/app/Icon.vue';
import { mapGetters } from 'vuex';

export default {
  name: 'LoginLink',
  components: {
    Icon
  },
  data () {
    return {
      dropdown: false
    };
  },
  computed: {
    ...mapGetters(['accountState'])
  },
  methods: {
    logout () {
      this.$store.dispatch('logout');
    }
  }
};
</script>
