<template>
  <div class="p-48">
    <ResetPassword v-if="resetPass" @backToLogin="backToPass" />
    <div v-else>
      <h2 class="text-h2">Enter password</h2>
      <h3 class="text-subheading color-text mt-12">In order to save changes, you need to enter a password.</h3>

      <div class="mt-24">
        <div class="d-flex jc-space-between ai-center">
          <label class="label">
            <span class="text-caption">Password</span>
          </label>
          <div class="text-link cursor-pointer" @click="resetPass = true">
            Forgot password
          </div>
        </div>

        <div class="input-block mb-6">
          <input
            class="input mt-6"
            :type="inputType"
            v-model="pass"
            @input="this.$emit('clearPassErrors')"
            placeholder="Enter the current password"
          />
          <Icon
            name="eye"
            class="input-block__counter cursor-pointer"
            :color='eyeColor'
            @mousedown="inputType = 'text'"
            @mouseup="inputType = 'text'"
            @mouseout="inputType = 'password' ; eyeColor = ''"
            @mouseover="eyeColor = '#A0B2BE'"
          />
        </div>
        <div v-if="errors.password_required" class="input-error">
          Invalid password.
        </div>
      </div>

      <div class="d-flex jc-space-between mt-24">
        <button class="br-5 bw-2 b-style-solid py-10 px-16 fw-600 fs-13 lh-16 let-space-08 transition w-416px color-link-darker bc-link-darker bg-link-darker_hover color-white_hover jc-center" @click="this.$emit('closePasswordRequired')">
          <Icon name="arrow-left" />
          &nbsp;&nbsp;Back
        </button>
        <button class="bttn-primary w-416px" :disabled="!pass" @click="submit">Confirm</button>
      </div>
    </div>
  </div>
</template>

<script>
import Icon from '@/components/app/Icon.vue';
import ResetPassword from '@/components/registration/ResetPassword';

export default ({
  name: 'PasswordRequired',
  props: ['errors'],
  emits: ['updateProfile', 'closePasswordRequired', 'setFormPassword', 'clearPassErrors'],
  components: {
    Icon,
    ResetPassword
  },
  data () {
    return {
      pass: null,
      inputType: 'password',
      eyeColor: '',
      resetPass: false
    };
  },
  methods: {
    submit () {
      this.$emit('setFormPassword', this.pass);
      this.$emit('updateProfile');
    },
    backToPass () {
      this.resetPass = false;
    }
  }
});
</script>
