<template>
  <!-- <div
    v-if="getSearchingHACK"
    class="d-flex align-center ai-center jc-center h-100"
  >
    <img src="@/assets/icons/searching.gif" class="h-78" />
  </div> -->
  <div
    class="px-24 pb-24"
  >
    <div class="text-h4 mt-24 mb-16">Social graph</div>
    <div class="input-info reset-text mb-24">
      <div class="text-h5 color-link mb-8">Enrich info</div>
      <div class="text-base color-text">You can extract more information on this profile's connections with an additional request.</div>
    </div>
    <button v-if="!!hasConnections" @click="openConnections" class="bttn-primary w-100">Open connections</button>
    <button v-else @click="findConnectionsMethod" class="bttn-primary w-100">Find connections (15 SLP)</button>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  props: {
    profile: {
      default: {}
    }
  },
  data () {
    return {
      loading: false
    };
  },
  created () {
    this.setCurrentConnections();
  },
  computed: {
    ...mapGetters(['getConnections', 'getSearchingHACK']),

    hasConnections () {
      if (this.getConnections) {
        const profileTarget = this.profile.oid;
        let target = false;
        this.getConnections.forEach(connection => {
          const ifConnection = connection.targets.find(target => target === profileTarget);
          if (ifConnection) target = connection.id;
        });
        return target;
      } else return false;
    }
  },
  methods: {
    ...mapActions(['findConnections', 'setCurrentConnections', 'ajaxWithTokenRefresh']),

    async findConnectionsMethod () {
      await this.findConnections([this.profile]);
    },

    openConnections () {
      let caseId = this.$route.params.id;
      if (this.profile.cid) caseId = this.profile.cid;
      this.$router.push(`/case/${caseId}/${this.profile.rid}/${this.hasConnections}`);
    }
  }
};
</script>
