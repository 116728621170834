import router from '@/router/indexNew.js';
import { arrayToObject } from '@/utils/helpers';

export default {
  state: {
    allResults: [],
    allConnections: []
  },
  getters: {
    getCurrentResults (state) {
      const allResults = state.allResults[router.currentRoute.value.params.searchId];
      if (allResults) return allResults;
      else return [];
    },
    getVisibleResults (state, getters) {
      if (getters.getCurrentResults) {
        return getters.getCurrentResults.filter(r => r.visible);
      } else return [];
    },
    getLocatedResults (state, getters) {
      if (getters.getCurrentResults) {
        return getters.getCurrentResults.filter(ent => ent.lat && ent.lon);
      } else return [];
    },
    getCountriesResults (state, getters) {
      return [...new Set(getters.getCurrentResults.map(profile => profile.country))];
    },
    getCurrentConnections (state) {
      const searchID = String(router.currentRoute.value.params.searchId);
      const connectionID = String(router.currentRoute.value.params.connectionId);
      console.log(state.allConnections[searchID + connectionID]);
      return state.allConnections[searchID + connectionID];
    }
  },
  mutations: {
    SET_ALL_RESULTS: (state, value) => Object.assign(state.allResults, value),
    SET_ALL_CONNECTIONS: (state, value) => Object.assign(state.allConnections, value)
  },
  actions: {
    async setCurrentResults ({ commit, state, dispatch }) {
      const searchID = router.currentRoute.value.params.searchId;

      if (searchID && !state.allResults[searchID]) {
        const response = await dispatch('ajaxWithTokenRefresh', {
          method: 'POST',
          url: '/api/user/searches/nodes',
          body: {
            search_id: searchID,
            levels: [1],
            limit: 2000,
            offset: 0
          }
        });
        const results = {};
        results[searchID] = response.data;

        results[searchID].forEach(n => {
          n.checked = false;
          n.visible = true;
          arrayToObject(n, 'fields', 'name');
        });
        commit('SET_ALL_RESULTS', results);
      }
    }
  }
};
