import { createRouter, createWebHistory } from 'vue-router';
import store from '../store';

const routes = [
  {
    path: '/login',
    name: 'Login',
    meta: { layout: 'sign', auth: false, title: 'Sign in' },
    component: () => import('@/views/Login.vue')
  },
  {
    path: '/registration',
    name: 'Registration',
    meta: { layout: 'sign', auth: false, title: 'Sign up' },
    component: () => import('@/views/Registration.vue')
  },
  {
    path: '/create_password',
    name: 'Create Password',
    meta: { layout: 'sign', auth: false, title: 'Create Password' },
    component: () => import('@/views/CreatePassword.vue')
  },
  {
    path: '/ui',
    name: 'UI KIT',
    meta: { layout: 'main', auth: true, title: 'UIkit' },
    component: () => import('@/views/UI.vue'),
    beforeEnter: async (to, from, next) => {
      try {
        if (!process.env.VUE_APP_UIKIT_MODE) next('/404');
        else next();
      } catch (error) {
        next('/404');
      }
    }
  },
  {
    path: '/case/:id',
    name: 'Case Page',
    meta: { layout: 'mainNew', auth: true, title: 'Cases' },
    component: () => import('@/viewsNew/Case.vue')
  },
  {
    path: '/tags/:id',
    name: 'Tags Page',
    meta: { layout: 'mainNew', auth: true, title: 'Cases' },
    component: () => import('@/viewsNew/Tags.vue')
  },
  {
    path: '/case/:id/:searchId',
    name: 'Search Profile',
    meta: { layout: 'mainNew', auth: true, title: 'Search Profile' },
    component: () => import('@/viewsNew/SearchProfile.vue')
  },
  {
    path: '/case/:id/:searchId/:connectionId',
    name: 'Connection Results',
    meta: { layout: 'mainNew', auth: true, title: 'Connection Results' },
    component: () => import('@/viewsNew/SearchConnections.vue')
  },
  {
    path: '/profile/:id/:searchId/:profileId',
    name: 'Profile Page',
    meta: { layout: 'mainNew', auth: true, title: 'Profile page' },
    component: () => import('@/viewsNew/Profile.vue')
  },
  {
    path: '/connection/:id/:searchId/:connectionId/:relationId',
    name: 'Connection Page',
    meta: { layout: 'mainNew', auth: true, title: 'Connection page' },
    component: () => import('@/viewsNew/Connection.vue')
  },
  {
    path: '/new-search/:id',
    name: 'New Search',
    meta: { layout: 'mainNew', auth: true, title: 'New Search' },
    component: () => import('@/viewsNew/NewSearch.vue')
  },
  {
    path: '/new-search/:id/:searchId',
    name: 'New Search ',
    meta: { layout: 'mainNew', auth: true, title: 'New Search' },
    component: () => import('@/viewsNew/NewSearch.vue')
  },
  {
    path: '/',
    name: 'Main',
    meta: { layout: 'mainNew', auth: true, title: 'Cases' },
    component: () => import('@/viewsNew/Main.vue')
  },
  {
    path: '/paywall',
    name: 'Paywall',
    meta: { layout: 'fullscreen', auth: true, title: 'Paywall' },
    component: () => import('@/views/Paywall.vue')
  },
  {
    path: '/plan',
    name: 'Plan',
    meta: { layout: 'mainNew', auth: true, title: 'Plan' },
    component: () => import('@/viewsNew/Plan.vue')
  },
  {
    path: '/:pathMatch(.*)',
    component: () => import('@/views/404.vue')
  },
  {
    path: '/terms',
    name: 'Terms&Policy',
    meta: { layout: 'sign', auth: false, title: 'Terms&Policy' },
    component: () => import('@/views/Terms&Policy.vue')
  },
  {
    path: '//signup/verification/:signup_token/:code',
    name: 'Verification',
    meta: { auth: false, title: 'Verification' },
    component: () => import('@/views/VerificationPage.vue')
  },
  {
    path: '//password/reset/:code',
    name: 'Reset Password ',
    meta: { auth: false, title: 'Reset Password' },
    component: () => import('@/views/VerificationPage.vue')
  },
  {
    path: '/sharing/case/:caseId',
    name: 'Case_Share',
    meta: { auth: true },
    beforeEnter: async (to, from, next) => {
      localStorage.setItem('caseShare', to.params.caseId);
      if (store.state.authAndRegistration.token.access) {
        next('/');
      } else {
        next('/login');
      }
    }
  },
  {
    path: '/account/coupon/:code',
    name: 'Reset Password',
    meta: { auth: true, title: 'Coupon' },
    beforeEnter: async (to, from, next) => {
      try {
        await store.dispatch('activateCoupon', to.params.code);
        store.commit('SET_BALANCE_FROM_COUPON');
      } catch (error) {
        console.error(error);
      }
      next('/');
    }
  },
  {
    path: '/monitoring',
    name: 'Monitoring',
    meta: { layout: 'mainNew', auth: true, title: 'Monitoring' },
    component: () => import('@/viewsNew/Monitoring.vue'),
    beforeEnter: async (to, from, next) => {
      store.commit('setSearching', true);
      store.commit('setLoadUrl', true);
      await store.dispatch('loadInvestigations');
      await store.dispatch('getMonitoringOptions');
      await store.dispatch('getMonitoredCases');
      await store.dispatch('getMonitoringTasks');
      next();
      store.commit('setSearching', false);
      store.commit('setLoadUrl', false);
    }
  },
  {
    path: '/monitoring/settings',
    name: 'MonitoringSettings',
    meta: { layout: 'mainNew', auth: true, title: 'Monitoring Settings' },
    component: () => import('@/viewsNew/MonitoringSettings.vue'),
    beforeEnter: async (to, from, next) => {
      store.commit('setSearching', true);
      store.commit('setLoadUrl', true);
      await store.dispatch('getMonitoringOptions');
      await store.dispatch('getMonitoredCases');
      await store.dispatch('getMonitoringTasks');
      const response = await store.dispatch('getAllInvestigations');
      store.commit('removeMonitoringCases');
      store.commit('addMonitoringCases', response.data.items);
      next();
      store.commit('setSearching', false);
      store.commit('setLoadUrl', false);
    }
  },
  {
    path: '/monitoring/review/:case_id',
    name: 'MonitoringReview',
    meta: { layout: 'mainNew', auth: true, title: 'Monitoring' },
    component: () => import('@/viewsNew/Monitoring.vue'),
    beforeEnter: async (to, from, next) => {
      store.commit('setSearching', true);
      store.commit('setLoadUrl', true);
      await store.dispatch('getMonitoringOptions');
      await store.dispatch('getMonitoredCases');
      await store.dispatch('getMonitoringTasks');
      next();
      store.commit('setSearching', false);
      store.commit('setLoadUrl', false);
    }
  },
  {
    path: '/diff-match',
    name: 'DiffMatch',
    meta: { layout: 'diffmatch', auth: false, title: 'DiffMatch' },
    component: () => import('@/views/DiffMatch.vue')
  },
  {
    path: '/find-face',
    name: 'FindFace',
    meta: { layout: 'fullscreen', auth: false, title: 'Find Face' },
    component: () => import('@/views/FindFace.vue')
  },

  {
    path: '/report/:id',
    name: 'Report Router',
    meta: { layout: 'mainNew', auth: true, title: 'Report' },
    beforeEnter: async (to, from, next) => {
      store.commit('setLoadingDirectUrl', true);
      try {
        if (store.state.selectedEntity === 'connection') {
          store.commit('setSearching', true);
          const investigation = await store.dispatch('checkCase', to.params.id);
          await store.dispatch('writeCaseAndResults', investigation);
          const relationsSearches = await store.dispatch('getConnectionSearches');
          store.commit('addRelationSearches', relationsSearches);
          next();
        } else {
          store.commit('activateRelationsSection', false);
          store.commit('clearRelationSearches');
          store.commit('selectProfile', null);
          store.commit('setSearching', true);
          store.commit('setLoadUrl', true);
          store.commit('clearConnections');
          store.commit('clearEdges');
          store.commit('openSavedInTagsPage', false);
          const investigation = await store.dispatch('checkCase', to.params.id);
          await store.dispatch('getUserTags');
          store.commit('setTagsLoading', true);
          await store.dispatch('writeCaseAndResults', investigation);
          const relationsSearches = await store.dispatch('getConnectionSearches');
          store.commit('addRelationSearches', relationsSearches);
          if (!store.state.results.onOverview) store.commit('toggleViewController');
          await store.dispatch('getLatLon', store.state.results.currentSearchResults);
          await store.dispatch('getUserTagsData', investigation.data.oid);
          await store.dispatch('getMultiprofilesWithData', investigation.data.oid);
          store.commit('setSearching', false);
          store.commit('setLoadUrl', false);
          store.commit('setTagsLoading', false);
          next();
        }
      } catch (error) {
        next('/404');
      }
      store.commit('setLoadingDirectUrl', false);
    },
    component: () => import('@/viewsNew/Report.vue'),
    children: [
      {
        path: 'report',
        name: 'Report',
        meta: { layout: 'mainNew', auth: true, title: 'Report' },
        component: () => import('@/viewsNew/Report.vue'),
        beforeEnter: async (to, from, next) => {
          try {
            store.commit('setSearching', true);
            store.commit('setLoadUrl', true);
            const payload = {
              method: 'GET',
              url: `/api/user/reports/${to.params.id}`
            };
            const report = await store.dispatch('ajaxWithTokenRefresh', payload);
            if (report.data.items) {
              store.commit('setReportItems', report.data.items);
            }
            store.commit('setTitle', report.data.title);
            store.commit('setSubtitle', report.data.subtitle);
            store.commit('setDescription', report.data.description);
            store.commit('setReportMapScreens', report.data.screenshots || []);
            store.commit('setReportId', report.data.oid);
            next();
            if (!store.state.tags.tagsLoading) {
              store.commit('setSearching', false);
              store.commit('setLoadUrl', false);
            }
          } catch (error) {
            if (error.response.data.message === 'There no report for this investigation') {
              store.commit('setTitle', '');
              store.commit('setSubtitle', '');
              store.commit('setDescription', '');
              next();
              if (!store.state.tags.tagsLoading) {
                store.commit('setSearching', false);
                store.commit('setLoadUrl', false);
              }
              const payload = {
                method: 'PUT',
                url: `/api/user/reports/${to.params.id}`,
                body: {
                  title: '',
                  subtitle: '',
                  description: '',
                  items: []
                }
              };
              const report = await store.dispatch('ajaxWithTokenRefresh', payload);
              this.$store.commit('setReportId', report.data.oid);
            } else {
              next('/404');
            }
          }
        }
      }
    ]
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

router.beforeEach(async (to, from, next) => {
  if (to.query?.token) {
    const token = {
      access: to.query.token,
      refresh: ''
    };
    localStorage.setItem('tokenGamayun', JSON.stringify(token));
    store.commit('setToken', token);
    store.commit('setAuthHeader');
    delete to.query;
    next(to);
  }
  if (to.name === 'Case_Share') {
    next();
    return;
  }
  const state = store.state.authAndRegistration;
  const requiredAuth = to.matched.some(record => record.meta.auth);
  if (state.token.access && !Object.keys(state.account).length) {
    await store.dispatch('checkAccount');
  }

  if (requiredAuth && state.token.access) {
    if (state.account.subscription) {
      next();
    } else if (from.path === '/login' || from.path === '/registration') {
      next(false);
    } else {
      next('/login');
    }
  } else if (requiredAuth && !state.token.access) {
    next('/login');
  } else if (!requiredAuth && !state.token.access) {
    next();
  } else if (state.account.subscription && (to.path === '/login' || to.path === '/registration')) {
    next('/');
  } else {
    next();
  }
});

export default router;
