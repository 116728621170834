import axios from 'axios';
import router from '../../router';
import intercom from '../../utils/intercom';
import { eventListener } from '../../utils/helpers';

export default {
  state: {
    token: {
      access: '',
      refresh: ''
    },
    authHeader: {},
    account: {},

    showMainInfoRegistration: true,
    showConfirmPhoneBlock: false,
    showPhoneCodeForm: false,
    showChangePhoneForm: false,
    showCompanyInfoForm: false,
    showConfirmEmailBlock: false,
    showEmailCodeForm: false,
    showChangeEmailForm: false,
    signUpToken: null,
    emailToConfirm: null,
    phoneToConfirm: null,
    timeoutToSendCode: null,
    mainInfoFromReg: null,
    companyInfoFromReg: null,
    successfulSendCode: null,
    codeForChangePassword: null,
    phoneToChangePassword: null
  },
  mutations: {
    setToken (state) {
      state.token.access = JSON.parse(localStorage.getItem('tokenGamayun')).access;
      state.token.refresh = JSON.parse(localStorage.getItem('tokenGamayun')).refresh;
    },
    setAuthHeader (state) {
      state.authHeader.Authorization = 'Bearer ' + state.token.access;
    },
    setAccount (state, obj) {
      state.account = obj;
      intercom();
      eventListener.emit('smartLookIdentify');
    },
    updateBalance (state, value) {
      if ((value !== 'unchanged') && (!isNaN(value)) && Number(value) !== -1 && (state.account.balance >= Number(value))) {
        state.account.balance = value;
      }
    },
    setTrialSubscription (state, val) {
      state.account.subscription = val;
      state.account.trial = val;
    },
    setBalance (state, val) {
      state.account.balance = val;
    },
    SET_BALANCE_FROM_COUPON (state) {
      state.account.balance += 300;
    },
    setShowConfirmPhoneBlock (state, value) {
      state.showConfirmPhoneBlock = value;
    },
    setShowMainInfoRegistration (state, value) {
      state.showMainInfoRegistration = value;
    },
    setShowPhoneCodeForm (state, value) {
      state.showPhoneCodeForm = value;
    },
    setShowChangePhoneForm (state, value) {
      state.showChangePhoneForm = value;
    },
    setShowCompanyInfoForm (state, value) {
      state.showCompanyInfoForm = value;
    },
    setShowConfirmEmailBlock (state, value) {
      state.showConfirmEmailBlock = value;
    },
    setShowEmailCodeForm (state, value) {
      state.showEmailCodeForm = value;
    },
    setShowChangeEmailForm (state, value) {
      state.showChangeEmailForm = value;
    },
    setSignUpToken (state, value) {
      state.signUpToken = value;
    },
    setEmail (state, value) {
      state.emailToConfirm = value;
    },
    setPhone (state, value) {
      state.phoneToConfirm = value;
    },
    setTimeoutToSendCode (state, value) {
      state.timeoutToSendCode = value;
    },
    setMainInfoFromReg (state, obj) {
      state.mainInfoFromReg = obj;
    },
    setCompanyInfoFromReg (state, obj) {
      state.companyInfoFromReg = obj;
    },
    setSuccessfulSendCode (state, value) {
      state.successfulSendCode = value;
    },
    setAccountConfirmEmail (state, value) {
      state.account.email_confirmed = value;
    },
    setCodeForChangePassword (state, value) {
      state.codeForChangePassword = value;
    },
    setPhoneToChangePassword (state, value) {
      state.phoneToChangePassword = value;
    }
  },
  getters: {
    accountState (state) {
      return state.account;
    },
    showConfirmPhoneBlock (state) {
      return state.showConfirmPhoneBlock;
    },
    showMainInfoRegistration (state) {
      return state.showMainInfoRegistration;
    },
    showPhoneCodeForm (state) {
      return state.showPhoneCodeForm;
    },
    showChangePhoneForm (state) {
      return state.showChangePhoneForm;
    },
    showCompanyInfoForm (state) {
      return state.showCompanyInfoForm;
    },
    showConfirmEmailBlock (state) {
      return state.showConfirmEmailBlock;
    },
    showChangeEmailForm (state) {
      return state.showChangeEmailForm;
    },
    showEmailCodeForm (state) {
      return state.showEmailCodeForm;
    },
    signUpToken (state) {
      return state.signUpToken;
    },
    emailToConfirm (state) {
      return state.emailToConfirm;
    },
    phoneToConfirm (state) {
      return state.phoneToConfirm;
    },
    timeoutToSendCode (state) {
      return state.timeoutToSendCode;
    },
    mainInfoFromReg (state) {
      return state.mainInfoFromReg;
    },
    companyInfoFromReg (state) {
      return state.companyInfoFromReg;
    },
    successfulSendCode (state) {
      return state.successfulSendCode;
    },
    codeForChangePassword (state) {
      return state.codeForChangePassword;
    },
    phoneToChangePassword (state) {
      return state.phoneToChangePassword;
    }
  },
  actions: {
    // A function that should be called for any method that requires authorization.
    // Accepts the url, data, and method in the parameters.
    // Internally, it executes the necessary server method and,
    // if the token is expired, updates it,
    // and then executes the method again and returns the result
    async ajaxWithTokenRefresh (ctx, payload) {
      const objToSend = {
        method: payload.method,
        headers: {
          ...this.state.authAndRegistration.authHeader,
          ...payload.headers
        },
        url: payload.url
      };
      if (payload.body) {
        objToSend.data = payload.body;
      }
      if (payload.cancelToken) {
        objToSend.cancelToken = payload.cancelToken;
      }
      try {
        return await axios(objToSend);
      } catch (error) {
        if (error.response && (error.response.status === 401 || error.response.status === 403)) {
          try {
            const body = { refresh: this.state.authAndRegistration.token.refresh };
            const results = await axios.post('/api/v2/token/refresh', body, {
              headers: this.state.authAndRegistration.authHeader
            });
            const token = {
              access: results.data.access,
              refresh: this.state.authAndRegistration.token.refresh
            };
            localStorage.setItem('tokenGamayun', JSON.stringify(token));
            ctx.commit('setToken');
            ctx.commit('setAuthHeader');
            return await axios(objToSend);
          } catch (error) {
            if (error.response.status === 401 || error.response.status === 403 || error.response.status === 500) {
              ctx.dispatch('logout');
            }
            throw error;
          }
        } else {
          throw error;
        }
      }
    },
    async checkAccount (ctx) {
      try {
        ctx.commit('setNoSubscribe', false);
        ctx.commit('setConditionsOfSubscribeModal', false);
        const payload = {
          method: 'GET',
          url: '/api/v2/account'
        };
        const result = await ctx.dispatch('ajaxWithTokenRefresh', payload);
        const accountOptions = {};
        result.data.subscription.options.forEach(o => {
          accountOptions[o.key] = o.value;
        });
        result.data.subscription.options = accountOptions;
        ctx.commit('setAccount', result.data);
        if (!((result.data.subscription && result.data.subscription.active) || (result.data.trial && result.data.trial.active))) {
          ctx.commit('setNoSubscribe', true);
          ctx.commit('setConditionsOfSubscribeModal', true);
        }
        return result;
      } catch (error) {
        console.error(error);
      }
    },
    logout (ctx) {
      this.state.authAndRegistration.token.access = '';
      this.state.authAndRegistration.token.refresh = '';
      localStorage.removeItem('tokenGamayun');
      router.push({ path: '/login' });
    },
    async checkTrial (ctx) {
      try {
        ctx.commit('setNoSubscribe', false);
        ctx.commit('setConditionsOfSubscribeModal', false);
        const payload = {
          method: 'POST',
          url: '/api/v2/trial'
        };
        const trial = await ctx.dispatch('ajaxWithTokenRefresh', payload);
        trial.data.active = true;
        ctx.commit('setBalance', trial.data.points);
        ctx.commit('setTrialSubscription', trial.data);
      } catch (error) {
        ctx.commit('setNoSubscribe', true);
        ctx.commit('setConditionsOfSubscribeModal', true);
      }
    },
    async sendCodeAgain (ctx, value) {
      try {
        ctx.commit('setTimeoutToSendCode', null);
        ctx.commit('setSuccessfulSendCode', null);
        const body = {
          value: value,
          signup_token: ctx.state.signUpToken
        };
        await axios.post('/api/v3/signup/verification/request', body);
        let type;
        if (value.indexOf('@') === -1) {
          type = 'code';
        } else {
          type = 'link';
        }
        ctx.commit('setSuccessfulSendCode', `The ${type} has been successfully sent`);
      } catch (error) {
        ctx.commit('setTimeoutToSendCode', error.response.data.detail);
      }
    },
    async authorization (ctx, payload) {
      try {
        const body = {
          login: payload.login,
          password: payload.password
        };
        const result = await axios.post('/api/v2/token', body);
        const token = {
          access: result.data.access,
          refresh: result.data.refresh
        };
        localStorage.setItem('tokenGamayun', JSON.stringify(token));
        ctx.commit('setToken');
        ctx.commit('setAuthHeader');
        const checkConfirmInfo = await axios.get('/api/v2/account', {
          headers: ctx.state.authHeader
        });
        if (!((checkConfirmInfo.data.subscription && checkConfirmInfo.data.subscription.active) || (checkConfirmInfo.data.trial && checkConfirmInfo.data.trial.active))) {
          ctx.commit('setNoSubscribe', true);
          ctx.commit('setConditionsOfSubscribeModal', true);
        }
        const accountOptions = {};
        checkConfirmInfo.data.subscription.options.forEach(o => {
          accountOptions[o.key] = o.value;
        });
        checkConfirmInfo.data.subscription.options = accountOptions;
        ctx.commit('setAccount', checkConfirmInfo.data);
        ctx.dispatch('loadProducts');
        return checkConfirmInfo.data.subscription;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    async checkConfirmInformation (ctx) {
      try {
        const payload = {
          method: 'POST',
          url: '/api/v2/trial'
        };
        const trial = await ctx.dispatch('ajaxWithTokenRefresh', payload);
        trial.data.active = true;
        ctx.commit('setBalance', trial.data.points);
        ctx.commit('setTrialSubscription', trial.data);
      } catch (error) {
        console.error(error);
        throw error;
      }
    }
  }
};
