<template>
  <div class="min-h-100 overlow-x-hidden bg-form" id="settings">

    <PasswordRequired v-if="requiredPassword" @updateProfile="updateProfile" @closePasswordRequired="closePasswordRequired" @setFormPassword="setFormPassword" :errors="errors" @clearPassErrors="clearPassErrors"/>
    <Phone v-else-if="showPhone" :phone="form.phone" @closePhone="closePhone" />
    <Email v-else-if="showEmail" :email="form.email" @closeEmail="closeEmail" />

    <form v-else
      @submit.prevent="sendForm"
      novalidate="true"
      class="d-grid g-min-content bg-form gap-24 p-48"
    >
      <div>
        <div v-if="sendSuccess" class="input-info d-flex jc-space-between pb-24">
          <div>
            <h5 class="text-h5 text-initial color-link-darker">Changes have been saved succesfully</h5>
            <p class="text-base fw-400 text-initial color-text mt-8">We've updated your profile information</p>
          </div>
          <Icon class="w-12px h-12px cursor-pointer" name="cancel-btn" @click="sendSuccess = false" />
        </div>
        <div v-if="sendError" class="input-error d-flex jc-space-between pb-24">
          <div>
            <h5 class="text-h5 text-initial color-warning">{{ sendErrorTitle }}</h5>
            <p class="text-base fw-400 text-initial color-warning mt-8">{{ sendErrorText }}</p>
          </div>
          <Icon class="w-12px h-12px cursor-pointer" color="#FF7A7A" name="cancel-btn" @click="closeError" />
        </div>
      </div>

      <div class="d-grid gap-16">
        <h2 class="text-h4">Personal data</h2>
        <div>
          <label class="label">
            <span>Your name</span>
          </label>
          <input
            class="input"
            type="text"
            v-model="form.name"
            :class="{ 'check-value': form.name === formState.name }"
          />
          <div
            v-if="errors.name"
            class="input-error"
          >
            {{ errors.name }}
          </div>
        </div>
        <div class="no-phone-flags">
          <label class="label">
            <span>Phone number</span>
            <span class="color-link">An sms will be sent to this number for account authorization</span>
          </label>
          <input
            class="input"
            id="phone"
            type="tel"
            @input="isPhoneValid"
            v-model="form.phone"
            :class="{ 'check-value': form.phone === formState.phone }"
          />
          <div
            v-if="errors.phone"
            class="input-error"
          >
            {{ errors.phone }}
          </div>
        </div>
        <div>
          <label class="label">
            <span>Your email</span>
            <span class="color-link">A mail will be sent to this email for account authorization</span>
          </label>
          <input
            class="input"
            type="email"
            @input="isEmailValid"
            v-model="form.email"
            :class="{ 'check-value': form.email === formState.email }"
          />
          <div
            v-if="errors.email"
            class="input-error"
          >
            {{ errors.email }}
          </div>
        </div>
        <div class="mt-28">
          <label class="label">
            <span>Purpose of use</span>
          </label>
        </div>
        <div class="mt-28">
          <label class="label">
            <span>User case</span>
          </label>

          <div class="input text-base cursor-pointer p-relative bg-form">
            <div @click="openCase = !openCase" class="d-flex jc-space-between ai-center">
              {{ selectedCase ? selectedCase : "Select value" }}
              <Icon name="arrow-down-white" :class="openCase ? 'arrow-up-white' : 'arrow-down-white'" class="mr-12" color="#60BCE3" />
            </div>
            <div v-if="openCase" class="p-absolute z-101 top-49px right-0px left-0px py-12 px-16 bg-form bc-link-border-lighter b-all br-bottom-left-5 br-bottom-right-5">
              <div v-for="(item, index) in cases" :key="index" class="mb-4">
                <a class="d-block color-link_hover" @click="selectedCase = item; openCase = false;">{{ item }}</a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="d-grid gap-16">
        <h2 class="text-h4">Password</h2>
        <div class="d-grid gtc-2 gap-x-8">
          <label class="label">
            <span>New Password</span>
          </label>
          <label class="label">
            <span>Repeat New Password</span>
          </label>
          <input
            class="input"
            type="password"
            v-model="form.newPassword"
            @input="clearPassErrors"
            :class="{ 'check-value': form.newPassword === formState.newPassword }"
            placeholder="At least 8 symbols"
          />
          <input
            class="input"
            type="password"
            v-model="form.repeatedNewPassword"
            @input="clearPassErrors"
            :class="{ 'check-value': form.repeatedNewPassword === formState.repeatedNewPassword }"
            placeholder="Enter the password again"
          />
        </div>
          <div v-if="errors.password_match" class="input-error">
            Passwords must match
          </div>
          <div v-if="errors.password_minLength" class="input-error">
            Password must have at least 8 letters
          </div>
          <div v-if="errors.password_maxLength" class="input-error">
            Password must have no more than 64 letters
          </div>
          <div v-if="this.errors.password_pattern" class="input-error">
            The password must contain:
              <ul>
                <li>Uppercase and lowercase letters <span class="text-initial">(Aa, Bb, Cc)</span></li>
                <li>Numbers (0...9)</li>
              </ul>
          </div>
      </div>

      <div class="d-grid gap-16">
        <h2 class="text-h4">Company info</h2>
        <div class="d-grid gtc-2 gap-x-8">
          <label class="label">
            <span>Company Name</span>
          </label>
          <label class="label">
            <span>Role in company</span>
          </label>
          <input
            class="input"
            type="text"
            v-model="form.company"
            :class="{ 'check-value': form.company === formState.company }"
          />
          <input
            class="input"
            type="text"
            :class="{ 'check-value': form.role === formState.role }"
          />
        </div>
        <div class="mt-28">
          <label class="label">
            <span>Industry</span>
          </label>
        </div>
        <div class="mt-28">
          <label class="label">
            <span>Role</span>
          </label>
          <input
            class="input"
            type="text"
            v-model="form.role"
            :class="{ 'check-value': form.role === formState.role }"
          />
        </div>
      </div>
      <div class="d-flex align-center jc-space-between">
        <div>
          <a
            class="text-link color-warning"
            @click.prevent="setDeleteAccountModal(true)"
          >
            Delete account
          </a>
        </div>
        <div>
          <button
            :disabled="!formChanged || Object.keys(this.errors).length > 0"
            id="edit-profile-info"
            class="bttn-primary w-260px"
          >Save</button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import intlTelInput from 'intl-tel-input';
import utils from 'intl-tel-input/build/js/utils';
import Icon from '@/components/app/Icon';
import Phone from '@/components/profile/settings/Phone';
import Email from '@/components/profile/settings/Email';
import PasswordRequired from '@/components/profile/settings/PasswordRequired';

export default {
  components: {
    Icon,
    Phone,
    Email,
    PasswordRequired
  },
  data () {
    return {
      formState: {},
      form: {},
      errors: {},
      formDelete: false,
      change: {},
      emailPattern: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      passPattern: /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])./,
      requiredPassword: false,
      showPhone: false,
      showEmail: false,
      sendSuccess: false,
      sendError: false,
      sendErrorTitle: 'Something went wrong',
      sendErrorText: 'Contact support team for help',
      cases: [
        'Background checks',
        'Digital footprints',
        'Fraud prevention',
        'Group structure identification',
        'Social monitoring',
        'Evidence collection',
        'Exposure assessment',
        'Data leak detection',
        'Corporate theft',
        'Due diligence',
        'Other'
      ],
      openCase: false,
      selectedCase: null
    };
  },
  created () {
    this.setForm();
  },
  async mounted () {
    if (this.$route.query.mail_result === 'success') this.sendSuccess = true;
    if (this.$route.query.mail_result === 'error') this.sendError = true;
    if (this.$route.query.mail_result) {
      await this.$store.dispatch('checkAccount');
      const query = Object.assign({}, this.$route.query);
      delete query.mail_result;
      this.$router.replace({ query });
      this.setForm();
    }

    const input = document.querySelector('#phone');
    this.itiPhone = intlTelInput(input, {
      utilsScript: utils,
      formatOnDisplay: false,
      nationalMode: false
    });
  },
  computed: {
    ...mapGetters(['accountState']),
    formChanged () {
      const comparedForm = Object.assign({}, this.form);
      delete comparedForm.password;
      return Object.keys(comparedForm).some(field => comparedForm[field] !== this.formState[field]);
    }
  },
  methods: {
    ...mapActions(['ajaxWithTokenRefresh']),
    ...mapMutations(['setDeleteAccountModal']),
    sendForm () {
      this.sendSuccess = false;
      this.sendError = false;

      if (this.form.phone && !this.itiPhone.isValidNumber()) {
        this.errors.phone = 'Not valid number';
        this.change.phone = true;
      }
      if (this.form.email && !this.form.email.match(this.emailPattern)) {
        this.errors.email = 'Wrong email address';
        this.change.email = true;
      }

      if (this.form.newPassword || this.form.repeatedNewPassword) {
        if (this.form.newPassword !== this.form.repeatedNewPassword) this.errors.password_match = true;

        if (this.form.newPassword.length < 8) this.errors.password_minLength = true;
        if (this.form.newPassword.length > 64) this.errors.password_maxLength = true;
        if (!this.form.newPassword.match(this.passPattern)) this.errors.password_pattern = true;
      }

      if (Object.keys(this.errors).length > 0) return;

      // this.updateProfile();
      this.requiredPassword = true;
      this.change = {};
    },
    isPhoneValid () {
      if (!this.change.phone || !this.form.phone) return;

      if (!this.itiPhone.isValidNumber()) this.errors.phone = 'Not valid number';
      else delete this.errors.phone;
    },
    isEmailValid () {
      if (!this.change.email || !this.form.email) return;

      const emailMatch = !!this.form.email.match(this.emailPattern);
      if (!emailMatch) this.errors.email = 'Wrong email address';
      else delete this.errors.email;
    },
    clearPassErrors () {
      if (this.errors.password_match) delete this.errors.password_match;
      if (this.errors.password_minLength) delete this.errors.password_minLength;
      if (this.errors.password_maxLength) delete this.errors.password_maxLength;
      if (this.errors.password_pattern) delete this.errors.password_pattern;
      if (this.errors.password_required) delete this.errors.password_required;
    },
    setForm () {
      const formData = {
        name: this.accountState.name || '',
        phone: this.accountState.phone || '',
        company: this.accountState.company || '',
        role: this.accountState.role || '',
        email: this.accountState.email || '',
        password: '',
        newPassword: '',
        repeatedNewPassword: ''
      };
      this.formState = Object.assign({}, formData);
      this.form = Object.assign({}, formData);
    },
    async updateProfile () {
      const payload = {
        method: 'PATCH',
        url: '/api/v3/account/update',
        body: this.form
      };
      payload.body.current_password = this.form.password;

      if (this.form.newPassword) payload.body.new_password = this.form.newPassword;

      if (!this.form.phone || this.form.phone === this.formState.phone) delete payload.body.phone;
      if (!this.form.email || this.form.email === this.formState.email) delete payload.body.email;
      if (!this.form.name) delete payload.body.name;

      try {
        const response = await this.$store.dispatch('ajaxWithTokenRefresh', payload);
        this.sendSuccess = true;
        if (!payload.body.phone && !payload.body.email) {
          await this.$store.dispatch('checkAccount');
          this.setForm();
        }
        if (payload.body.phone) this.showPhone = true;
        if (payload.body.email) this.showEmail = true;

        this.requiredPassword = false;
        console.log(response);
      } catch (error) {
        const reason = error.response.data;

        if (reason.code && reason.code === 'incorrect_password') {
          this.errors.password_required = 'Incorrect Password';
        } else if (reason.fields && reason.fields.phone) {
          const code = reason.fields.phone.filter(item => item.code === 'phone_in_use')[0];
          if (code) this.sendErrorText = reason.fields.phone[0].detail;
        } else if (reason.fields && reason.fields.email) {
          const code = reason.fields.email.filter(item => item.code === 'unique')[0];
          if (code) this.sendErrorText = reason.fields.email[0].detail;
        }

        if (reason.code && reason.code !== 'incorrect_password') {
          this.requiredPassword = true;
          await this.$store.dispatch('checkAccount');
          this.setForm();
        }
      }
      document.querySelector('#settings').scrollIntoView({ behavior: 'smooth' });
    },
    async closeEmail () {
      this.showEmail = false;
      await this.$store.dispatch('checkAccount');
      this.setForm();
    },
    async closePhone () {
      this.showPhone = false;
      await this.$store.dispatch('checkAccount');
      this.setForm();
    },
    async closePasswordRequired () {
      delete this.errors.password_required;
      this.sendError = true;
      this.sendErrorTitle = 'Changes not saved';
      this.sendErrorText = 'All new changes have been reset. You have not entered a password for confirmation.';

      this.requiredPassword = false;
      await this.$store.dispatch('checkAccount');
      this.setForm();
    },
    setFormPassword (pass) {
      this.form.password = pass;
    },
    closeError () {
      this.sendError = false;
      this.sendErrorTitle = 'Something went wrong';
      this.sendErrorText = 'Contact support team for help';
    }
  }
};
</script>
