<template>
  <div class="modal-background"></div>
  <div class="modal-wrap">
    <div class="modal">
      <button class="cancel-btn" @click="closeModal"><Icon name="cancel-btn" /></button>
      <slot></slot>
      <div class="d-flex">
        <button class="primary-btn mr-12" @click="confirmAnswer">Yes</button>
        <button class="opacity-btn" @click="closeModal">No</button>
      </div>
    </div>
  </div>
</template>

<script>
import Icon from './Icon';

export default {
  name: 'AcceptModal',
  components: {
    Icon
  },
  emits: ['closeModal', 'confirmAnswer'],
  methods: {
    closeModal () {
      this.$emit('closeModal');
    },
    confirmAnswer () {
      this.$emit('confirmAnswer');
    }
  }
};
</script>
