export default {
  state: {
    checkedValues: {}
  },
  getters: {
    getCheckedValues (state) {
      return state.checkedValues;
    }
  },
  mutations: {
    ADD_OR_REMOVE_CHECKED_ITEM (state, value) {
      if (state.checkedValues[value.oid]) {
        delete state.checkedValues[value.oid];
      } else {
        const keys = Object.keys(state.checkedValues);
        if (value.level === 2 && keys.length && state.checkedValues[keys[0]].level !== 2) {
          state.checkedValues = {};
        } else if (value.level !== 2 && keys.length && state.checkedValues[keys[0]].level === 2) {
          state.checkedValues = {};
        }
        state.checkedValues[value.oid] = value;
      }
    },
    CHECK_ALL_ITEMS (state, obj) {
      if (obj.checked) {
        const keys = Object.keys(state.checkedValues);
        if (obj.item.level === 2 && keys.length && state.checkedValues[keys[0]].level !== 2) {
          state.checkedValues = {};
        } else if (obj.item.level !== 2 && keys.length && state.checkedValues[keys[0]].level === 2) {
          state.checkedValues = {};
        }

        state.checkedValues[obj.item.oid] = obj.item;
      } else {
        delete state.checkedValues[obj.item.oid];
      }
    },
    CLEAR_CHECKED_VALUES (state) {
      state.checkedValues = {};
    }
  },
  actions: {

  }
};
