<template>
  <div
    @click="closeModal"
    class="p-fixed z-1003 top-0 left-0 w-100 h-100 bg-form-darker opacity-8 transition"
  ></div>
  <div
    class="send-modal p-fixed z-1003 bg-white abs-center w-100 br-5 b-all bc-body bs-controller p-48"
    :class="{
      'text-center': buttonsCount === 1
    }"
  >
    <a
      class="p-absolute top-24px right-24px d-block w-20px h-20px"
      @click.prevent="closeModal"
    >
      <Icon class="w-100 h-100" name="cancel-btn" />
    </a>
    <div class="overflow-hidden">
      <h2 v-if="title" class="text-h2 mb-12">{{ title }}</h2>
      <div v-if="subtitle" class="text-subheading color-text">{{ subtitle }}</div>
    </div>
    <div
      v-if="buttonsCount"
      class="d-grid gap-12 mt-24"
      :class="{
        'gtc-1 w-50 ml-auto mr-auto': buttonsCount === 1,
        'gtc-2': buttonsCount > 1
      }"
    >
      <button v-if="declineText" class="bttn-transparent" @click="closeModal">{{ declineText }}</button>
      <button v-if="confirmText" class="bttn-primary" :class="testInfo" @click="confirmAnswer">{{ confirmText }}</button>
    </div>

    <div
      v-if="loading"
      class="p-absolute top-0 left-0 right-0 bottom-0 d-flex align-center jc-center bg-white"
    >
      <div>
        <div class="mb-12">
          <img src="@/assets/icons/searching.gif" class="h-78">
        </div>
        <div class="text-center">
          <span class="typography-theme ml-10">Loading...</span>
          <!-- <button class="typography-theme color-link" id="cancel-search" @click="closeModal">Cancel</button> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Icon from '@/components/app/Icon';

export default {
  components: {
    Icon
  },
  props: {
    title: { default: null },
    subtitle: { default: null },
    confirmText: { default: null },
    declineText: { default: null },
    testInfo: { default: '' },
    loading: { default: false }
  },
  emits: ['closeModal', 'confirmAnswer'],
  computed: {
    buttonsCount () {
      let count = 0;
      if (this.confirmText) count += 1;
      if (this.declineText) count += 1;
      return count;
    }
  },
  methods: {
    closeModal () {
      this.$emit('closeModal');
    },
    confirmAnswer () {
      this.$emit('confirmAnswer');
    }
  }
};
</script>

<style lang="scss" scoped>
  .send-modal {
    max-width: 640px;
  }
</style>
