import router from '@/router/indexNew.js';
import { arrayToObject } from '@/utils/helpers';

export default {
  state: {
    tagColors: ['#CC73E1', '#65D938', '#15B1F3', '#EBBB01', '#A2845E', '#FF2967', '#FF9501', '#0DE396', '#4255FF', '#FF87AB'],
    taggedList: [],
    tagged: [],
    taggedProfiles: []
  },
  getters: {
    getTaggedList (state) {
      return state.taggedList;
    },
    getCurrentTagged (state) {
      return state.tagged[router.currentRoute.value.params.id] || [];
    },
    getTaggedProfiles (state) {
      return state.taggedProfiles;
    }
  },
  mutations: {
    SET_TAGGED_LIST: (state, value) => { state.taggedList = value; },
    SET_TAGGED: (state, value) => { Object.assign(state.tagged, value); },
    SET_CURRENT_TAGGED_UPDATE: (state, value) => { state.tagged[router.currentRoute.value.params.id] = value; }
  },
  actions: {
    async setTaggedList ({ commit, state, dispatch }, isUpdate) {
      if (!state.taggedList.length || isUpdate) {
        const response = await dispatch('ajaxWithTokenRefresh', {
          method: 'GET',
          url: '/api/user/tags'
        });
        response.data.forEach((tag, index) => {
          let tagIndex = index;
          if (index + 1 > state.tagColors.length) tagIndex = index % state.tagColors.length;
          tag.color = state.tagColors[tagIndex];
        });
        commit('SET_TAGGED_LIST', response.data);
      }
    },
    async setCurrentTagged ({ commit, state, dispatch }, isUpdate) {
      const caseID = router.currentRoute.value.params.id;

      if (!state.tagged[caseID] || isUpdate) {
        const response = await dispatch('ajaxWithTokenRefresh', {
          method: 'GET',
          url: `/api/user/tags/tagged/${caseID}`
        });
        const tagged = {};
        tagged[caseID] = response.data.tags;
        const nodes = response.data.nodes || [];
        nodes.forEach(node => {
          node.checked = false;
          node.cid = caseID;
          arrayToObject(node, 'fields', 'name');
        });
        nodes.forEach(node => {
          if (node.fields?.relation) {
            node.childProfile = { ...nodes.filter(child => child.parent_id === node.oid)[0] };
            node.parentProfile = { ...nodes.filter(parent => parent.rid === node.rid)[0] };
            node.typeid = node.parentProfile.typeid;
          }
        });

        tagged[caseID].forEach(tagged => {
          tagged.nodes = nodes.filter(node => node.tags.some(tag => tag === tagged.oid));
        });

        commit('SET_TAGGED', tagged);
      }
    },
    async setAddTagged ({ commit, state, getters, dispatch }, info) {
      await dispatch('ajaxWithTokenRefresh', {
        method: 'POST',
        url: '/api/user/tags',
        body: {
          ...info
        }
      });
      await dispatch('setCurrentTagged', true);
      await dispatch('setTaggedList', true);
    },
    async setRenameTagged (ctx, info) {
      if (info.tag.name.trim() !== '' && info.tag.name.length >= 1 && info.tag.name !== info.name) {
        const caseID = router.currentRoute.value.params.id;

        await ctx.dispatch('ajaxWithTokenRefresh', {
          method: 'PUT',
          url: '/api/user/tags',
          body: {
            ...info.tag
          }
        });

        const tagged = ctx.state.tagged[caseID].forEach(itm => {
          if (itm.oid === info.tag.id) itm.name = info.tag.name;
        });
        ctx.commit('SET_TAGGED', tagged);
      }
    },
    async setRemoveTagged (ctx, info) {
      const targets = [];
      info.nodes.forEach(node => {
        targets.push({ id: node.oid, type: 'entity' });
      });

      const body = { id: info.id, targets };

      await ctx.dispatch('ajaxWithTokenRefresh', {
        method: 'DELETE',
        url: '/api/user/tags',
        body: {
          ...body
        }
      });

      await ctx.dispatch('setCurrentTagged', true);
    }
  }
};
