<template>
  <div class="min-h-100 overlow-x-hidden bg-form p-48">
    <div class="text-h4">Additional purchases</div>

    <div class="p-24 bs-light br-6 mt-24">
      <div class="text-h4 mb-16">SL points (min - 100 slp, max - 10 000 slp)</div>

      <div class="text-base color-text-darker d-flex flex-w-wrap mb-16">
        <div class="w-50 mb-8">From 500 slp - 5% discount</div>
        <div class="w-50 mb-8">From 5 000 slp - 15% discount</div>
        <div class="w-50">From 1 000 slp - 10% discount</div>
        <div class="w-50">10 000 slp - 20% discount</div>
      </div>

      <div v-if="this.maltegoSubscription">
        <div class="input text-base cursor-pointer p-relative bg-form">
          <div @click="openProduct = !openProduct" class="d-flex jc-space-between ai-center">
            {{ splValue ? this.selectedSpl.name : "Select value" }}
            <Icon name="arrow-down-white" :class="openProduct ? 'arrow-up-white' : 'arrow-down-white'" class="mr-12" color="#60BCE3" />
          </div>
          <div v-if="openProduct" class="p-absolute z-101 top-49px right-0px left-0px py-12 px-16 bg-form bc-link-border-lighter b-all br-bottom-left-5 br-bottom-right-5">
            <div v-for="product in availableProducts" :key="product.points" class="mb-4">
              <a class="d-block color-link_hover" @click="selectProduct(product)">{{ product.name }}</a>
            </div>
          </div>
        </div>
      </div>

      <div v-else>
        <div class="input-container">
          <input type="number" min="100" max="10000" step="100" placeholder="slp" class="input mb-24" @keydown.enter="bySpl" v-model="splValue" @input="priceForCustomAmmount">
        </div>
        <div class="d-flex jc-space-between mb-16" v-if="(splValue > 0) && (+splValue !== Math.ceil(splValue / 100) * 100)">
          <span class="text-base">You can only top up your balance in an amount that is a multiple of 100.</span>
          <button class="text-link" @click="upSpl">up to&nbsp;<span>{{ Math.ceil(splValue / 100) * 100 }}</span>&nbsp;slp</button>
        </div>
      </div>

      <div class="d-flex jc-space-between ai-center mt-24">
        <div class="text-h6">Total
          <span class="color-link">
             <span v-if="this.maltegoSubscription">
              <span v-if="this.oldPrice" class="decor-through color-text-lighter">${{ this.oldPrice }}</span>
              ${{ splValue ? selectedSpl.price : 0 }}
            </span>
            <span v-else>
              <span v-if="customDiscount && customPrice" class="decor-through color-text-lighter">${{ (splValue / 100 * basePrice).toFixed(2) }}</span>
              ${{ customPrice }}
            </span>
          </span>
        </div>

        <button v-if="this.maltegoSubscription" class="bttn-primary w-260px" :disabled="this.splValue <= 0" @click="goToCheckout">Buy</button>
        <button v-else class="bttn-primary w-260px" :disabled="!this.customPrice" @click="bySpl">Buy</button>
      </div>

    </div>

    <div class="p-24 bs-light br-6 mt-16">
      <div class="text-h4 mb-16">Cases</div>
      <div>
        <input type="text" class="input mb-24 bg-form" v-model="addCases" disabled>
        <div class="d-flex jc-space-between ai-center">
          <div class="text-h6">Total <span class="color-link">{{ getCasesPrice }} SLP</span></div>
          <button class="bttn-primary w-260px" @click="upgradeCases">Buy</button>
        </div>
      </div>
    </div>
  </div>

  <Notification v-if="showNotification" :title="notificationTitle" :subtitle="notificationSubitle" :error="notificationError" icon="briefcase-fill" @closeNotification="closeNotification"/>

</template>

<script>
import { mapGetters } from 'vuex';
import Icon from '@/components/app/Icon';
import Notification from '@/components/modals/Notification';

export default ({
  components: {
    Notification,
    Icon
  },
  mounted () {
    this.addCases = this.getCasesStep;
  },
  data () {
    return {
      openProduct: false,
      splValue: null,
      customDiscount: 0,
      customPrice: 0,
      addCases: null,
      showNotification: false,
      notificationTitle: 'Some title should be here',
      notificationSubitle: 'Ups, it shouldn\'t be shown',
      notificationError: false
    };
  },
  computed: {
    ...mapGetters(['accountState', 'loadedProducts']),
    getCasesLimit () {
      return this.accountState.subscription.options['gamayun.cases.limit'];
    },
    getCasesPrice () {
      return this.accountState.subscription.options['gamayun.cases.limit.upgrade.price'];
    },
    getCasesStep () {
      return this.accountState.subscription.options['gamayun.cases.limit.upgrade.step'];
    },
    availableProducts () {
      const unsubProducts = this.loadedProducts.filter(p => p.subscription === false);
      return unsubProducts.sort((a, b) => a.points - b.points);
    },
    selectedSpl () {
      const result = this.availableProducts.filter(item => item.points === this.splValue)[0];
      return result;
    },
    basePrice () {
      return +this.availableProducts.find(item => item.code === '100_slp').price;
    },
    oldPrice () {
      if (!this.selectedSpl) return null;
      const price = this.basePrice / 100 * this.selectedSpl.points;
      return price === +this.selectedSpl.price ? null : price;
    },
    maltegoSubscription () {
      return this.accountState.subscription.maltego && this.accountState.subscription.trial;
    }
  },
  methods: {
    selectProduct (product) {
      this.splValue = product.points;
      this.openProduct = false;
    },
    upSpl () {
      this.splValue = Math.ceil(this.splValue / 100) * 100;
      this.priceForCustomAmmount();
    },
    async priceForCustomAmmount () {
      if (this.splValue > 10000) this.splValue = 10000;

      if (+this.splValue !== Math.ceil(this.splValue / 100) * 100) {
        this.splValue ? this.customPrice = null : this.customPrice = 0;
        return;
      }

      const payload = {
        method: 'GET',
        url: `/api/v3/ppg/packages/price/${this.splValue}`
      };
      try {
        const response = await this.$store.dispatch('ajaxWithTokenRefresh', payload);
        console.log(response);
        this.customDiscount = response.data.discount;
        this.customPrice = response.data.price;
      } catch (error) {
        console.log(error);
      }
    },
    async upgradeCases () {
      this.showNotification = false;

      const payload = {
        method: 'POST',
        url: '/api/v3/account/limits/upgrade',
        body: {
          key: 'gamayun.cases.limit'
        }
      };
      try {
        const response = await this.$store.dispatch('ajaxWithTokenRefresh', payload);
        this.notificationSubitle = `${this.getCasesPrice} slp has been withdrawn from your account`;
        await this.$store.dispatch('checkAccount');
        this.notificationTitle = `Your SPL has been increased to ${this.getCasesLimit}`;
        this.notificationError = false;
        this.showNotification = true;
        console.log(response);
      } catch (error) {
        this.notificationTitle = 'Something went wrong';
        this.notificationSubitle = 'Please, try again later';
        this.notificationError = true;
        this.showNotification = true;
      }
    },
    async bySpl () {
      this.showNotification = false;

      const payload = {
        method: 'POST',
        url: '/api/v3/ppg/packages/order',
        body: {
          amount: this.splValue
        }
      };
      try {
        const response = await this.$store.dispatch('ajaxWithTokenRefresh', payload);
        await this.$store.dispatch('checkAccount');
        this.notificationTitle = 'Your case limit has been increased';
        this.notificationSubitle = '';
        this.notificationError = false;
        this.showNotification = true;
        console.log(response);
      } catch (error) {
        console.log(error);
        this.notificationTitle = 'Something went wrong';
        this.notificationSubitle = 'Please, try again later';
        this.notificationError = true;
        this.showNotification = true;
      }
    },
    goToCheckout () {
      window.open(this.selectedSpl.checkout_url, '_blank');
    },
    closeNotification () {
      this.showNotification = false;
    }
  }
});
</script>
