export default {
  state: {
    title: '',
    subtitle: '',
    description: '',
    reportItems: [],
    openFastReport: false,
    mapScreens: [],
    checkedScreenshots: [],
    hideAvatarsFromConnections: false,
    reportId: null
  },
  mutations: {
    setReportItems (state, items) {
      state.reportItems = items;
    },
    setReportMapScreens (state, screenshots) {
      screenshots.forEach(element => {
        element.checked = false;
      });
      state.mapScreens = screenshots;
    },
    checkScreenshots (state, item) {
      state.mapScreens.flat().forEach(element => {
        if (element === item) {
          element.checked = !element.checked;
        }
      });
      if (item.checked) {
        state.checkedScreenshots.push(item);
      } else {
        state.checkedScreenshots.splice(item, 1);
      }
    },
    removeReportMapScreens (state, screenshot) {
      state.mapScreens = state.mapScreens.flat().filter(item => item.id !== screenshot.id);
    },
    addReportItem (state, item) {
      state.reportItems.push(item);
    },
    setTitle (state, val) {
      state.title = val;
    },
    setSubtitle (state, val) {
      state.subtitle = val;
    },
    setDescription (state, val) {
      state.description = val;
    },
    setOpenFastReport (state, val) {
      state.openFastReport = val;
    },
    hideAvatars (state, val) {
      state.hideAvatarsFromConnections = val;
    },
    setReportId (state, val) {
      state.reportId = val;
    }
  },
  getters: {
    getReportItems (state) {
      return state.reportItems;
    },
    getTitle (state) {
      return state.title;
    },
    getSubtitle (state) {
      return state.subtitle;
    },
    getDescription (state) {
      return state.description;
    },
    openFastReport (state) {
      return state.openFastReport;
    },
    hideAvatarsFromConnections (state) {
      return state.hideAvatarsFromConnections;
    },
    reportId (state) {
      return state.reportId;
    }
  },
  actions: {
    async saveItemNote ({ state, dispatch, commit }, obj) {
      try {
        let type;
        if (obj.entity.level === 2) {
          type = 2;
        } else if (obj.entity.color) {
          type = 3;
        } else if (obj.entity.id) {
          type = 4;
        } else {
          type = 1;
        }
        if (state.reportItems.length) {
          const el = state.reportItems.find(item => {
            return (item.oid === obj.entity.oid || item.oid === obj.entity.id);
          });
          if (!el) {
            commit('addReportItem', {
              oid: obj.entity.oid || obj.entity.id,
              note: obj.note,
              type: type
            });
          } else {
            el.note = obj.note;
          }
        } else {
          commit('addReportItem', {
            oid: obj.entity.oid || obj.entity.id,
            note: obj.note,
            type: type
          });
        }
        const payload = {
          method: 'PUT',
          url: `/api/user/reports/${obj.caseId}`,
          body: {
            title: state.title,
            subtitle: state.subtitle,
            description: state.description,
            items: state.reportItems
          }
        };
        await dispatch('ajaxWithTokenRefresh', payload);
        commit('setReportItems', state.reportItems);
      } catch (error) {
        console.error(error);
        throw error;
      }
    }
  }
};
