export default {
  state: {
    resultsMap: null
  },
  mutations: {
    addResultsMap (state, map) {
      state.resultsMap = map;
    }
  },
  getters: {},
  actions: {
    removeAllMapMarkers ({ state }) {
      const map = state.resultsMap;
      if (map) {
        map._panes.markerPane.remove();
        map._panes.shadowPane.remove();
      }
    },
    focusEntityOnMap ({ state }, entity) {
      if (!entity || !entity.lat || !entity.lon) return;
      state.resultsMap.flyTo([entity.lat, entity.lon], 18, { animate: false });
    }
  }
};
