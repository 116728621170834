<template>
  <button class="sidebar__options-item custom-padding w-100 border-0">
    <div class="d-flex move-block mr-12">
      <Icon name="trash" bg="#FFFFFF" class="trash-icon mr-4" @click.stop="removeTag" />
      <Icon name="edit" class="trash-icon trash-icon--blue" @click.stop="openInputTag" />
      <span class="tag-icon" :style="'background:' + tag.color"></span>
    </div>
    <div class="d-flex flex-wrap move-block align-center fz-13" @click.prevent="openSublist">
      <span class="color-2 fw-600 mr-12">{{tag.name}} &nbsp;</span>
      <div>
        <span class="fw-500 mr-12">({{tagEntities.length}} {{tagEntities.length > 1 ? 'items' : 'item'}})</span>
        <Icon name="arrow-down-white" color="#60BCE3" />
      </div>
    </div>
    <div class="right-icon right-icon--0 d-flex" v-if="tag.oid === loading">
      <span class="load-circle load-circle--1"></span>
      <span class="load-circle load-circle--2"></span>
      <span class="load-circle load-circle--3"></span>
    </div>
    <div class="right-icon right-icon--23" v-else>
      <input type="checkbox" name="" :id="'sidebar-checkbox-' + tag.oid" class="sidebar-checkbox" :checked="tag.checked">
      <label class="sidebar-checkbox__label" @click="checkAllEntities"></label>
    </div>
  </button>
  <ul class="sidebar-sublist sublist-accordeon pl-25 pr-0" :ref="tag.oid" v-if="tagEntities.length">
    <li class="mb-8" v-for="entity in tagEntities" :key="entity.oid">
      <TagEntity :entity="entity" :tag="tag" :index="index" @loading="setLoading" />
    </li>
  </ul>
</template>

<script>
import Icon from './app/Icon';
import TagEntity from './TagEntity';
import { eventListener } from '../utils/helpers';

export default {
  name: 'Tag',
  props: ['tag', 'index'],
  emits: ['showTagInput', 'addTaggedEntities'],
  data () {
    return {
      loading: ''
    };
  },
  created () {
    eventListener.on('uncheckingResults', () => {
      if (this.tagEntities && this.tagEntities.length) {
        this.tagEntities.forEach(ent => { ent.checked = false; });
      }
      this.$store.commit('uncheckTags');
    });
  },
  computed: {
    tagEntities () {
      const entities = [];
      const entitiesMap = {};
      const addTaggedEntities = (entities) => {
        Object.keys(entities).forEach(oid => {
          const ent = entities[oid];
          if (ent.tags && ent.tags.length) {
            if (ent.tags.includes(this.tag.oid)) {
              entitiesMap[ent.oid] = ent;
            }
          }
        });
      };
      const resultsMap = this.$store.getters.resultsMap;
      addTaggedEntities(resultsMap);
      Object.keys(resultsMap).forEach(oid => { addTaggedEntities(resultsMap[oid].children || []); });
      addTaggedEntities(this.$store.getters.connectionEntitiesMap);
      addTaggedEntities(this.$store.getters.edgesMap);
      addTaggedEntities(this.$store.getters.mergedConnectionsBetween);

      Object.keys(entitiesMap).forEach(oid => entities.push(entitiesMap[oid]));
      this.$emit('addTaggedEntities', entitiesMap);

      this.$nextTick(() => {
        const elem = this.$refs[this.tag.oid];
        if (elem && elem.style.maxHeight) {
          elem.style.maxHeight = elem.scrollHeight + 'px';
        }
      });

      return entities;
    }
  },
  methods: {
    setLoading (value) {
      this.loading = value;
    },
    async removeTag () {
      try {
        const findTaggedEntity = (entities, type = 'entity') => {
          Object.keys(entities).forEach(oid => {
            const ent = entities[oid];
            if (ent.tags && ent.tags.length) {
              const index = ent.tags.indexOf(this.tag.oid);
              if (index !== -1) {
                payload.body.targets.push({ type, id: ent.oid });
              }
            }
          });
        };

        this.loading = this.tag.oid;
        const payload = {
          method: 'DELETE',
          url: '/api/user/tags',
          body: {
            id: this.tag.oid,
            targets: []
          }
        };
        const updateResultsTags = {
          attachProfile: null,
          tag: this.tag
        };

        const resultsMap = this.$store.getters.resultsMap;
        findTaggedEntity(resultsMap);
        Object.keys(resultsMap).forEach(oid => { findTaggedEntity(resultsMap[oid].children || []); });
        findTaggedEntity(this.$store.getters.connectionEntitiesMap);
        findTaggedEntity(this.$store.getters.edgesMap);

        await this.$store.dispatch('ajaxWithTokenRefresh', payload);
        this.$store.commit('deleteTagsInResults', updateResultsTags);
        this.$store.commit('deleteTagsInConnections', updateResultsTags);
        this.$store.commit('deleteTagsInConnectionsBetween', updateResultsTags);
        this.$store.commit('deleteTagsInEdges', updateResultsTags);

        this.loading = '';
      } catch (error) {
        console.error(error);
      }
    },
    openInputTag () {
      this.$emit('showTagInput', this.tag.oid);
      const elem = this.$refs[this.tag.oid];
      elem.style.maxHeight = null;
      elem.style['margin-bottom'] = '0';
    },
    openSublist () {
      const elem = this.$refs[this.tag.oid];
      if (elem.style.maxHeight) {
        elem.style.maxHeight = null;
        elem.style['margin-bottom'] = '0';
      } else {
        elem.style.maxHeight = elem.scrollHeight + 'px';
        elem.style['margin-bottom'] = '16px';
      }
    },
    checkAllEntities () {
      this.$store.commit('checkTag', this.tag.oid);
      if (this.tagEntities && this.tagEntities.length) {
        this.tagEntities.forEach(ent => {
          if (!ent?.fields?.relation?.value) {
            ent.checked = this.tag.checked;
            this.$store.commit('checkedTagEntities', ent);
          }
        });
      }
    }
  },
  components: {
    Icon, TagEntity
  }
};
</script>

<style lang="scss" scoped>
  .custom-padding {
    padding-top: 10px;
    padding-bottom: 10px;
    min-height: auto;
  }
</style>
