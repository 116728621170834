<template>
    <h1 class="typography-1 color-black mb-32 px-8" v-if="resetForm || phoneForm || emailForm">Reset your password</h1>
    <div class="px-8" v-if="resetForm">
      <div class="container px-0">
        <div class="row mx--8">
          <div class="col-12 col-md-6 px-8 mb-16">
            <button id="reset-password-by-email" class="primary-btn mr-8 w-sm-100" @click="openEmailForm">
              By email
            </button>
          </div>
          <div class="col-12 col-md-6 px-8 mb-16">
            <button id="reset-password-by-phone" class="primary-btn w-sm-100" @click="openPhoneForm">
              By phone
            </button>
          </div>
        </div>
      </div>
      <button class="opacity-btn mr-16 max-w-100" @click="backToLogin" id="reset-password-back">
        <Icon name="arrow-left-blue" class="mr-8" />
        Back
      </button>
    </div>
    <div class="px-8" v-else-if="phoneForm">
      <div class="mb-12">
        <label class="typography-caption color-2 mb-8 let-space-08">Phone</label>
        <InputPhone @writePhone="writePhone" @itiPhoneState="itiPhoneState" @checkPhoneError="checkPhoneError" />
      </div>
      <div class="error" v-if="serverError || codeSendTimeout">
        <Icon name="warning" class="mr-13"/>
        <div class="d-flex flex-column align-start">
          <span>{{serverError || codeSendTimeout}}</span>
        </div>
      </div>
      <div class="container px-0 mt-32">
        <div class="row mx--8 confirm-buttons">
          <div class="col-12 col-md-6 px-8 mb-16">
            <button class="opacity-btn mr-16 w-sm-100" @click="backToReset" id="back-to-reset">
              <Icon name="arrow-left-blue" class="mr-8" />
              Back
            </button>
          </div>
          <div class="col-12 col-md-6 px-8 mb-16">
            <button
              id="reset-password-confirm"
              class="primary-btn typography-base w-sm-100"
              :disabled="!phone"
              @click="goToCode('phone')"
            >
              Confirm
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="px-8" v-else-if="emailForm">
      <div class="mb-12">
        <label for="email" class="typography-caption color-2 mb-8 let-space-08">Your business email</label>
        <input type="email" placeholder="Email" id="email" class="input bg-form" :class="errors.email && v$.email.$error ? 'error-border' : ''" v-model.trim="v$.email.$model">
      </div>
      <div class="error" v-if="errors.email && v$.email.$error">
        <Icon name="warning" class="mr-13"/>
        <div class="d-flex flex-column align-start">
          <span v-show="v$.email.maxLength.$invalid">Email must have no more than {{v$.email.maxLength.$params.max}} letters.</span>
          <span v-show="v$.email.email.$invalid">Wrong email address</span>
        </div>
      </div>
      <div class="error" v-if="serverError">
        <Icon name="warning" class="mr-13"/>
        <div class="d-flex flex-column align-start">
          <span>{{serverError}}</span>
        </div>
      </div>
      <div class="container px-0 mt-32">
        <div class="row mx--8 confirm-buttons">
          <div class="col-12 col-md-6 px-8 mb-16">
            <button class="confirm-buttons__back opacity-btn mr-16 w-sm-100" @click="backToReset">
              <Icon name="arrow-left-blue" class="mr-8" />
              Back
            </button>
          </div>
          <div class="col-12 col-md-6 px-8 mb-16">
            <button
              class="confirm-buttons__confirm primary-btn typography-base w-sm-100"
              :disabled="v$.email.$error || !email"
              @click="goToCode('email')"
            >
              Confirm
            </button>
          </div>
        </div>
      </div>
    </div>
    <template v-else-if="codeForm">
      <Feature visible="VUE_APP_NSA_1845">
        <div
          v-if="phone"
          class="px-8"
        >
          <h3 class="typography-1 color-black">Enter the code</h3>
          <div class="typography-11 color-2 mb-24">You receive via SMS to {{phone}}</div>
          <div class="input-block mb-12">
            <input
              id="reset-password-code"
              type="text"
              placeholder="Enter the code"
              class="input bg-form"
              :class="errors.sendCode && v$.sendCode.$error ? 'error-border' : ''"
              v-model.trim="v$.sendCode.$model"
              maxlength="6"
            >
            <span class="input-block__counter">{{v$.sendCode.$model.length}} / {{v$.sendCode.maxLength.$params.max}}</span>
          </div>
          <div class="error" v-if="errors.sendCode && v$.sendCode.$error">
            <Icon name="warning" class="mr-13"/>
            <div class="d-flex flex-column align-start">
              <span v-show="v$.sendCode.minLength.$invalid">Code must have at least {{v$.sendCode.minLength.$params.min}} letters.</span>
              <span v-show="v$.sendCode.maxLength.$invalid">Code must have no more than {{v$.sendCode.maxLength.$params.max}} letters.</span>
              <span v-show="v$.sendCode.numeric.$invalid">Code must be numeric</span>
            </div>
          </div>
          <div class="d-flex flex-column mt-24">
            <span class="typography-6 color-2 mb-16" v-if="codeSendTimeout">{{codeSendTimeout}}</span>
            <div class="d-flex justify-between">
              <span class="typography-6 color-2" v-if="successfulSendCode">{{successfulSendCode}}</span>
              <span class="typography-6 color-2" v-else>Did not get the code?</span>
              <a href="#" class="results-btn fw-600" @click.prevent="sendCodeAgain" id="reset-password-send-again">
                Send again
              </a>
            </div>
          </div>
          <div class="container px-0 mt-32">
            <div class="row mx--8 confirm-buttons">
              <div class="col-12 col-md-6 px-8 mb-16">
                <button id="back-to-phone" class="opacity-btn mr-16 w-sm-100" @click="backToPhone">
                  <Icon name="arrow-left-blue" class="mr-8" />
                  Wrong number?
                </button>
              </div>
              <div class="col-12 col-md-6 px-8 mb-16">
                <button
                  id="reset-password-next"
                  class="primary-btn typography-base w-sm-100"
                  :disabled="!sendCode"
                  @click="goToPassword"
                >
                  Next
                </button>
              </div>
            </div>
          </div>
        </div>
        <div
          v-else
          class="px-8">
          <h3 class="typography-1 color-black">We’ve sent a letter to you {{ email }}, please check it.</h3>
        </div>
      </Feature>
      <Feature visible="!VUE_APP_NSA_1845">
        <div class="px-8">
          <h3 class="typography-1 color-black">Enter the code</h3>
          <div class="typography-11 color-2 mb-24" v-if="phone">You receive via SMS to {{phone}}</div>
          <div class="typography-11 color-2 mb-24" v-else>Enter the code you receive by {{email}}</div>
          <div class="input-block mb-12">
            <input
              id="reset-password-code"
              type="text"
              placeholder="Enter the code"
              class="input bg-form"
              :class="errors.sendCode && v$.sendCode.$error ? 'error-border' : ''"
              v-model.trim="v$.sendCode.$model"
              maxlength="6"
            >
            <span class="input-block__counter">{{v$.sendCode.$model.length}} / {{v$.sendCode.maxLength.$params.max}}</span>
          </div>
          <div class="error" v-if="errors.sendCode && v$.sendCode.$error">
            <Icon name="warning" class="mr-13"/>
            <div class="d-flex flex-column align-start">
              <span v-show="v$.sendCode.minLength.$invalid">Code must have at least {{v$.sendCode.minLength.$params.min}} letters.</span>
              <span v-show="v$.sendCode.maxLength.$invalid">Code must have no more than {{v$.sendCode.maxLength.$params.max}} letters.</span>
              <span v-show="v$.sendCode.numeric.$invalid">Code must be numeric</span>
            </div>
          </div>
          <div class="d-flex flex-column mt-24">
            <span class="typography-6 color-2 mb-16" v-if="codeSendTimeout">{{codeSendTimeout}}</span>
            <div class="d-flex justify-between">
              <span class="typography-6 color-2" v-if="successfulSendCode">{{successfulSendCode}}</span>
              <span class="typography-6 color-2" v-else>Did not get the code?</span>
              <a href="#" class="results-btn fw-600" @click.prevent="sendCodeAgain" id="reset-password-send-again">
                Send again
              </a>
            </div>
          </div>
          <div class="container px-0 mt-32">
            <div class="row mx--8 confirm-buttons">
              <div class="col-12 col-md-6 px-8 mb-16">
                <button id="back-to-phone" class="opacity-btn mr-16 w-sm-100" @click="backToPhone" v-if="phone">
                  <Icon name="arrow-left-blue" class="mr-8" />
                  Wrong number?
                </button>
                <button id="back-to-email" class="opacity-btn mr-16 w-sm-100" @click="backToEmail" v-else>
                  <Icon name="arrow-left-blue" class="mr-8" />
                  Wrong email?
                </button>
              </div>
              <div class="col-12 col-md-6 px-8 mb-16">
                <button
                  id="reset-password-next"
                  class="primary-btn typography-base w-sm-100"
                  :disabled="!sendCode"
                  @click="goToPassword"
                >
                  Next
                </button>
              </div>
            </div>
          </div>
        </div>
      </Feature>
    </template>
</template>

<script>
import Feature from '@/components/app/Feature';
import Icon from '../app/Icon.vue';
import InputPhone from '../app/InputPhone.vue';
import { minLength, maxLength, numeric, email } from '@vuelidate/validators';
import axios from 'axios';
import { useVuelidate } from '@vuelidate/core';
import { eventListener } from '../../utils/helpers';
import { mapGetters } from 'vuex';

export default {
  name: 'ResetPassword',
  components: {
    Feature,
    Icon,
    InputPhone
  },
  emits: ['backToLogin'],
  data () {
    return {
      resetForm: true,
      emailForm: false,
      phoneForm: false,
      codeForm: false,
      email: '',
      phone: '',
      sendCode: '',
      codeSendTimeout: '',
      itiPhone: null,
      v$: useVuelidate(),
      serverError: '',
      errors: {
        phone: false,
        email: false,
        sendCode: false
      }
    };
  },
  created () {
    const token = this.$route.query.token;
    if (token) {
      this.$router.push({ query: null });
      localStorage.setItem('tokenGamayun', token);
      this.$store.commit('setSignUpToken', token);

      this.resetForm = false;
    }
  },
  computed: {
    ...mapGetters(['successfulSendCode'])
  },
  validations () {
    return {
      sendCode: {
        minLength: minLength(6),
        maxLength: maxLength(6),
        numeric
      },
      email: {
        email,
        maxLength: maxLength(320)
      }
    };
  },
  methods: {
    backToLogin () {
      this.sendCode = '';
      this.phone = '';
      this.email = '';
      this.$emit('backToLogin');
    },
    async goToCode (type) {
      try {
        this.serverError = '';
        this.codeSendTimeout = '';
        if (type === 'phone') {
          eventListener.emit('checkPhoneError');
          if (this.errors.phone) return;
          await axios.post('/api/v2/password/reset', { phone: this.itiPhone.getNumber() });
        } else {
          await axios.post('/api/v2/password/reset', { email: this.email });
        }
        this.phoneForm = false;
        this.emailForm = false;
        this.codeForm = true;
      } catch (error) {
        this.serverError = error.response.data.detail;
        this.codeSendTimeout = error.response.data[0];
      }
    },
    goToPassword () {
      this.errors.sendCode = false;
      if (this.v$.sendCode.$error) {
        this.errors.sendCode = this.v$.sendCode.$error;
        return;
      }
      if (this.phone) {
        this.$store.commit('setPhoneToChangePassword', this.phone);
        this.$store.commit('setCodeForChangePassword', this.sendCode);
      }
      this.$router.push('/create_password');
    },
    writePhone (val) {
      this.phone = val;
    },
    itiPhoneState (val) {
      this.itiPhone = val;
    },
    async sendCodeAgain () {
      try {
        this.$store.commit('setSuccessfulSendCode', null);
        if (this.email) {
          await axios.post('/api/v2/password/reset', { email: this.email });
        } else {
          await axios.post('/api/v2/password/reset', { phone: this.itiPhone.getNumber() });
        }
        this.codeSendTimeout = '';
        this.$store.commit('setSuccessfulSendCode', 'The code has been successfully sent');
      } catch (error) {
        this.codeSendTimeout = error.response.data[0];
      }
    },
    openPhoneForm () {
      this.phoneForm = true;
      this.resetForm = false;
    },
    openEmailForm () {
      this.emailForm = true;
      this.resetForm = false;
    },
    backToReset () {
      this.phoneForm = false;
      this.emailForm = false;
      this.phone = '';
      this.email = '';
      this.serverError = '';
      this.resetForm = true;
    },
    checkPhoneError (val) {
      this.errors.phone = val;
    },
    backToPhone () {
      this.codeForm = false;
      this.phoneForm = true;
    },
    backToEmail () {
      this.codeForm = false;
      this.emailForm = true;
    }
  }
};
</script>
