<template>
  <div class="p-relative h-80px px-24 py-20 z-10 bg-link">
    <div class="p-absolute top-0 left-0 w-100 h-100">
      <picture>
        <!-- <source srcset="@/assets/images/sidebar-header-bg-adaptive.jpg" media="(max-width: 1440px)"> -->
        <img
          class="w-100 h-100 object-cover"
          src="@/assets/images/sidebar-header-bg.jpg"
        />
      </picture>
    </div>

    <MainLogo />
  </div>
</template>

<script>
import MainLogo from '@/components/app/MainLogo';

export default {
  name: 'SidebarHeader',
  components: {
    MainLogo
  }
};
</script>
