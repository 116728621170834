<template>
  <slot v-if="ifVisible"></slot>
</template>

<script>
export default {
  name: 'Feature',
  props: ['visible'],

  computed: {
    ifVisible () {
      if (this.visible.charAt(0) === '!') {
        const visible = this.visible.substring(1);
        return (process.env[visible] !== 'true');
      } else {
        const visible = this.visible;
        return (process.env[visible] === 'true');
      }
    }
  }
};
</script>
