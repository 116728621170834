export class ConnectedProfile {
  constructor (entity, socialNet) {
    this.entity = entity;
    this.socialNet = socialNet;

    this.addUrl();
    this.addValue();
    this.addFields();
    this.addPostsStatsToFields();
  }

  setValue (name, value) {
    this.entity[name] = value;
  }

  setValues (arrOfObj) {
    arrOfObj.forEach(obj => this.setValue(obj.name, obj.value));
  }

  addToFields (fieldName, value) {
    this.entity.fields[fieldName] = { name: fieldName, value };
  }

  stringifyFieldValue (fieldName) {
    const fieldObj = this.entity.fields?.[fieldName];
    if (fieldObj) fieldObj.value = fieldObj.value.toString();
  }

  getAllPosts () {
    return { ...(this.entity.connection_posts || {}), ...(this.entity.connection_photos || {}) };
  }

  getPostsStats () {
    let commentedCount = 0;
    let reactedCount = 0;
    let taggedCount = 0;
    let likedCount = 0;
    let repostCount = 0;
    let mentionedCount = 0;
    const posts = this.getAllPosts();
    Object.keys(posts).forEach(key => {
      const post = posts[key];
      commentedCount += (post.comment) ? 1 : 0;
      reactedCount += (post.reaction) ? 1 : 0;
      taggedCount += (post.with) ? 1 : 0;
      likedCount += (post.like) ? 1 : 0;
      repostCount += (post.repost) ? 1 : 0;
      mentionedCount += (post.mention) ? 1 : 0;
    });
    return {
      commented_count: commentedCount,
      reacted_count: reactedCount,
      tagged_count: taggedCount,
      liked_count: likedCount,
      repost_count: repostCount,
      mentioned_count: mentionedCount
    };
  }

  addPostsStatsToFields () {
    if (!this.entity.fields) this.entity.fields = {};
    const fields = this.entity.fields;
    const postsStats = this.getPostsStats();
    fields.commented_count = { name: 'commented_count', value: postsStats.commented_count };
    fields.reacted_count = { name: 'reacted_count', value: postsStats.reacted_count };
    fields.tagged_count = { name: 'tagged_count', value: postsStats.tagged_count };
    fields.liked_count = { name: 'liked_count', value: postsStats.liked_count };
    fields.repost_count = { name: 'repost_count', value: postsStats.repost_count };
    fields.mentioned_count = { name: 'mentioned_count', value: postsStats.mentioned_count };
  }

  addUrl () {
    const alias = this.entity.username || this.entity.alias;
    if (alias && !this.entity.url) {
      this.entity.url = `https://www.${this.type}.com/${alias}`;
    }
  }

  addValue () {
    this.entity.value = (this.entity.id || this.entity.username || this.entity.name).toString();
  }

  addFields () {
    if (!this.entity.fields) this.entity.fields = {};
    const fields = this.entity.fields;
    fields.score = { name: 'score', value: this.entity.score.toString() };
    fields.connection = { name: 'connection', value: 'true' };

    if (this.entity.id) {
      fields.id = { name: 'id', value: this.entity.id.toString() };
    }
    if (this.entity.name) {
      fields.name = { name: 'name', value: this.entity.name };
    }
    if (this.entity.username) {
      fields.username = { name: 'username', value: this.entity.username };
    }
    if (this.entity.alias) {
      fields.username = { name: 'username', value: this.entity.alias };
    }
    if (this.entity.url) {
      fields.url = { name: 'url', value: this.entity.url };
    }
    if (this.entity.mutual_friends) {
      fields.mutual_friends = { name: 'mutual_friends', value: this.entity.mutual_friends.toString() };
    }
    if (this.entity.connection_photos) {
      fields.connection_photos = { name: 'connection_photos', value: JSON.stringify(this.entity.connection_photos) };
    }
    if (this.entity.connection_posts) {
      fields.connection_posts = { name: 'connection_posts', value: JSON.stringify(this.entity.connection_posts) };
    }
    if (this.entity.relations) {
      fields.relations = { name: 'relations', value: JSON.stringify(this.entity.relations) };
    }

    const image = this.entity.image || this.entity.avatar || this.entity.photo;
    if (image) {
      fields['profile-image'] = { name: 'profile-image', value: image };
    }

    if (this.entity.post_exists) {
      fields.post_exists = { name: 'post_exists', value: this.entity.post_exists.toString() };
    }
  }

  addRelation (relatives) {
    for (const relative of relatives || []) {
      if (this.entity.id && this.entity.id === relative.id) {
        this.entity.fields.relation = { name: 'relation', value: relative.relation };
        break;
      }
    }
  }

  addConnectionTypes (uniqPostIDs) {
    if (!this.entity.fields) this.entity.fields = {};
    const fields = this.entity.fields;
    fields.connection_types = { name: 'connection_types', value: [] };
    (this.entity.connection_types || []).forEach(connType => {
      connType = connType.replace(/^with$/g, 'tagged').replace('_', ' ');
      fields.connection_types.value.push(connType.charAt(0).toUpperCase() + connType.slice(1));
    });

    const usedTargets = {};
    (this.entity.relations || []).forEach(relation => {
      const relationType = relation.type.charAt(0).toUpperCase() + relation.type.slice(1);
      if (!fields.connection_types.value.includes(relationType)) {
        fields.connection_types.value.push(relationType);
      }

      const relationTarget = relation.target;
      if (relationTarget && !uniqPostIDs.includes(relationTarget)) {
        uniqPostIDs.splice(0, 0, relationTarget);
      }

      if (!usedTargets[relationType]) {
        usedTargets[relationType] = [];
      }
      if (usedTargets[relationType].includes(relationTarget)) {
        return;
      }
      if (relationType === 'Like') {
        fields.liked_count.value++;
      }
      if (relationType === 'Reaction') {
        fields.reacted_count.value++;
      }
      if (relationType === 'Comment') {
        fields.commented_count.value++;
      }
      if (relationType === 'With') {
        fields.tagged_count.value++;
      }
      if (relationType === 'Repost') {
        fields.repost_count.value++;
      }
      if (relationType === 'Mention') {
        fields.mentioned_count.value++;
      }
      usedTargets[relationType].push(relationTarget);
    });

    fields.liked_count.value = fields.liked_count.value.toString();
    fields.repost_count.value = fields.repost_count.value.toString();
    fields.mentioned_count.value = fields.mentioned_count.value.toString();
    fields.reacted_count.value = fields.reacted_count.value.toString();
    fields.commented_count.value = fields.commented_count.value.toString();
    fields.connection_types.value = fields.connection_types.value.toString();
  }
}

export class InstagramConnectionProfile extends ConnectedProfile {
  addValue () {
    this.entity.value = (this.entity.username || this.entity.id || this.entity.name).toString();
  }
}

export class TwitterConnectionProfile extends ConnectedProfile {}

export class LinkedInConnectionProfile extends ConnectedProfile {
  addUrl () {
    const alias = this.entity.username || this.entity.alias;
    if (alias && !this.entity.url) {
      this.entity.url = `https://www.${this.type}.com/in/${alias}`;
    }
  }

  addValue () {
    this.entity.value = (this.entity.url || this.entity.id || this.entity.username || this.entity.name).toString();
  }
}

export class FacebookConnectionProfile extends ConnectedProfile {}
