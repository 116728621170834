<template>
  <div class="p-relative">
    <div
      v-if="overLimit"
      class="tooltip-ui"
      :class="{
        'is-active' : hovered,
        'position-bottom': positionY === 'bottom'
      }"
    >
      <Icon
        v-if="positionY === 'bottom'"
        class="rotate-180"
        name="tooltip-arrow-black"
      />
      <div
        class="tooltip-text"
        :style="{ width: tooltipWidth }"
      >
        <div
          class="overflow-hidden"
          :class="{ 'wbreak-break-word': noSpaces }"
        >
          {{ text }}
        </div>
      </div>
      <Icon
        v-if="positionY === 'top'"
        name="tooltip-arrow-black"
      />
    </div>
    <div
      @mouseenter="hovered = true"
      @mouseleave="hovered = false"
      class="p-relative z-2"
      :class="className"
    >
      {{ textLimited }}
    </div>
  </div>
</template>

<script>
import Icon from '@/components/app/Icon';

export default {
  components: {
    Icon
  },
  props: {
    text: { default: '' },
    limit: { default: 26 },
    className: { default: '' },
    positionY: { default: 'top' },
    tooltipWidth: { default: '250px' }
  },
  data () {
    return {
      hovered: false
    };
  },
  computed: {
    overLimit () {
      return this.text.length > this.limit;
    },
    textLimited () {
      if (this.overLimit) return this.text.slice(0, this.limit) + ' ...';
      else return this.text;
    },
    noSpaces () {
      return this.text.indexOf(' ') === -1;
    }
  },
  methods: {
    hoverNode (type) {
      if (!this.hover) return;
      if (!this.$refs.text) return;
      if (type) {
        this.$refs.text.classList.add('hovered');
      } else {
        this.$refs.text.classList.remove('hovered');
      }
    }
  }
};
</script>
