export default {
  state: {
    loadedProducts: []
  },
  mutations: {
    setProducts (state, value) {
      state.loadedProducts = value;
    }
  },
  getters: {
    loadedProducts (state) {
      return state.loadedProducts;
    }
  },
  actions: {
    async loadProducts ({ commit, dispatch }) {
      const payload = {
        method: 'GET',
        url: '/api/v2/products'
      };
      const products = await dispatch('ajaxWithTokenRefresh', payload);
      commit('setProducts', products.data);
    },
    async activateCoupon ({ commit, dispatch }, code) {
      const payload = {
        method: 'POST',
        url: '/api/v3/account/coupons/activate',
        body: {
          code: code
        }
      };
      await dispatch('ajaxWithTokenRefresh', payload);
    }
  }
};
