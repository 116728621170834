<template>
  <div
    v-if="loading"
    class="loader-block"
  >
    <img src="@/assets/icons/searching.gif" alt="" class="h-78">
  </div>
  <div
    v-if="!loading"
    @click="setDeleteAccountModal(false)"
    class="modal-background"
  ></div>
  <div
    v-if="!loading"
    class="modal new py-48"
  >
    <div>
      <button
        @click="setDeleteAccountModal(false)"
        class="cancel-btn"
      >
        <Icon name="cancel-btn" />
      </button>
      <div>
        <h2 class="text-h2 lh-32 mb-12">Are you sure you want to delete your account?</h2>
        <p class="text-base fs-18">Cases and SLPs were deleted within 30 days</p>
        <div class="d-grid gap-8 my-24">
          <label
            v-for="(reason, index) in reasons"
            :key="index"
            class="input-radio d-flex align-center jc-space-between"
          >
            <div class="text-base color-text-darker">{{ reason }}</div>
            <div>
              <input
                type="radio"
                class="d-none"
                v-model="reasonModel"
                :value="reason"
              >
              <span></span>
            </div>
          </label>
          <div v-if="reasonModel === 'Other reason'">
            <input class="input" v-model="customReason">
          </div>
        </div>
      </div>
      <div class="d-grid gtc-2 gap-12">
        <button
          @click="setDeleteAccountModal(false)"
          class="bttn-transparent"
        >No</button>
        <button
          @click="deleteAccount"
          class="bttn-primary"
          :disabled="reasonModel === 'Other reason' && !customReason"
        >Yes</button>
      </div>
    </div>
  </div>
</template>

<script>
import Icon from '@/components/app/Icon';
import { mapActions, mapMutations } from 'vuex';

export default {
  components: {
    Icon
  },
  data () {
    return {
      loading: false,
      reasonModel: 'The price is too high',
      reasons: [
        'The price is too high',
        'I no longer need this product',
        'I am not satisfied with the product',
        'I switched to another product',
        'It isn\'t a priority right now',
        'I registered by mistake',
        'Other reason'
      ],
      customReason: ''
    };
  },
  methods: {
    ...mapActions(['ajaxWithTokenRefresh', 'logout']),
    ...mapMutations(['setDeleteAccountModal']),
    async deleteAccount () {
      this.loading = true;
      const payload = {
        method: 'PUT',
        url: '/api/v3/account/delete',
        body: {
          reason: this.reasonModel === 'Other reason' ? this.customReason : this.reasonModel
        }
      };
      await this.ajaxWithTokenRefresh(payload);
      this.loading = false;
      this.setDeleteAccountModal(false);
      this.logout();
    }
  }
};
</script>
