<template>
  <component :is="layout">
    <router-view/>
  </component>
  <div class="loader-block" v-if="$store.state.loadingDirectUrl">
    <img src="@/assets/icons/searching.gif" alt="" class="h-78">
  </div>
</template>

<script>
import smartlookClient from 'smartlook-client';
import { eventListener } from './utils/helpers';

import { library } from '@fortawesome/fontawesome-svg-core';
import { faCheck, faPlus, faChevronDown, faTrash } from '@fortawesome/free-solid-svg-icons';
import { faFacebookF, faLinkedinIn, faInstagram, faTwitter } from '@fortawesome/free-brands-svg-icons';

import SignLayout from './layouts/SignLayout';
import MainLayout from './layouts/MainLayout';
import MainNewLayout from './layouts/MainNewLayout';
import FullscreenLayout from './layouts/FullscreenLayout';
import DiffmatchLayout from './layouts/DiffMatchLayout';

library.add(faCheck, faPlus, faChevronDown, faFacebookF, faLinkedinIn, faInstagram, faTwitter, faTrash);

export default {
  computed: {
    layout () {
      return this.$route.meta.layout + '-layout';
    }
  },
  watch: {
    '$route' (to, from) {
      const currentCaseName = this.$store.getters.currentCaseName;
      const areTagsOpened = this.$store.state.tags.openSavedInTagsPage;
      document.title = to.meta.title;
      document.body.classList.remove('imaged');
      if (to.name === 'Report') {
        document.title = currentCaseName + ((!areTagsOpened) ? ' — Report' : ' — Tags');
      }
      if ((to.name === 'Case') || (to.name === 'CasePage')) {
        document.title = currentCaseName + ((!areTagsOpened) ? ' — Profile search results' : ' — Tags');
      }
      if (to.name === 'Main') {
        document.title = to.meta.title;
      }
      if (to.name === 'Plan') {
        document.title = 'Plan';
      }
      if (to.name === 'Paywall') {
        document.body.classList.add('imaged');
      }
    }
  },
  async created () {
    const token = localStorage.getItem('tokenGamayun');

    if (token) {
      this.$store.commit('setToken');
      this.$store.commit('setAuthHeader');
      this.$store.dispatch('loadProducts');
    }
  },
  mounted () {
    smartlookClient.init(process.env.VUE_APP_SMARTLOOK_PROJECT_KEY);
    eventListener.on('smartLookIdentify', this.smartLookIdentify);
  },
  methods: {
    smartLookIdentify () {
      const account = this.$store.getters.accountState;
      const consentText = this.$store.state.smartLookConsentText;
      smartlookClient.consentForms(consentText);
      smartlookClient.consentIP(consentText);
      smartlookClient.consentAPI(consentText);
      smartlookClient.identify(account.id, {
        name: account.name,
        email: account.email,
        package: account.subscription?.name || '',
        days_in_product: Math.floor((Date.now() - account.created * 1000) / 86400000),
        role: account.role || '',
        renewals: account.renewals
      });
    }
  },
  components: {
    SignLayout, MainLayout, MainNewLayout, FullscreenLayout, DiffmatchLayout
  }
};
</script>

<style>
.imaged {
  background: url('https://thumb.tildacdn.com/tild6535-6664-4363-a563-393961656233/-/format/webp/Group_4_1.png') !important;
}
</style>
