<template>
  <div class="modal-background"></div>
  <div class="modal-wrap">
    <div class="modal" v-if="!informModal">
      <div class="d-flex justify-between align-center mb-24">
        <p class="typography-5 fw-600 let-space-12 color-1">{{ title }}</p>
        <button class="cancel-btn" @click="closeModal"><Icon name="cancel-btn" /></button>
      </div>
      <div>
        <Multiselect v-model="value" :options="options" class="custom-multiselect mb-12"/>
        <textarea name="" id="" cols="30" rows="5" :placeholder="placeholder" class="text-input textarea mb-24" v-model="text"></textarea>
      </div>
      <div class="d-flex">
        <button id="modal-send" class="primary-btn mr-12" :disabled="sending || !text || typeof(value) !== 'number'" @click="sendForm">Send</button>
      </div>
    </div>
    <div class="modal text-center" v-else>
      <p class="typography-5 fw-600 let-space-12 color-1 mb-30" v-if="!existRequest">Your request has been sent</p>
      <p class="typography-5 fw-600 let-space-12 color-1 mb-30" v-else>Your request has already been sent earlier</p>
      <div class="d-flex justify-center">
        <button id="modal-ok" class="primary-btn" @click="closeModal">Ok</button>
      </div>
    </div>
  </div>
</template>

<script>
import Icon from './Icon';
import Multiselect from '@vueform/multiselect';

export default {
  name: 'SendModal',
  components: {
    Icon,
    Multiselect
  },
  emits: ['closeModal'],
  props: ['data'],
  created () {
    if (this.data) {
      this.text = this.data.text;
      this.options = this.data.options;
      this.title = this.data.title;
      this.placeholder = this.data.placeholder;
    }
  },
  data () {
    return {
      value: 0,
      options: [
        'Payment issues',
        'Technical issues',
        'Enterprise',
        'Other'
      ],
      title: 'Describe your problem',
      placeholder: 'What issues are you experiencing? Let us know and we will get back to you as soon as possible.',
      text: '',
      sending: false,
      informModal: false,
      existRequest: false
    };
  },
  methods: {
    closeModal () {
      this.$emit('closeModal');
    },
    async sendForm () {
      try {
        this.sending = true;
        const text = this.text;
        let type;
        if (['Payment issues', 'Payment'].includes(this.options[this.value])) {
          type = 'issues.payment';
        } else if (this.options[this.value] === 'Technical issues') {
          type = 'issues.technical';
        } else if (this.options[this.value] === 'Enterprise') {
          type = 'enterprise';
        } else {
          type = 'other';
        }
        const payload = {
          method: 'POST',
          url: '/api/v2/feedbacks',
          body: {
            type: type,
            description: text
          }
        };
        await this.$store.dispatch('ajaxWithTokenRefresh', payload);
        this.sending = false;
        this.informModal = true;
      } catch (error) {
        console.error(error);
        this.sending = false;
        this.informModal = true;
        this.existRequest = true;
      }
    }
  }
};
</script>

<style src="@vueform/multiselect/themes/default.css"></style>

<style lang="scss" scoped>
  .modal {
    padding: 32px 48px;
  }

  .cancel-btn {
    position: static;
  }
</style>
