<template>
  <div class="modal-background"></div>
    <div class="modal-wrap">
      <div class="modal py-48">
        <div v-if="deleteProcess" class="loader-block">
          <img src="@/assets/icons/searching.gif" alt="" class="h-78">
        </div>
        <div v-else>
          <button class="cancel-btn" @click="closeModal"><Icon name="cancel-btn" /></button>
          <h2 class="fs-24 fw-600 mb-12 color-text-darker let-space-06">Delete {{ this.currentCaseName }}?</h2>
          <p class="fs-18 fw-400 lh-24 mb-24 color-text">{{ modalText }}</p>
          <div class="d-flex jc-space-between">
            <button class="opacity-btn wide-btn fs-13" @click="closeModal">{{ closeButton }}</button>
            <button class="primary-btn wide-btn fs-13" @click="confirmDelete">{{ confirmButton }}</button>
          </div>
        </div>
      </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Icon from '@/components/app/Icon';

export default {
  name: 'DeleteModal',
  components: {
    Icon
  },
  props: {
    modalText: {
      default: 'Our service no longer allows you to have cases without searchings'
    },
    closeButton: {
      default: 'Close'
    },
    confirmButton: {
      default: 'Yes'
    }
  },
  emits: ['closeModal'],
  data () {
    return {
      deleteProcess: false
    };
  },
  computed: {
    ...mapGetters(['currentCase', 'currentCaseName'])
  },
  methods: {
    closeModal () {
      this.$emit('closeModal');
    },
    async confirmDelete () {
      try {
        this.$emit('closeModal');
        this.deleteProcess = true;
        const payload = {
          method: 'POST',
          url: '/api/user/invdel',
          body: {
            fid: this.currentCase.oid
          }
        };
        await this.$store.dispatch('ajaxWithTokenRefresh', payload);
        this.$store.commit('deleteCase', this.currentCase);
        this.$store.commit('updateCountOfCases');
        this.deleteProcess = false;
        this.$router.push('/');
      } catch (error) {
        console.error(error);
        this.deleteProcess = false;
        throw error;
      }
    }
  }
};
</script>
