<template>
  <div
    class="pt-8 px-16 pb-6"
    :class="{
      'bg-form-darker': !parity,
      'bg-form': parity
    }"
  >
    <div
      v-if="showTitle"
      class="text-caption color-text-darker"
      v-html="title"
    ></div>
    <div
      v-if="type !== 'object'"
      v-html="text"
      class="text-base color-text"
    ></div>
    <div v-else>
      <div
        v-for="(point, index) in text"
        :key="index"
        class="text-base color-text"
      >
        {{ point }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['index', 'content', 'showTitle'],
  computed: {
    parity () {
      return (this.index % 2) === 0;
    },
    title () {
      if (this.showTitle) return Object.keys(this.content)[0];
      else return '';
    },
    type () {
      return typeof this.content[this.title];
    },
    text () {
      if (this.showTitle) return this.content[this.title];
      else return this.content;
    }
  }
};
</script>
