import router from '../../router';

export default {
  state: {
    investigations: [],
    limit: 20,
    searchHistory: {},
    allInvestigationsCount: 0,
    relationsSearches: []
  },
  mutations: {
    editCase (state, obj) {
      const investigation = state.investigations.find(inv => {
        return inv.oid === obj.id;
      });
      investigation.tag = obj.name;
    },
    deleteCase (state, caseId) {
      state.investigations.forEach((inv, index) => {
        if (inv.oid === caseId) {
          state.investigations.splice(index, 1);
        }
      });
    },
    addNewCase (state, investigation) {
      state.investigations.unshift(investigation);
    },
    addNewSearchHistory (state, payload) {
      state.searchHistory[payload.id] = [];
      state.searchHistory[payload.id].push(payload.data);
    },
    setAllInvestigationsCount (state, value) {
      state.allInvestigationsCount = value;
    },
    setRelationsSearchesCount (state, value) {
      state.relationsSearches = value;
    },
    clearCases (state) {
      state.investigations = [];
    },
    updateCountOfCases (state) {
      state.allInvestigationsCount--;
    }
  },
  getters: {
    getInvestigations (state) {
      return state.investigations;
    },
    getSearchHistory (state) {
      return state.searchHistory;
    },
    getAllInvestigationsCount (state) {
      return state.allInvestigationsCount;
    }
  },
  actions: {
    async getAllInvestigations (ctx) {
      return await ctx.dispatch('ajaxWithTokenRefresh', {
        method: 'GET',
        url: '/api/user/investigations'
      });
    },
    async loadInvestigations ({ state, dispatch }) {
      try {
        const payload = {
          method: 'POST',
          url: '/api/user/invlst',
          body: {
            pos: state.investigations.length,
            limit: state.limit
          }
        };
        const investigations = await dispatch('ajaxWithTokenRefresh', payload);
        const recListPayload = {
          method: 'POST',
          url: '/api/user/reclst'
        };
        if (investigations.data && investigations.data.length) {
          const arrOfPromises = [];
          investigations.data.forEach(inv => {
            recListPayload.body = {
              fid: inv.oid
            };
            const reclst = dispatch('ajaxWithTokenRefresh', recListPayload);
            arrOfPromises.push(reclst);
            state.investigations.push(inv);
          });
          Promise.all(arrOfPromises).then(history => {
            history.forEach(item => {
              if (item.data.length) {
                state.searchHistory[item.data[0].fid] = item.data;
              }
            });
          });
        }
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    async checkCase (ctx, id) {
      try {
        ctx.commit('clearResults');
        ctx.commit('openResults', false);
        ctx.commit('openConnections', false);
        ctx.commit('openMultiprofile', false);
        ctx.commit('clearOpenSearch');
        ctx.commit('clearCurrentSearchResults');
        const payload = {
          method: 'GET',
          url: `/api/user/investigations/${id}`
        };
        return await ctx.dispatch('ajaxWithTokenRefresh', payload);
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    async setEntitiesTree (ctx, results) {
      const entitiesMap = {};
      results.data.forEach(result => {
        result.ents.forEach(ent => {
          ent.children = [];
          entitiesMap[ent.oid] = ent;
        });
      });
      results.data.forEach(result => {
        ctx.commit('addEdges', result.edgs);
        result.edgs.forEach(edg => {
          const src = entitiesMap[edg.src];
          const dst = entitiesMap[edg.dst];
          src.children.push(dst);
        });
      });
      ctx.commit('updateResultsMap', entitiesMap);
    },
    async createNewCase ({ commit, dispatch }) {
      try {
        commit('setSearching', true);
        commit('setLoadUrl', true);
        const payload = {
          method: 'POST',
          url: '/api/user/invins',
          body: {
            tag: 'My Case',
            description: ''
          }
        };
        const investigation = await dispatch('ajaxWithTokenRefresh', payload);
        await router.push(`/case/${investigation.data.oid}`);
        commit('addNewCase', investigation.data);
      } catch (error) {
        console.error(error);
      }
    },
    async writeCaseAndResults (ctx, investigation) {
      try {
        ctx.commit('writeCase', investigation.data);
        ctx.commit('updateCaseName', investigation.data.tag);

        const recListPayload = {
          method: 'POST',
          url: '/api/user/reclst',
          body: {
            fid: investigation.data.oid
          }
        };
        const reclst = await ctx.dispatch('ajaxWithTokenRefresh', recListPayload);
        ctx.commit('writeCurrentSearchHistory', reclst.data);

        if (reclst.data.length) {
          const payload = {
            method: 'POST',
            url: '/api/user/searches/nodes',
            body: {
              search_id: '',
              levels: [1],
              limit: 2000,
              offset: 0
            }
          };

          let indexOfVisibleResults = 0;
          const querySearch = router.currentRoute.value.query.search;
          if (querySearch) {
            indexOfVisibleResults = reclst.data.findIndex(rec => {
              return rec.rid === querySearch;
            });
          }

          payload.body.search_id = reclst.data[indexOfVisibleResults].rid;
          ctx.commit('setSearchID', payload.body.search_id);

          const allResults = [];
          while (true) {
            const response = await ctx.dispatch('ajaxWithTokenRefresh', payload);
            ctx.commit('addToLoadedSearches', payload.body.search_id);
            const results = response.data.filter(ent => ent.level === 1);
            allResults.push(...results);
            ctx.commit('updateResultsMap', results);
            ctx.dispatch('convertEntityFieldsToMap', response.data);
            results.forEach(res => {
              res.checked = false;
              res.visible = true;
              ctx.commit('setCurrentSearchResults', res);
            });
            ctx.commit('openedSearch', reclst.data[indexOfVisibleResults]);
            if (response.data.length < payload.body.limit) {
              break;
            } else {
              payload.body.offset += payload.body.limit;
            }
          }

          ctx.commit('openResults', true);
          ctx.commit('writeNewResults', allResults);
        }
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    async editCase (ctx, caseinfo) {
      try {
        const payload = {
          method: 'POST',
          url: '/api/user/invtag',
          body: caseinfo
        };
        await ctx.dispatch('ajaxWithTokenRefresh', payload);
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    async caseShareAccept (ctx, caseId) {
      try {
        const payload = {
          method: 'POST',
          url: '/api/user/investigations/sharing/get',
          body: {
            sharing_id: caseId
          }
        };
        const result = await ctx.dispatch('ajaxWithTokenRefresh', payload);
        payload.url = '/api/user/investigations/sharing/accept';
        await ctx.dispatch('ajaxWithTokenRefresh', payload);
        ctx.commit('setCaseSharingOwner', result.data.owner_name);
      } catch (error) {
        console.error(error);
        throw error;
      }
    }
  }
};
