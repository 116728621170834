<template>
  <div class="sidebar-line">
    <button class="sidebar__options-item border-0 w-100 test-sidebar-saved-in-tags" @click="openSavedInTagsPage" :disabled="disabledTags">
      <div class="icon-wrap mr-12">
        <Icon name="tag" color="#A0B2BE" />
      </div>
      <span>Tags</span>
      <div class="right-icon right-icon--0 d-flex" v-if="loader">
        <span class="load-circle load-circle--1"></span>
        <span class="load-circle load-circle--2"></span>
        <span class="load-circle load-circle--3"></span>
      </div>
    </button>
    <div v-for="(tag, index) in notEmptyTags" :key="tag.oid || index">
      <div class="sidebar__options-item border-0" v-if="showTagInput === tag.oid">
        <input type="text" ref="tagInput" v-model="tagName" class="input-text" @keyup.enter="editTag(tag.oid)" maxlength="24">
        <button @click="editTag(tag.oid)" class="edit-tag mr-8"><Icon name="ok" /></button>
        <button @click="closeInput" class="close-tag-input"><Icon name="cancel" /></button>
      </div>
      <Tag :tag="tag" :index="index" @showTagInput="addTagInput" @addTaggedEntities="addTaggedEntities" v-else/>
    </div>
    <MultiprofileWidget v-for="(multiprofile, index) in multiprofiles" :key="index" :multiprofile="multiprofile" :index="index" />
    <div
      v-if="!notEmptyTags.length && modalOpened"
      style="margin-top: -14px;"
      class="px-25 mb-20"
    >
      <div class="monitoring-beta bg-white b-left-double bc-link br-3 p-16 mt-14">
        <div class="d-flex jc-space-between mb-8">
          <div class="fs-16 fw-400 color-link lh-24">Assign profiles to a tag</div>
          <button
            class="lh-24"
            @click="modalOpened = false"
          >
            <Icon name="cancel-btn" />
          </button>
        </div>
        <div class="fs-14 fw-400 color-text lh-24">
          Tags are one of the main tools of our service. With their help, you can find connections between profiles and generate a report.
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Icon from './app/Icon';
import { eventListener } from '../utils/helpers';
import { mapGetters } from 'vuex';
import Tag from './Tag';
import MultiprofileWidget from './multiprofile/MultiprofileWidget';

export default {
  name: 'SidebarTags',
  components: {
    Icon, Tag, MultiprofileWidget
  },
  data () {
    return {
      showTagInput: null,
      tagName: '',
      taggedEntities: {},
      modalOpened: true
    };
  },
  async created () {
    eventListener.on('addInfoInTag', (tag) => {
      const elem = this.$refs[tag.oid];
      if (elem && elem.style.maxHeight !== '') {
        elem.style.maxHeight = '100%';
      }
    });
  },
  computed: {
    ...mapGetters(['results', 'currentSearchResults', 'notEmptyTags', 'multiprofiles']),
    loader () {
      return this.$store.getters.getLoader;
    },
    disabledTags () {
      if (!this.notEmptyTags.length) {
        return true;
      }
      return false;
    }
  },
  watch: {
    notEmptyTags () {
      if (!this.notEmptyTags.length && this.$store.state.tags.openSavedInTagsPage) {
        this.$store.commit('clearAllSavedInTagsEntities');
        this.$store.commit('openSavedInTagsPage', false);
        this.$store.commit('openResults', true);
        this.modalOpened = true;
      }
    }
  },
  methods: {
    addTaggedEntities (entitiesMap) {
      Object.keys(entitiesMap).forEach(oid => {
        if (!this.taggedEntities[oid]) {
          this.taggedEntities[oid] = entitiesMap[oid];
        }
      });
    },
    openSavedInTagsPage () {
      if (this.$route.name === 'Report') {
        this.$router.push(`/case/${this.$route.params.id}`);
      }
      this.$store.commit('setLoadUrl', true);
      this.$store.commit('setSearching', true);
      this.$store.commit('openConnections', false);
      this.$store.commit('openMultiprofile', false);
      this.$store.commit('setOpenFastReport', false);
      this.$store.commit('clearAllSavedInTagsEntities');
      Object.keys(this.taggedEntities).forEach(oid => {
        const ent = this.taggedEntities[oid];
        const mutation = (ent?.fields?.relation?.value) ? 'addConnectionToSavedInTagsPage' : 'addProfileToSavedInTagsPage';
        this.$store.commit(mutation, ent);
      });

      this.$store.commit('openResults', false);
      this.$store.commit('showAllSavedInTags', true);
      this.$store.commit('openSavedInTagsPage', true);
      this.$store.commit('setSearching', false);
      document.getElementsByClassName('content')[0].scrollIntoView();
    },
    addTagInput (tagOID) {
      this.showTagInput = tagOID;
    },
    async editTag (oid) {
      if (this.tagName.length < 3) return;
      try {
        const tag = {
          id: oid,
          name: this.tagName
        };
        await this.$store.dispatch('editTag', tag);
        this.showTagInput = null;
        this.tagName = '';
      } catch (error) {
        console.error(error);
      }
    },
    closeInput () {
      this.showTagInput = null;
      this.tagName = '';
    }
  }
};
</script>

<style lang="scss" scoped>
.typography-13{
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.08em;
}
.sidebar__tags-modal{
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-left: 24px;
  padding-right: 21px;
  padding-top: 16px;
  padding-bottom: 16px;
  min-height: 46px;
  height: 100%;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #BFD2DF;
  border-bottom: 2px solid #EFF4F7;
  background: #F8FCFF;
}
.modal-text{
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.04em;
  color: #8D8D8E;
  font-weight: 400;
}
</style>
