<template>
  <div class="cookie-form d-flex align-center justify-between" v-if="$store.state.openCookieModal">
    <div>
      <h3>We use cookies to collect information about the service's usability</h3>
      <p>It includes data files that are placed on your device or computer and include an anonymous unique identifier.</p>
    </div>
    <div class="d-flex">
      <button class="cookie-accept mr-15" @click="cookieAccept"><Icon name="ok" /></button>
    </div>
  </div>
  <div id="layout">
    <div class="main-layout">
      <div class="main-layout-wrap">
        <Feature visible="!VUE_APP_NSA_1684">
          <HeaderMainOld />
        </Feature>
        <Feature visible="VUE_APP_NSA_1684">
          <div class="fixed-header p-fixed z-1003 top-0">
            <HeaderMain />
          </div>
        </Feature>
      </div>
      <aside class="sidebar">
        <SidebarHeaderOld />
        <SidebarOld />
      </aside>
      <Searching v-show="getSearching" :loadUrl="loadUrl" />
    </div>
    <router-view v-show="!getSearching" />
    <SendModal v-if="openIssuesModal" @closeModal="closeModal" />
    <Feature visible="VUE_APP_NSA_1684">
      <ProfileModal />
    </Feature>
  </div>
  <div id="no-mobile">
    <img src="@/assets/images/no-mobile.png" alt="" class="mb-40">
    <span class="typography-4 color-theme">
      Unfortunately, the service does not support the mobile version and does not work on screens with a width of less than 1150 pixels
    </span>
  </div>
</template>

<script>
import Feature from '@/components/app/Feature';
import Icon from '@/components/app/Icon';
import HeaderMainOld from '@/components/app/HeaderMainOld';
import HeaderMain from '@/components/app/HeaderMain';
import SidebarHeaderOld from '@/components/SidebarHeaderOld';
import SidebarOld from '@/components/app/SidebarOld';
import Searching from '@/components/app/Searching';
import SendModal from '@/components/app/SendModal';
import ProfileModal from '@/components/profile/Modal';
import { mapGetters } from 'vuex';

export default {
  components: {
    Feature,
    Icon,
    HeaderMainOld,
    HeaderMain,
    SidebarHeaderOld,
    SidebarOld,
    Searching,
    SendModal,
    ProfileModal
  },
  computed: {
    ...mapGetters(['getSearching', 'loadUrl', 'openIssuesModal'])
  },
  mounted () {
    if (this.$store.state.openCookieModal) {
      setTimeout(() => {
        if (document.querySelector('.intercom-lightweight-app-launcher')) {
          document.querySelector('.intercom-lightweight-app-launcher').style.bottom = 90 + 'px';
        }
      }, 200);
    }
  },
  methods: {
    cookieAccept () {
      this.$store.state.openCookieModal = false;
      localStorage.setItem('acceptedCookie', true);
      document.querySelector('.intercom-lightweight-app-launcher').style.bottom = 20 + 'px';
    },
    closeModal () {
      this.$store.commit('openIssuesModal', false);
    }
  }
};
</script>

<style lang="scss">
  a {
    cursor: pointer;
  }
  .fixed-header {
    width: calc(100% - 400px);
  }
  .cookie-form {
    padding: 16px 24px;
    position: fixed;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 999999 !important;
    background-color:  #F8FCFF;
    box-shadow: 0px -70px 100px 8px rgba(0, 0, 0, 0.05);
    h3 {
      font-family: 'Segoe UI', 'Segoe UI Mac', Avenir, Helvetica, Arial, sans-serif;;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.04em;
      color: #29292B;
    }
    p {
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.04em;
      color: #A0B2BE;
    }
  }
</style>
