<template>
  <div class="loading-block">
    <div class="mb-12">
      <img src="@/assets/icons/searching.gif" alt="" class="h-78">
    </div>
    <template v-if="!loadUrl">
      <span class="typography-3 mb-6">{{ $store.getters.searchText }}</span>
      <button id="cancel-searching" class="typography-theme color-link" @click="cancelSearching">Сancel</button>
    </template>
    <span class="typography-3 mb-6" v-else>Loading...</span>
  </div>
</template>

<script>

export default {
  name: 'Searching',
  props: ['loadUrl'],
  methods: {
    cancelSearching () {
      this.$store.commit('setSearching', false);
      this.$store.commit('clearSearchTimer');
      if (this.$store.state.results.searching) {
        this.$store.commit('searchResults', false);
        this.$store.state.results.resultsCancelToken.cancel();
      } else if (this.$store.state.connections.searching) {
        this.$store.commit('searchConnections', false);
        this.$store.state.connections.connectionsCancelToken.cancel();
        this.$store.getters.currentSearchResults.forEach(ent => { ent.visible = ent.level === 1; });
        if (this.$store.state.connections.between) {
          this.$store.commit('openConnections', true);
          this.$store.commit('openResults', false);
          this.$store.commit('openMultiprofile', false);
        } else {
          this.$store.commit('openConnections', false);
          this.$store.commit('openResults', true);
        }
      } else if (this.$store.state.userDetails.searching) {
        this.$store.state.userDetails.detailsCancelToken.cancel();
        this.$store.commit('setSearchingUserDetails', false);
      }
      this.$store.dispatch('checkAccount');
    }
  }
};
</script>
