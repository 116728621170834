<template>
  <div class="diffmatch-layout" id="layout">
    <div class="diffmatch-container py-48">
      <router-view />
    </div>
  </div>
  <div id="no-mobile">
    <img src="@/assets/images/no-mobile.png" alt="" class="mb-40">
    <span class="typography-4 color-theme">
      Unfortunately, the service does not support the mobile version and does not work on screens with a width of less than 1150 pixels
    </span>
  </div>
</template>

<script>
export default {
  name: 'DiffmatchLayout'
};
</script>
