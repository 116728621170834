<template>
  <div
    class="right-sidebar d-flex fd-column"
    :class="{
      'child': child,
      'child-hide': childHide && child
    }"
  >
    <button
      v-if="childMode"
      class="p-absolute top-0 left-0 right-0 bottom-0 w-100 d-flex align-center jc-space-between px-24 py-16"
      @click.prevent="childHide = false"
    >
      <div class="d-flex align-center">
        <Avatar class="br-circle overflow-hidden w-36px h-36px mr-12" :src="avatar" />
        <div class="text-h5">{{ name }}</div>
      </div>
      <div class="text-link">Show second profile</div>
    </button>
    <div class="p-sticky top-0 z-2">
      <div class="bg-form pt-24 px-24 pb-16">
        <div class="d-flex jc-space-between">
          <div>
            <Avatar class="br-circle overflow-hidden w-64px h-64px mb-8" :src="avatar" />
            <div class="text-h3">{{ name }}</div>
          </div>
          <button
            @click.prevent="closeSidebar"
            class="w-16px h-16px"
          >
            <Icon class="w-100 h-100" name="cancel-btn" />
          </button>
        </div>
        <div class="d-flex align-center jc-space-between mt-8">
          <a
            @click.prevent="openFullView"
            class="d-flex align-center text-caption color-link color-link-lighter_hover transition"
          >
            <Icon name="fullview-icon-small" />
            <span class="ml-6">Full view</span>
          </a>
          <!-- <a
            target="_blank"
            class="d-flex align-center text-caption color-link color-link-lighter_hover transition"
          >
            <Icon name="connections-icon-small" />
            <span class="ml-6">Connections</span>
          </a> -->
          <a
            :href="profileUrl"
            target="_blank"
            class="d-flex align-center text-caption color-link color-link-lighter_hover transition"
          >
            <Icon class="w-12px h-12px mb-3" :name="socialNetworkIcon" />
            <span class="ml-6">Profile page</span>
          </a>
          <TagTooltip
            :profiles="profile"
            size="small"
            position-x="right"
            position-y="bottom"
          />
        </div>
      </div>
      <div class="d-flex align-center bg-white b-bottom-double bc-body px-24">
        <button
          v-for="(tab, index) in tabsProfileInfo"
          :key="index"
          :disabled="tab.disabled"
          class="text-base transition b-bottom-double mb--2 mr-20"
          :class="{
            'color-text bc-transparent pt-12 pb-8': !tab.active && !tab.disabled,
            'color-link fw-600 bc-link pt-8 pb-12': tab.active && !tab.disabled,
            'color-text-lighter cursor-auto bc-transparent pt-12 pb-8': tab.disabled
          }"
          @click.prevent="tabsProfileInfo.find(t => t.active).active = false; tab.active = true;"
        >
          {{ tab.label }}
        </button>
      </div>
    </div>
    <div
      v-for="(tab, index) in tabsProfileInfo"
      v-show="tab.active"
      :key="index"
      class="h-100"
    >
      <TabsInfo
        v-if="tab.label === 'Profile info' && tab.active"
        :profile="profile"
        :bio="bio"
        :profileDetails="profileDetails"
      />
      <TabsConnections
        v-if="tab.label === 'Social graph' && tab.active"
        :profile="profile"
      />
    </div>
  </div>
</template>

<script>
import Avatar from '@/components/app/Avatar';
import Icon from '@/components/app/Icon';
import TagTooltip from '@/components/UI/TagTooltip';
import TabsInfo from '@/components/right-sidebar/tabs/Info';
import TabsConnections from '@/components/right-sidebar/tabs/Connections';
import { mapActions, mapGetters } from 'vuex';
import profileInfoDetailMixin from '@/mixins/profileInfoDetailMixin';

export default {
  components: {
    Avatar,
    Icon,
    TagTooltip,
    TabsInfo,
    TabsConnections
  },
  props: {
    profile: { default: null },
    child: { default: false }
  },
  mixins: [profileInfoDetailMixin],
  watch: {
    getTabSocialGraph: {
      immediate: true,
      handler () {
        if (this.getTabSocialGraph) {
          this.tabsProfileInfo.find(tab => tab.active).active = false;
          this.tabsProfileInfo.find(tab => tab.label === 'Social graph').active = true;
        }
      }
    }
  },
  data () {
    return {
      childHide: true,
      tabsProfileInfo: [
        { label: 'Profile info', active: true, disabled: false },
        { label: 'Social graph', active: false, disabled: false }
        // { label: 'Content', active: false, disabled: true },
        // { label: 'Updates history', active: false, disabled: true }
      ]
    };
  },
  computed: {
    ...mapGetters(['getTabSocialGraph']),

    childMode () {
      return this.childHide && this.child;
    }
  },
  methods: {
    ...mapActions(['setCloseRightSidebar', 'setProfile']),

    closeSidebar () {
      if (this.child) this.setCloseRightSidebar('child');
      else this.setCloseRightSidebar('parent');
    },
    async openFullView () {
      let caseId = this.$route.params.id;
      if (this.profile.cid) caseId = this.profile.cid;
      this.setProfile(this.profile);
      this.$router.push(`/profile/${caseId}/${this.profile.rid}/${this.profile.oid}`);
    }
  }
};
</script>
