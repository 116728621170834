import axios from 'axios';
import { getLocation, eventListener } from '../../utils/helpers';

export default {
  state: {
    case: null,
    currentSearchHistory: [],
    openSearch: null,
    results: [],
    resultsMap: {},
    currentSearchResults: [],
    resultsCancelToken: axios.CancelToken.source(),
    caseName: '',
    filterCheck: false,
    selectedProfile: null,
    lastVisibleOIDs: [],
    opened: false,
    searchID: null,
    searching: false,
    onOverview: true,
    onMapView: false,
    foundLocations: false,
    loadingSearches: new Set(),
    zeroResultsUrlParameter: 'zeroresults'
  },
  mutations: {
    pushResults (state, results) {
      state.results.push(...results);
    },
    pushToCurrentSearchResults (state, results) {
      state.currentSearchResults.push(...results);
    },
    foundLocations (state, value) {
      state.foundLocations = value;
    },
    toggleViewController (state) {
      state.onOverview = !state.onOverview;
      state.onMapView = !state.onMapView;
    },
    searchResults (state, value) {
      state.searching = value;
    },
    clearResults (state) {
      state.results = [];
      state.resultsMap = {};
    },
    addProfileToResultsMap (state, entity) {
      if (!entity.oid) {
        return;
      }
      if (!state.resultsMap[entity.oid]) {
        state.resultsMap[entity.oid] = entity;
      }
    },
    checkResultsProfile (state, oid) {
      const ent = state.resultsMap[oid];
      if (ent) {
        ent.checked = !ent.checked;
      }
    },
    updateResultsMap (state, entities) {
      if (Array.isArray(entities)) {
        entities.forEach(ent => {
          if (!state.resultsMap[ent.oid]) {
            state.resultsMap[ent.oid] = ent;
          }
        });
      } else {
        Object.keys(entities).forEach(oid => {
          if (!state.resultsMap[oid]) {
            state.resultsMap[oid] = entities[oid];
          }
        });
      }
    },
    openResults (state, value) {
      state.opened = value;
    },
    selectProfile (state, value) {
      state.selectedProfile = value;
    },
    saveLastVisibleOIDs (state, entities) {
      entities.forEach(ent => {
        state.lastVisibleOIDs.push(ent.oid);
      });
    },
    removeLastVisibleOIDs (state) {
      state.lastVisibleOIDs = [];
    },
    updateFilterCheck (state, value) {
      state.filterCheck = value;
    },
    updateCaseName (state, value) {
      state.caseName = value;
    },
    updateResultsCancelToken (state) {
      state.resultsCancelToken = axios.CancelToken.source();
    },
    updateResultsVisibility (state, { buttons, locationExists, socialNetExists }) {
      if (!locationExists && !socialNetExists) {
        state.currentSearchResults.forEach(res => {
          res.visible = true;
        });
      } else {
        state.currentSearchResults.forEach(res => {
          const visible = buttons.socialNet[res.typeid];
          if (!locationExists) {
            res.visible = visible || false;
          } else if (!socialNetExists) {
            const loc = getLocation(res) || 'No info';
            res.visible = buttons.location[loc] || false;
          } else {
            const loc = getLocation(res) || 'No info';
            res.visible = (visible && buttons.location[loc]) || false;
          }
        });
      }
    },
    updateCheckFlags (state, value) {
      state.results.forEach(res => {
        if (res.oid === value) {
          res.checked = !res.checked;
        }
        (res.children || []).forEach(res => {
          if (res.oid === value) {
            res.checked = !res.checked;
          }
        });
      });
      eventListener.emit('clearInfoInPopup');
    },
    uncheckAllResults (state) {
      state.results.forEach(res => {
        res.checked = false;
        (res.children || []).forEach(ent => { ent.checked = false; });
      });
    },
    checkAllVisible (state, checked) {
      state.results.forEach(res => {
        if (res.visible) {
          res.checked = checked;
        }
      });
    },
    showProfilesByIDs (state, OIDs) {
      state.results.forEach(res => {
        res.visible = OIDs.includes(res.oid);
      });
    },
    setSearchID (state, value) {
      state.searchID = value;
    },
    updateResultsEntity (state, value) {
      state.results.forEach(res => {
        if (res.oid === value.oid) {
          res = value;
        }
      });
    },
    writeCase (state, value) {
      state.case = value;
    },
    updateTagsInResults (state, payload) {
      const updateTag = (ent) => {
        if (payload.searchItems.has(ent.oid)) {
          if (ent.tags && ent.tags.length) {
            ent.tags.push(payload.tag);
          } else {
            ent.tags = [];
            ent.tags.push(payload.tag);
          }
        }
      };
      const updateTags = (obj) => {
        if (Array.isArray(obj)) {
          obj.forEach(result => { updateTag(result); });
        } else {
          Object.keys(obj).forEach(oid => { updateTag(obj[oid]); });
        }
      };
      updateTags(state.resultsMap);
      state.results.forEach(res => { updateTags(res.children || []); });
    },
    addResultsFromTags (state, arr) {
      if (state.results.length) {
        state.results.forEach(result => {
          const existResultIndex = arr.findIndex(res => {
            return result.oid === res.oid;
          });
          if (existResultIndex !== -1) {
            arr.splice(existResultIndex, 1);
          }
        });
        arr.forEach(res => {
          state.results.push(res);
        });
      } else {
        arr.forEach(res => {
          state.results.push(res);
        });
      }
    },
    deleteTagsInResults (state, payload) {
      if (payload.attachProfile) {
        let profile = null;
        for (const ent of state.results) {
          if (ent.oid === payload.attachProfile.oid) {
            profile = ent;
          }
          for (const e of ent.children || []) {
            if (e.oid === payload.attachProfile.oid) {
              profile = e;
            }
          }
          if (profile) {
            break;
          }
        }
        if (profile) {
          const index = profile.tags.indexOf(payload.tag.oid);
          if (index !== -1) {
            profile.tags.splice(index, 1);
          }
        }
      } else {
        state.results.forEach(profile => {
          if (profile.tags && profile.tags.length) {
            const index = profile.tags.indexOf(payload.tag.oid);
            if (index !== -1) {
              profile.tags.splice(index, 1);
            }
          }
          (profile.children || []).forEach(profile => {
            if (profile.tags && profile.tags.length) {
              const index = profile.tags.indexOf(payload.tag.oid);
              if (index !== -1) {
                profile.tags.splice(index, 1);
              }
            }
          });
        });
      }
    },
    writeCurrentSearchHistory (state, value) {
      state.currentSearchHistory = value;
    },
    deleteCurrentSearchHistory (state, value) {
      state.currentSearchHistory = state.currentSearchHistory.filter(item => item !== value);
    },
    writeNewResults (state, arr) {
      state.results = arr;
    },
    addMoreResults (state, result) {
      const existResultIndex = state.results.findIndex(res => {
        return res.oid === result.oid;
      });
      if (existResultIndex === -1) {
        state.results.push(result);
      } else {
        state.results.splice(existResultIndex, 1, result);
      }
    },
    openedSearch (state, value) {
      state.openSearch = value;
    },
    hideResults (state) {
      state.results.forEach(res => {
        res.visible = false;
      });
    },
    clearOpenSearch (state) {
      state.openSearch = null;
    },
    setCurrentSearchResults (state, value) {
      state.currentSearchResults.push(value);
    },
    clearCurrentSearchResults (state) {
      state.currentSearchResults = [];
    },
    addToLoadedSearches (state, value) {
      state.loadingSearches.add(value);
    },
    clearLoadingSearches (state) {
      state.loadingSearches = new Set();
    },
    setScoreForPreview (state, value) {
      const res = state.results.find(result => {
        return result.oid === value.resultId;
      });
      if (res) {
        res.scoreToShow = value.score;
      }
    }
  },
  getters: {
    results: state => state.results,
    resultsMap: state => state.resultsMap,
    visibleResults: state => state.currentSearchResults.filter(res => res.visible),
    currentSearchResults: state => state.currentSearchResults,
    currentHistory: state => state.currentSearchHistory,
    openSearch: state => state.openSearch,
    currentCase: state => state.case,
    currentCaseName: state => state.caseName,
    loadingSearches: state => state.loadingSearches,
    searchID: state => state.searchID,
    sortedHistorySearches (state) {
      return state.currentSearchHistory.sort((rel1, rel2) => rel2 - rel1);
    }
  },
  actions: {
    addZeroResultsUrlParameter ({ state }) {
      const url = new URL(window.location.href);
      url.searchParams.set(state.zeroResultsUrlParameter, 'true');
      history.replaceState(history.state, document.title, url);
    },
    removeZeroResultsUrlParameter ({ state }) {
      const url = new URL(window.location.href);
      url.searchParams.delete(state.zeroResultsUrlParameter);
      history.replaceState(history.state, document.title, url);
    },
    updateFieldsInResult ({ state, rootState }, objToUpdate) {
      let result;
      result = state.results.find(res => {
        return res.oid === objToUpdate.oid;
      });
      if (!result) {
        result = rootState.connections.connections.find(conn => {
          return conn.oid === objToUpdate.oid;
        });
      }
      if (!result) {
        result = rootState.connections.mergedConnectionsBetween.find(conn => {
          return conn.oid === objToUpdate.oid;
        });
      }
      result.fields[objToUpdate.name] = {
        name: objToUpdate.name,
        value: JSON.stringify(objToUpdate.value),
        label: objToUpdate.name,
        rule: null
      };
    },
    async getChildren ({ state }, parentID) {
      const children = [];
      Object.keys(state.resultsMap).forEach(oid => {
        const ent = state.resultsMap[oid];
        if (ent.parent_id === parentID) {
          children.push(ent);
        }
      });
      return children;
    },
    async createInvestigation ({ state, dispatch }) {
      try {
        const payload = {
          method: 'POST',
          url: '/api/user/invins',
          body: { tag: state.caseName },
          cancelToken: state.resultsCancelToken.token
        };
        const resp = await dispatch('ajaxWithTokenRefresh', payload);
        if (resp.status === 200) {
          state.case = resp.data;
        }
      } catch (e) {
        console.error(e);
      }
    },
    async createNewSearch ({ state, dispatch, commit }, transforms) {
      if (!state.case) {
        return;
      }
      try {
        const searchGroups = Object.keys(transforms);
        const payload = {
          method: 'POST',
          url: '/api/user/recnew',
          cancelToken: state.resultsCancelToken.token,
          body: {
            fid: state.case.oid,
            tag: '',
            query: {}
          }
        };
        searchGroups.forEach(searchType => {
          const entity = transforms[searchType].entity;
          entity.fields.forEach(field => {
            if (field.label === 'name') {
              payload.body.query.name = field.value;
            } else if (field.label === 'alias') {
              payload.body.query.alias = field.value;
            } else if (field.label === 'photo') {
              payload.body.query.image = field.value;
            } else if (field.label === 'location') {
              payload.body.query.location = field.value;
            } else if (field.label === 'phone') {
              payload.body.query.phone = field.value;
            } else if (field.label === 'email') {
              payload.body.query.email = field.value;
            } else if (field.label === 'education') {
              payload.body.query.education = field.value;
            } else if (field.label === 'company') {
              payload.body.query.company = field.value;
            } else if (field.label === 'profile_url') {
              payload.body.query.profile_url = field.value;
            } else if (field.label === 'profile_id') {
              payload.body.query.profile_id = field.value;
            }
          });
        });
        Object.keys(payload.body.query).forEach((queryName, idx, arr) => {
          const splitter = (idx === arr.length - 1) ? '' : ' ';
          const fieldName = queryName.charAt(0).toUpperCase() + queryName.slice(1);
          payload.body.tag += `${fieldName}: ${payload.body.query[queryName]}${splitter}`;
        });

        const resp = await dispatch('ajaxWithTokenRefresh', payload);
        if (resp.status === 200) {
          // Add new search history to current Case in CaseList
          const objOfCase = this.state.cases.searchHistory[state.case.oid];
          if (objOfCase) {
            objOfCase.unshift(resp.data);
          } else {
            commit('addNewSearchHistory', { id: state.case.oid, data: resp.data });
          }
          // Add new search history in Current Case
          state.currentSearchHistory.unshift(resp.data);
          return resp.data;
        }
      } catch (e) {
        console.error(e);
      }
    },
    async createEntities ({ state, dispatch }, { search, entities }) {
      try {
        const payload = {
          method: 'POST',
          url: '/api/user/entins',
          cancelToken: state.resultsCancelToken.token,
          body: {
            rid: search.rid,
            ents: entities
          }
        };
        const resp = await dispatch('ajaxWithTokenRefresh', payload);
        if (resp.status === 200) {
          resp.data.forEach((oid, idx) => {
            const entity = entities[idx];
            entity.oid = oid;
            entity.rid = search.rid;
            search.nodes.push(entity);
          });
          return resp.data;
        }
      } catch (e) {
        console.error(e);
      }
    },
    async saveProfiles ({ state, dispatch }, { search, entities }) {
      if (!entities) {
        return;
      }
      entities.forEach(ent => { ent.level = 1; });
      try {
        const payload = {
          method: 'POST',
          url: '/api/user/searches/' + search.rid,
          cancelToken: state.resultsCancelToken.token,
          body: { nodes: entities }
        };
        const resp = await dispatch('ajaxWithTokenRefresh', payload);
        if (resp.status === 200) {
          entities.forEach((ent, idx) => {
            ent.oid = resp.data.nodes[idx];
            ent.checked = false;
            ent.rid = search.rid;
          });
          return resp.data.nodes;
        }
      } catch (e) {
        console.error(e);
      }
    },
    createXML ({ commit, rootState }, transformData) {
      const xmlTemplate =
        `<MaltegoMessage>
          <MaltegoTransformRequestMessage>
            <Entities>
              <Entity Type="${transformData.entityType}">
                <Value>${transformData.entity.value}</Value>
                <Weight>${transformData.weight}</Weight>
              </Entity>
            </Entities>
            <TransformFields>
              <Field Name="socialLinksAPI">${rootState.authAndRegistration.account.token}</Field>
            </TransformFields>
            <Limits SoftLimit="${transformData.limit}" HardLimit="${transformData.limit}"/>
          </MaltegoTransformRequestMessage>
        </MaltegoMessage>`;

      const parser = new DOMParser();
      const xmlDoc = parser.parseFromString(xmlTemplate, 'text/xml');
      const entitiesTag = xmlDoc.getElementsByTagName('Entities')[0];
      const entityTag = entitiesTag.getElementsByTagName('Entity')[0];
      if (transformData.additionalFields.length) {
        const additionalFieldsTag = xmlDoc.createElement('AdditionalFields');
        entityTag.appendChild(additionalFieldsTag);
        for (const field of transformData.additionalFields) {
          const fieldTag = xmlDoc.createElement('Field');
          additionalFieldsTag.appendChild(fieldTag);
          fieldTag.setAttribute('Name', field.name);
          fieldTag.textContent = field.value;
        }
      }

      return new XMLSerializer().serializeToString(xmlDoc);
    },
    async parseXML ({ commit }, stringToParse) {
      const parser = new DOMParser();
      const xmlResponse = parser.parseFromString(stringToParse, 'text/xml');

      const resp = {
        balance: 0,
        entities: [],
        errors: null,
        ready: 0,
        start: 0
      };

      const uiMessage = xmlResponse.getElementsByTagName('UIMessage')[0];
      if (uiMessage) {
        resp.errors = uiMessage.textContent;
        return resp;
      }

      for (const ent of xmlResponse.getElementsByTagName('Entity')) {
        const entity = {};
        entity.typeid = ent.getAttribute('Type');
        entity.value = ent.getElementsByTagName('Value')[0].textContent;
        const weight = ent.getElementsByTagName('Weight')[0];
        if (weight) {
          entity.weight = Number(weight.textContent);
        }
        entity.fields = [];

        for (const fld of ent.getElementsByTagName('Field')) {
          const field = {};
          field.label = fld.getAttribute('DisplayName');
          field.name = fld.getAttribute('Name');
          field.rule = fld.getAttribute('MatchingRule');
          field.value = fld.textContent;
          entity.fields.push(field);
        }

        const iconURL = ent.getElementsByTagName('IconURL');
        if (iconURL.length) {
          const avatar = iconURL[0].textContent;
          if (avatar) {
            entity.fields.push({ name: 'profile-image', label: 'profile-image', rule: null, value: avatar });
          }
        }

        resp.entities.push(entity);
      }
      return resp;
    },
    async convertEntityFieldsToMap ({ state }, entities) {
      entities.forEach(ent => {
        const map = {};
        if (!ent.fields) {
          ent.fields = [];
        }
        if (!Array.isArray(ent.fields)) return;
        ent.fields.forEach(field => { map[field.name] = field; });
        ent.fields = map;
      });
      return entities;
    },
    async runTransform ({ state, dispatch, commit }, { url, transformName, xml, searchQuery, searchType }) {
      try {
        const resp = await axios.post(url, xml, {
          headers: { 'Content-Type': 'text/xml' },
          cancelToken: state.resultsCancelToken.token,
          params: { slarea_transform: transformName }
        });
        commit('updateBalance', resp.headers['x-user-balance']);
        if (resp.status === 200) {
          const response = await dispatch('parseXML', resp.data);
          if (response.error) {
            console.error(response.error);
          }
          if (!response.entities) {
            response.entities = [];
          }
          if (searchQuery) {
            response.entities.forEach(ent => {
              ent.fields.push(searchQuery);
              ent.visible = true;
            });
          }
          if (searchType) {
            response.entities.forEach(ent => {
              ent.fields.push({
                name: 'search-type',
                label: 'search-type',
                rule: null,
                value: searchType
              });
            });
          }
          if (resp.data.indexOf('Your SocialLinks API key is incorrect or empty.') !== -1) {
            commit('setNoSubscribe', true);
            commit('setConditionsOfSubscribeModal', true);
          }
          return response.entities;
        }
      } catch (e) {
        console.error(e);
      }
    },
    async getAdditionalFieldValue ({ commit }, { entity, fieldName }) {
      for (const field of entity.fields) {
        if (!field) return;
        if (field.name === fieldName) {
          return field.value;
        }
      }
    },
    async addAdditionalFields ({ dispatch }, { entity, transformData }) {
      if (transformData.searchType === 'name,location') {
        const location = await dispatch('getAdditionalFieldValue', { entity, fieldName: 'location' });
        transformData.additionalFields.push({ name: 'location', value: location });
        transformData.additionalFields.push({ name: 'fullname', value: entity.value });
      } else if (entity.typeid === 'maltego.SearchFace' || transformData.searchType === 'photo') {
        const photo = await dispatch('getAdditionalFieldValue', { entity, fieldName: 'photo' });
        transformData.additionalFields.push({ name: 'photo', value: photo });
        transformData.additionalFields.push({ name: 'fullname', value: entity.value });
      } else if (transformData.searchType === 'name,education') {
        const education = await dispatch('getAdditionalFieldValue', { entity, fieldName: 'education' });
        transformData.additionalFields.push({ name: 'education', value: education });
        transformData.additionalFields.push({ name: 'fullname', value: entity.value });
      } else if (transformData.searchType === 'name,company') {
        const company = await dispatch('getAdditionalFieldValue', { entity, fieldName: 'company' });
        transformData.additionalFields.push({ name: 'company', value: company });
        transformData.additionalFields.push({ name: 'fullname', value: entity.value });
      }
    },
    async getLatLon (ctx, entities) {
      if (!entities.length || entities.find(ent => ent.lat)) return;
      ctx.commit('foundLocations', false);
      const locations = [];
      const entitiesWithLocation = [];
      entities.forEach(ent => {
        const loc = getLocation(ent);
        if (loc.length) {
          locations.push(loc);
          entitiesWithLocation.push(ent);
        }
      });
      try {
        const response = await ctx.dispatch('ajaxWithTokenRefresh', {
          method: 'POST',
          url: '/api/geocoder/run?threads=10',
          body: locations,
          headers: {
            'Accept-Language': 'en-US;q=0.8,en;q=0.7'
          }
        });
        if (response.status === 202) {
          const interval = setInterval(async () => {
            try {
              const resp = await ctx.dispatch('ajaxWithTokenRefresh', {
                method: 'POST',
                url: '/api/geocoder/get?id=' + response.data
              });
              if (resp.status === 206) {
                const locations = resp.data.data;
                Object.keys(locations).forEach(idx => {
                  const location = locations[idx];
                  if (location) {
                    const ent = entitiesWithLocation[idx];
                    ent.lat = location.lat;
                    ent.lon = location.lon;
                    ent.country = location.address.country;
                  }
                });
              }
            } catch (e) {
              clearInterval(interval);
              ctx.commit('foundLocations', true);
            }
          }, 3000);
        }
      } catch (e) {
        console.error(e);
      }
    },
    async searchEntities ({ dispatch, state, commit }, { entity, transform }) {
      const copyOfEntity = Object.assign({}, entity);
      if (transform.searchQuery) {
        copyOfEntity.value = transform.searchQuery;
        copyOfEntity.typeid = transform.entityType;
      }
      const transformData = {
        entity: copyOfEntity,
        limit: 256,
        entityType: transform.entityType,
        additionalFields: [],
        weight: 100,
        searchType: transform.searchType
      };
      await dispatch('addAdditionalFields', { entity, transformData });

      let searchQuery = {};
      entity.fields.forEach(field => {
        // we get the part of the substring after "search-"
        // const str = field.name.substring(7, field.name.length);
        const str = field ? field.name.substring(7, field.name.length) : '';
        if (str === transform.searchType) searchQuery = field;
      });

      let results = await dispatch('runTransform', {
        url: '/endpoint.php?template=' + transform.template,
        transformName: transform.name,
        xml: await dispatch('createXML', transformData),
        searchQuery,
        searchType: transform.searchType
      });

      if (transform.name === 'LinkedinProfileDetail' && ['profile_url', 'profile_id'].includes(transform.searchType)) {
        results = results.filter(ent => ent.typeid === 'maltego.linkedin.profile');
      }

      return results || [];
    },
    async fetchResults ({ dispatch, state, commit }, { transforms, entities }) {
      const allResults = [];
      const allTransforms = Object.keys(transforms).reduce((prevTransforms, searchType) => {
        return [...prevTransforms, ...transforms[searchType].transforms];
      }, []);
      return Promise.all(allTransforms.map(async transform => {
        const entity = entities.find(ent => {
          const searchType = ent.fields.find(fld => fld.name === 'search-type');
          return searchType.value === transform.searchType;
        });
        allResults.push(...await dispatch('searchEntities', { entity, transform }));
      })).then(() => { return allResults; });
    }
  }
};
