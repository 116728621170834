<template>
  <div
    v-if="loading"
    class="d-flex align-center jc-center h-100 py-25"
    :class="[height]"
  >
    <img src="@/assets/icons/searching.gif" class="h-78" />
  </div>

  <template v-else>
    <div class="d-flex flex-w-wrap bg-form px-24 py-12">
      <a
        v-if="Object.keys(tabsProfileDetails).length > 1"
        class="text-base transition mr-12"
        :class="{
          'color-text-lighter': !allProfileTabsActive,
          'color-link fw-600': allProfileTabsActive
        }"
        @click.prevent="showAllProfileDetails"
      >All</a>
      <a
        v-for="(tab, key) in tabsProfileDetails"
        :key="key"
        class="text-base transition mr-12"
        :class="{
          'color-text-lighter': !tab,
          'color-link fw-600': tab,
        }"
        @click.prevent="tabsProfileDetails[key] = !tabsProfileDetails[key]"
      >{{ key }}</a>
    </div>
    <div class="px-24 pb-24">
      <div class="d-flex align-center jc-space-between my-24">
        <div class="text-h4">Profile info</div>
        <button
          v-if="userDetailsInfo"
          class="text-link f-flex align-center"
          @click="getMoreInfo"
        >
          <Icon name="refresh" class="transition" />
          <span class="ml-4">Refresh ({{ price }} SLP)</span>
        </button>
      </div>
      <ProfileInfo
        v-for="(tab, index) in generalInfo"
        v-show="tabsProfileDetails['General info']"
        :key="index"
        title="General info"
        :tab="tab"
        :show-title="false"
      />
      <ProfileInfo
        v-for="(tab, key) in profileDetails"
        v-show="tabsProfileDetails[key]"
        :key="key"
        :title="key"
        :tab="tab"
      />
      <button
        v-if="showMoreInfoButton && !userDetailsInfo"
        :disabled="disabledButton"
        class="bttn-primary w-100 mb-24"
        @click="getMoreInfo"
      >Get more info ({{ price }} SLP)</button>
    </div>
  </template>
</template>

<script>
import Icon from '@/components/app/Icon';
import ProfileInfo from '@/components/right-sidebar/ProfileInfo';
import { mapActions, mapGetters } from 'vuex';

export default {
  components: {
    Icon,
    ProfileInfo
  },
  props: {
    profile: { default: {} },
    bio: { default: {} },
    profileDetails: { default: {} },
    showUpdates: { default: false },
    height: { default: 'h-100' }
  },
  data () {
    return {
      loading: false,
      tabsProfileDetails: {},
      allProfileTabsActive: true
    };
  },
  watch: {
    profileDetails: {
      immediate: true,
      deep: true,
      handler () {
        const tabs = {};
        tabs['General info'] = true;
        Object.keys(this.profileDetails).forEach(detail => {
          tabs[detail] = true;
        });
        this.tabsProfileDetails = tabs;
      }
    }
  },
  computed: {
    ...mapGetters(['accountState']),

    showMoreInfoButton () {
      if (this.profile.typeid === 'maltego.facebook.profile' && !this.profile.value) return false;
      else if (this.profile.typeid === 'maltego.affiliation.Twitter' && !(this.profile.fields['twitter.screen-name'] || this.profile.username)) return false;
      else if (this.profile.typeid === 'maltego.instagram.profile' && !this.profile.value) return false;
      else if (this.profile.typeid === 'maltego.linkedin.profile' && !this.profile.value) return false;
      else return true;
    },
    userDetailsInfo () {
      return !!this.profile.fields['user-details'];
    },
    generalInfo () {
      const obj = {};
      obj['General info'] = this.bio;
      return obj;
    },
    price () {
      let index;
      if (this.profile.typeid === 'maltego.facebook.profile') {
        index = this.accountState.transforms.findIndex(transform => {
          return transform.name === '/facebook/user/v4';
        });
      } else if (this.profile.typeid === 'maltego.affiliation.Twitter') {
        index = this.accountState.transforms.findIndex(transform => {
          return transform.name === '/twitter/user';
        });
      } else if (this.profile.typeid === 'maltego.instagram.profile') {
        index = this.accountState.transforms.findIndex(transform => {
          return transform.name === '/instagram/user';
        });
      } else {
        index = this.accountState.transforms.findIndex(transform => {
          return transform.name === '/linkedin/user';
        });
      }
      return this.accountState.transforms[index].price;
    },
    disabledButton () {
      let disabled = false;

      if (this.accountState.balance < this.price) {
        disabled = true;
      }
      if (this.profile.typeid === 'maltego.linkedin.profile' && !this.profile.value) {
        disabled = true;
      }
      return disabled;
    }
  },
  methods: {
    ...mapActions(['setProfileInfo']),

    showAllProfileDetails () {
      let handler = false;
      for (const elem in this.tabsProfileDetails) {
        if (!this.tabsProfileDetails[elem]) handler = true;
      }
      this.allProfileTabsActive = handler;
      for (const elem in this.tabsProfileDetails) {
        this.tabsProfileDetails[elem] = handler;
      }
    },
    async getMoreInfo () {
      this.loading = true;
      await this.setProfileInfo(this.profile);
      this.loading = false;
    }
  }
};
</script>
