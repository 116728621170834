import { createRouter, createWebHistory } from 'vue-router';
import store from '../store';

const routes = [
  {
    path: '/login',
    name: 'Login',
    meta: { layout: 'sign', auth: false, title: 'Sign in' },
    component: () => import('@/views/Login.vue')
  },
  {
    path: '/registration',
    name: 'Registration',
    meta: { layout: 'sign', auth: false, title: 'Sign up' },
    component: () => import('@/views/Registration.vue')
  },
  {
    path: '/create_password',
    name: 'Create Password',
    meta: { layout: 'sign', auth: false, title: 'Create Password' },
    component: () => import('@/views/CreatePassword.vue')
  },
  {
    path: '/ui',
    name: 'UI KIT',
    meta: { layout: 'main', auth: true, title: 'UIkit' },
    component: () => import('@/views/UI.vue'),
    beforeEnter: async (to, from, next) => {
      try {
        if (!process.env.VUE_APP_UIKIT_MODE) next('/404');
        else next();
      } catch (error) {
        next('/404');
      }
    }
  },
  {
    path: '/case/:id',
    name: 'CasePage',
    meta: { layout: 'main', auth: true, title: 'Cases' },
    beforeEnter: async (to, from, next) => {
      try {
        if (store.state.selectedEntity === 'connection') {
          store.commit('setSearching', true);
          const investigation = await store.dispatch('checkCase', to.params.id);
          await store.dispatch('writeCaseAndResults', investigation);
          const relationsSearches = await store.dispatch('getConnectionSearches');
          store.commit('addRelationSearches', relationsSearches);
          next();
        } else {
          if (!from.name) {
            store.commit('setLoadingDirectUrl', true);
          }
          store.commit('activateRelationsSection', false);
          store.commit('clearRelationSearches');
          store.commit('selectProfile', null);
          store.commit('setSearching', true);
          store.commit('setLoadUrl', true);
          store.commit('clearConnections');
          store.commit('clearEdges');
          store.commit('openSavedInTagsPage', false);
          const investigation = await store.dispatch('checkCase', to.params.id);
          await store.dispatch('getUserTags');
          store.commit('setTagsLoading', true);
          await store.dispatch('writeCaseAndResults', investigation);
          const relationsSearches = await store.dispatch('getConnectionSearches');
          store.commit('addRelationSearches', relationsSearches);
          if (!store.state.results.onOverview) store.commit('toggleViewController');
          await store.dispatch('getLatLon', store.state.results.currentSearchResults);
          await store.dispatch('getUserTagsData', investigation.data.oid);
          await store.dispatch('getMultiprofilesWithData', investigation.data.oid);
          store.commit('setSearching', false);
          store.commit('setLoadUrl', false);
          store.commit('setTagsLoading', false);
          store.commit('setLoadingDirectUrl', false);
          next();
        }
      } catch (error) {
        next('/404');
      }
    },
    component: () => import('@/views/Case.vue'),
    children: [
      {
        path: '',
        name: 'Case',
        meta: { layout: 'main', auth: true, title: '' },
        component: () => import('@/components/Case.vue')
      },
      {
        path: 'report',
        name: 'Report',
        meta: { layout: 'main', auth: true, title: 'Report' },
        component: () => import('@/views/Report.vue'),
        beforeEnter: async (to, from, next) => {
          try {
            store.commit('setSearching', true);
            store.commit('setLoadUrl', true);
            const payload = {
              method: 'GET',
              url: `/api/user/reports/${to.params.id}`
            };
            const report = await store.dispatch('ajaxWithTokenRefresh', payload);
            if (report.data.items) {
              store.commit('setReportItems', report.data.items);
            }
            store.commit('setTitle', report.data.title);
            store.commit('setSubtitle', report.data.subtitle);
            store.commit('setDescription', report.data.description);
            store.commit('setReportMapScreens', report.data.screenshots || []);
            store.commit('setReportId', report.data.oid);
            next();
            if (!store.state.tags.tagsLoading) {
              store.commit('setSearching', false);
              store.commit('setLoadUrl', false);
            }
          } catch (error) {
            if (error.response.data.message === 'There no report for this investigation') {
              store.commit('setTitle', '');
              store.commit('setSubtitle', '');
              store.commit('setDescription', '');
              next();
              if (!store.state.tags.tagsLoading) {
                store.commit('setSearching', false);
                store.commit('setLoadUrl', false);
              }
              const payload = {
                method: 'PUT',
                url: `/api/user/reports/${to.params.id}`,
                body: {
                  title: '',
                  subtitle: '',
                  description: '',
                  items: []
                }
              };
              const report = await store.dispatch('ajaxWithTokenRefresh', payload);
              this.$store.commit('setReportId', report.data.oid);
            } else {
              next('/404');
            }
          }
        }
      }
    ]
  },
  {
    path: '/',
    name: 'Main',
    meta: { layout: 'main', auth: true, title: 'Cases' },
    beforeEnter: async (to, from, next) => {
      store.commit('clearCurrentSearchResults');
      store.commit('clearOpenSearch');
      store.commit('clearResults');
      store.commit('clearLoadingSearches');
      await store.dispatch('getMonitoredCases');
      if (!((store.getters.accountState.subscription && store.getters.accountState.subscription.active) || (store.getters.accountState.trial && store.getters.accountState.trial.active))) {
        store.dispatch('checkTrial');
      }
      const caseShare = localStorage.getItem('caseShare');
      if (caseShare) {
        try {
          await store.dispatch('caseShareAccept', caseShare);
          store.commit('setOpenShareModal', true);
          store.commit('acceptSharingForm', true);
        } catch (error) {
          if (error.response?.data?.code === 406) {
            localStorage.removeItem('caseShare');
            next(`/case/${caseShare}`);
          } else {
            store.commit('setOpenShareModal', true);
            store.commit('acceptSharingForm', true);
          }
        }
      }
      next();
    },
    component: () => import('@/views/Main.vue')
  },
  {
    path: '/paywall',
    name: 'Paywall',
    meta: { layout: 'fullscreen', auth: true, title: 'Paywall' },
    component: () => import('@/views/Paywall.vue')
  },
  {
    path: '/plan',
    name: 'Plan',
    meta: { layout: 'main', auth: true, title: 'Plan' },
    component: () => import('@/views/Plan.vue')
  },
  {
    path: '/:pathMatch(.*)',
    component: () => import('@/views/404.vue')
  },
  {
    path: '/terms',
    name: 'Terms&Policy',
    meta: { layout: 'sign', auth: false, title: 'Terms&Policy' },
    component: () => import('@/views/Terms&Policy.vue')
  },
  {
    path: '//signup/verification/:signup_token/:code',
    name: 'Verification',
    meta: { auth: false, title: 'Verification' },
    component: () => import('@/views/VerificationPage.vue')
  },
  {
    path: '//password/reset/:code',
    name: 'Reset Password',
    meta: { auth: false, title: 'Reset Password' },
    component: () => import('@/views/VerificationPage.vue')
  },
  {
    path: '/account/verification/:code',
    name: 'VerificationEmail',
    meta: { auth: true, title: 'VerificationEmail' },
    component: () => import('@/components/profile/settings/VerificationEmail.vue')
  },
  {
    path: '/sharing/case/:caseId',
    name: 'Case_Share',
    meta: { auth: true },
    beforeEnter: async (to, from, next) => {
      localStorage.setItem('caseShare', to.params.caseId);
      if (store.state.authAndRegistration.token.access) {
        next('/');
      } else {
        next('/login');
      }
    }
  },
  {
    path: '/monitoring',
    name: 'Monitoring',
    meta: { layout: 'main', auth: true, title: 'Monitoring' },
    component: () => import('@/views/Monitoring.vue'),
    beforeEnter: async (to, from, next) => {
      store.commit('setSearching', true);
      store.commit('setLoadUrl', true);
      await store.dispatch('loadInvestigations');
      await store.dispatch('getMonitoringOptions');
      await store.dispatch('getMonitoredCases');
      await store.dispatch('getMonitoringTasks');
      next();
      store.commit('setSearching', false);
      store.commit('setLoadUrl', false);
    }
  },
  {
    path: '/monitoring/settings',
    name: 'MonitoringSettings',
    meta: { layout: 'main', auth: true, title: 'Monitoring Settings' },
    component: () => import('@/views/MonitoringSettings.vue'),
    beforeEnter: async (to, from, next) => {
      store.commit('setSearching', true);
      store.commit('setLoadUrl', true);
      await store.dispatch('getMonitoringOptions');
      await store.dispatch('getMonitoredCases');
      await store.dispatch('getMonitoringTasks');
      const response = await store.dispatch('getAllInvestigations');
      store.commit('removeMonitoringCases');
      store.commit('addMonitoringCases', response.data.items);
      next();
      store.commit('setSearching', false);
      store.commit('setLoadUrl', false);
    }
  },
  {
    path: '/monitoring/review/:case_id',
    name: 'MonitoringReview',
    meta: { layout: 'main', auth: true, title: 'Monitoring' },
    component: () => import('@/views/Monitoring.vue'),
    beforeEnter: async (to, from, next) => {
      store.commit('setSearching', true);
      store.commit('setLoadUrl', true);
      await store.dispatch('getMonitoringOptions');
      await store.dispatch('getMonitoredCases');
      await store.dispatch('getMonitoringTasks');
      next();
      store.commit('setSearching', false);
      store.commit('setLoadUrl', false);
    }
  },
  {
    path: '/diff-match',
    name: 'DiffMatch',
    meta: { layout: 'diffmatch', auth: false, title: 'DiffMatch' },
    component: () => import('@/views/DiffMatch.vue')
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

router.beforeEach(async (to, from, next) => {
  if (to.name === 'Case_Share') {
    next();
    return;
  }
  const state = store.state.authAndRegistration;
  const requiredAuth = to.matched.some(record => record.meta.auth);
  if (state.token.access && !Object.keys(state.account).length) {
    await store.dispatch('checkAccount');
  }

  if (requiredAuth && state.token.access) {
    if (state.account.subscription) {
      next();
    } else if (from.path === '/login' || from.path === '/registration') {
      next(false);
    } else {
      next('/login');
    }
  } else if (requiredAuth && !state.token.access) {
    next('/login');
  } else if (!requiredAuth && !state.token.access) {
    next();
  } else if (state.account.subscription && (to.path === '/login' || to.path === '/registration')) {
    next('/');
  } else {
    next();
  }
});

export default router;
