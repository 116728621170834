export default {
  state: {
    edges: [],
    edgesDict: {}
  },
  mutations: {
    clearEdges (state, clearTaggedEntities = true) {
      state.edges = [];
      const edgesDict = {};
      if (!clearTaggedEntities) {
        Object.keys(state.edgesDict).forEach(key => {
          const edge = state.edgesDict[key];
          if (edge.tags?.length) {
            edgesDict[key] = edge;
          }
        });
      }
      state.edgesDict = edgesDict;
    },
    addEdges (state, edgesArray) {
      state.edges.push(...edgesArray);
      edgesArray.forEach(edge => {
        if (edge.oid && !state.edgesDict[edge.oid]) {
          state.edgesDict[edge.oid] = edge;
        }
      });
    },
    updateTagsInEdges (state, payload) {
      state.edges.forEach(edge => {
        if (payload.searchItems.has(edge.oid)) {
          if (edge.tags && edge.tags.length) {
            edge.tags.push(payload.tag);
          } else {
            edge.tags = [];
            edge.tags.push(payload.tag);
          }
        }
      });
    },
    deleteTagsInEdges (state, payload) {
      const removeTag = (edge) => {
        const index = edge.tags.indexOf(payload.tag.oid);
        if (index !== -1) {
          edge.tags.splice(index, 1);
        }
      };
      if (payload.attachProfile) {
        const edge = state.edges.find(edge => { return payload.attachProfile.oid === edge.oid; });
        if (edge) {
          removeTag(edge);
        }
        return;
      }
      state.edges.forEach(edge => {
        if (edge.tags && edge.tags.length) {
          removeTag(edge);
        }
      });
    },
    checkEdge (state, obj) {
      let edgeOID = obj;
      let typeID = null;
      if (typeof obj === 'object') {
        edgeOID = obj.oid;
        typeID = obj.typeid;
      }
      const edge = state.edgesDict[edgeOID];
      if (edge) {
        if (typeID) state.edgesDict[edgeOID].typeid = typeID;
        state.edgesDict[edgeOID].checked = !state.edgesDict[edgeOID].checked;
      }
    },
    checkAllEdges (state, value) {
      state.edges.forEach(edge => { edge.checked = value; });
    },
    countToShowPosts (state, value) {
      const res = state.edges.find(result => {
        return result.oid === value.oid;
      });
      res.countPostsToShow = value.count;
    }
  },
  getters: {
    edges (state) {
      return state.edges;
    },
    edgesMap (state) {
      return state.edgesDict;
    }
  },
  actions: {}
};
