<template>
  <div class="min-h-100 overlow-x-hidden bg-form p-48">
    profile notifications
  </div>
</template>

<script>
export default {

};
</script>
