export default {
  state: {
    monitoringOptions: null,
    cases: [],
    caseSearchesStorage: {},
    loadedProfiles: {},
    monitoredCases: [],
    monitoredProfiles: {},
    tasks: {},
    caseToRemove: null,
    taskToRemove: null
  },
  mutations: {
    addMonitoringOptions (state, value) {
      state.monitoringOptions = value;
    },
    addMonitoringCases (state, cases) {
      state.cases.push(...cases);
    },
    removeMonitoringCases (state) {
      state.cases = [];
    },
    keepMonitoringProfiles (state, { searchID, profiles }) {
      state.loadedProfiles[searchID] = profiles;
    },
    keepCaseSearches (state, { caseID, searches }) {
      state.caseSearchesStorage[caseID] = searches;
    },
    addMonitoredCases (state, cases) {
      if (cases) {
        state.monitoredCases.push(...cases);
      }
    },
    removeMonitoredCases (state) {
      state.monitoredCases = [];
    },
    addMonitoredProfiles (state, { caseID, profiles }) {
      state.monitoredProfiles[caseID] = profiles;
    },
    addMonitoringTasks (state, { caseID, tasks }) {
      state.tasks[caseID] = tasks;
    },
    pauseMonitoringTasks (state, { caseID, taskID, pause }) {
      const tasks = state.tasks[caseID];
      if (taskID) {
        const task = tasks.find(task => task.id === taskID);
        task.paused = pause;
      } else {
        tasks.forEach(task => { task.paused = pause; });
      }
    },
    setCaseToRemove (state, investigation) {
      state.caseToRemove = investigation;
    },
    setTaskToRemove (state, task) {
      state.taskToRemove = task;
    }
  },
  getters: {
    monitoringOptions (state) {
      return state.monitoringOptions;
    },
    monitoringCases (state) {
      return state.cases;
    },
    monitoringLoadedProfiles (state) {
      return state.loadedProfiles;
    },
    caseSearchesStorage (state) {
      return state.caseSearchesStorage;
    },
    monitoredCases (state) {
      return state.monitoredCases;
    },
    monitoredCasesSorted (state) {
      return state.monitoredCases.sort((a, b) => b.crtunix - a.crtunix);
    },
    monitoredProfiles (state) {
      return state.monitoredProfiles;
    },
    monitoringTasks (state) {
      return state.tasks;
    },
    caseToRemove (state) {
      return state.caseToRemove;
    },
    taskToRemove (state) {
      return state.taskToRemove;
    }
  },
  actions: {
    async getMonitoringOptions ({ state, commit, dispatch }) {
      if (!state.monitoringOptions) {
        const response = await dispatch('ajaxWithTokenRefresh', { url: '/api/monitoring/options' });
        commit('addMonitoringOptions', response.data);
      }
    },
    async getMonitoredCases ({ commit, dispatch }) {
      commit('removeMonitoredCases');
      const payload = {
        method: 'POST',
        url: '/api/monitoring/cases/list',
        body: {
          limit: 1000,
          offset: 0
        }
      };
      while (true) {
        const response = await dispatch('ajaxWithTokenRefresh', payload);
        if (response.data.cases) {
          commit('addMonitoredCases', response.data.cases);
          if (response.data.cases.length < payload.body.limit) {
            break;
          } else {
            payload.body.offset += payload.body.limit;
          }
        } else {
          break;
        }
      }
    },
    async getMonitoringTasks ({ state, commit, dispatch }, caseID) {
      const caseIDs = caseID ? [caseID] : state.monitoredCases.map(inv => inv.oid);
      return Promise.all(caseIDs.map(async (caseID) => {
        const payload = {
          method: 'POST',
          url: '/api/monitoring/tasks/list',
          body: {
            case_id: caseID,
            limit: 1000,
            offset: 0,
            events_limit: 1000
          }
        };
        while (true) {
          const response = await dispatch('ajaxWithTokenRefresh', payload);
          const monitoringTasks = [];
          response.data.tasks.forEach(task => {
            const pipelines = response.data.pipelines.find(e => e.task_id === task.id) || [];
            task = { ...task, pipelines: pipelines };
            monitoringTasks.push(task);
          });

          if (response.data.cases) {}
          const profiles = response.data.entities || [];
          await dispatch('convertEntityFieldsToMap', profiles);
          commit('addMonitoredProfiles', { caseID, profiles });
          commit('addMonitoringTasks', { caseID, tasks: monitoringTasks || [] });
          if (response.data.tasks.length < payload.body.limit) {
            break;
          } else {
            payload.body.offset += payload.body.limit;
          }
        }
      }));
    },
    async updateMonitoringTask ({ state, commit, dispatch }, { caseID, taskID, paused, period }) {
      return dispatch('ajaxWithTokenRefresh', {
        method: 'POST',
        url: '/api/monitoring/tasks/update',
        body: {
          case_id: caseID,
          task_id: taskID,
          paused,
          period
        }
      });
    },
    async removeMonitoringTasks ({ dispatch }, { caseID, taskID }) {
      const payload = {
        method: 'POST',
        url: '/api/monitoring/tasks/delete',
        body: {
          case_id: caseID,
          task_id: taskID || null
        }
      };
      if (taskID) payload.body.task_id = taskID;
      await dispatch('ajaxWithTokenRefresh', payload);
    }
  }
};
