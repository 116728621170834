<template>
  <router-link
    @mouseover="hovered = true"
    @mouseleave="hovered = false"
    :to="'/case/' + investigation.oid"
    class="d-flex align-center jc-space-between fs-14 lh-24 fw-400 pt-8 pb-8 open-case test-sidebar-case"
    :class="{ 'is-active': hovered || editPanel }"
  >
    <div class="d-flex">
      <div class="item-index fw-600">
        <div v-show="!hovered && !editPanel">{{ index + 1 }}</div>
        <div v-show="hovered && !editPanel" class="row no-grid">
          <button
            class="test-case-panel"
            @click.stop.prevent="togglePanel(true, investigation.tag)"
          >
            <Icon name="edit-viewbox-right" class="icon-small icon-simple" />
          </button>
          <button
            class="test-open-delete-case"
            @click.stop.prevent="openDeleteModal = true"
          >
            <Icon name="trash-viewbox-left" class="icon-small icon-simple" />
          </button>
        </div>
        <div v-if="editPanel">
          <button
            @click.stop.prevent="togglePanel(false, investigation.tag)"
            class="icon-arrow test-case-panel-close"
          >
            <Icon name="arrow-left" class="icon-simple" />
          </button>
        </div>
      </div>
      <Tooltip
        v-if="!editPanel"
        :text="caseTitle"
        :limit="26"
      />
    </div>
    <div
      v-if="!editPanel"
      class="uppercase fs-11 fw-600"
      :class="{ 'color-link_hover': hovered }"
    >
      {{ date(investigation.crtunix) }}
    </div>
    <div
      v-if="editPanel"
      @click.stop.prevent class="edit-panel"
    >
      <input
        type="text"
        v-model="caseName"
        ref="input"
        @blur="editCase()"
        @keydown.enter="editCase()"
        class="fw-600 test-rename-case"
      />
      <div class="edit-panel__text fw-600">
        <div
          :class="{ 'is-warning': overLimit }"
          class="text-limit"
        >
          {{ caseName.length }}
        </div>
        /
        {{ limit }}
        would be visible
      </div>
    </div>
  </router-link>

  <div class="loader-block" v-if="deleteProcess">
    <img src="@/assets/icons/searching.gif" alt="" class="h-78">
  </div>
  <AcceptModal
    v-if="openDeleteModal"
    @closeModal="openDeleteModal = false"
    @confirmAnswer="confirmDelete">
    <p class="typography-5 fw-600 let-space-12 color-1 mb-24 overflow-hidden">Are you sure <br> you want to delete {{ investigation.tag }}?</p>
  </AcceptModal>
</template>

<script>
import Icon from '@/components/app/Icon';
import Tooltip from '@/components/UI/Tooltip';
import AcceptModal from '@/components/app/AcceptModal';
import { dateFormat } from '@/utils/helpers';
import { mapGetters } from 'vuex';

export default {
  name: 'SidebarCase',
  components: {
    Icon,
    Tooltip,
    AcceptModal
  },
  emits: ['deleteCase', 'openShareModal'],
  props: {
    investigation: { required: true },
    index: { required: true },
    limit: { required: false, default: 26 }
  },

  data () {
    return {
      hovered: false,
      showTooltip: false,
      editPanel: false,
      caseName: '',
      openDeleteModal: false,
      deleteProcess: false
    };
  },

  computed: {
    ...mapGetters(['getInvestigations']),

    overLimit () {
      return this.caseName.length > this.limit;
    },

    caseTitle () {
      if (this.investigation.tag.length > this.limit) {
        return this.investigation.tag.substring(0, this.limit - 5) + '...';
      } else {
        return this.investigation.tag;
      }
    }
  },

  methods: {
    date (val) {
      return dateFormat(val);
    },

    async editCase () {
      if (this.caseName.length > this.limit) return;
      if (this.caseName.trim() === '' || this.caseName.length < 1) return;
      try {
        const caseInfo = {
          fid: this.investigation.oid,
          tag: this.caseName
        };
        await this.$store.dispatch('editCase', caseInfo);
        this.$store.commit('editCase', { id: this.investigation.oid, name: this.caseName });
        this.editPanel = false;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },

    togglePanel (bool, inpVal) {
      this.caseName = inpVal;
      this.editPanel = bool;
      if (this.editPanel) setTimeout(() => { this.$refs.input.focus(); }, 0);
    },

    async confirmDelete () {
      try {
        this.deleteProcess = true;
        this.openDeleteModal = false;
        const payload = {
          method: 'POST',
          url: '/api/user/invdel',
          body: {
            fid: this.investigation.oid
          }
        };
        await this.$store.dispatch('ajaxWithTokenRefresh', payload);
        this.$store.commit('deleteCase', this.investigation.oid);
        this.$store.commit('updateCountOfCases');
        if (this.getInvestigations.length === 0 && this.$route.path === '/') {
          await this.$store.dispatch('createNewCase');
        }
        this.deleteProcess = false;
      } catch (error) {
        console.error(error);
        this.deleteProcess = false;
        throw error;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.tooltip-container {
  position: relative;
}
</style>
