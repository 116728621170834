export default {
  state: {
    visibleParent: false,
    visibleChild: false,
    profileParent: null,
    profileChild: null,
    tabSocialGraph: false
  },
  getters: {
    getVisibleSidebar (state) {
      return state.visibleParent || state.visibleChild;
    },
    getVisibleSidebarChild (state) {
      return state.visibleChild;
    },
    getVisibleSidebarParent (state) {
      return state.visibleParent;
    },
    getProfileParent (state) {
      return state.profileParent;
    },
    getProfileChild (state) {
      return state.profileChild;
    },
    getTabSocialGraph (state) {
      return state.tabSocialGraph;
    }
  },
  mutations: {
    SET_VISIBLE_PARENT: (state, bool) => { state.visibleParent = bool; },
    SET_VISIBLE_CHILD: (state, bool) => { state.visibleChild = bool; },
    SET_PROFILE_PARENT: (state, val) => { state.profileParent = val; },
    SET_PROFILE_CHILD: (state, val) => { state.profileChild = val; },
    SET_TAB_SOCIAL_GRAPH: (state, bool) => { state.tabSocialGraph = bool; }
  },
  actions: {
    setOpenRightSidebar ({ commit, state }, profileInfo) {
      let parentInfo = profileInfo[0];
      if (!profileInfo[1]) parentInfo = profileInfo;
      if (profileInfo[1] && profileInfo[1] === 'openSocial') commit('SET_TAB_SOCIAL_GRAPH', true);
      commit('SET_PROFILE_PARENT', parentInfo);
      setTimeout(() => { commit('SET_VISIBLE_PARENT', true); }, 500);

      if (profileInfo[1] && profileInfo[1] !== 'openSocial') {
        commit('SET_PROFILE_CHILD', profileInfo[1]);
        setTimeout(() => { commit('SET_VISIBLE_CHILD', true); }, 500);
      }
    },
    setCloseParentSidebar ({ commit }) {
      commit('SET_VISIBLE_PARENT', false);
      setTimeout(() => {
        commit('SET_PROFILE_PARENT', null);
      }, 500);
    },
    setCloseChildSidebar ({ commit }) {
      commit('SET_VISIBLE_CHILD', false);
      setTimeout(() => {
        commit('SET_PROFILE_CHILD', null);
      }, 500);
    },
    setCloseRightSidebar (ctx, modalType) {
      if (modalType === 'parent') {
        ctx.dispatch('setCloseParentSidebar');
      } else
      if (modalType === 'child') {
        ctx.dispatch('setCloseChildSidebar');
      } else {
        ctx.dispatch('setCloseParentSidebar');
        ctx.dispatch('setCloseChildSidebar');
      }
      ctx.commit('SET_TAB_SOCIAL_GRAPH', false);
    }
  }
};
