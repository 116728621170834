<template>
  <div class="d-flex fd-column jc-space-between bg-white">
    <div>
      <h2 class="text-h4 px-24 pt-48 pb-24">Profile</h2>
      <ul>
        <li
          v-for="(link, index) in links"
          :key="index"
        >
          <a
            class="d-flex align-center color-text color-link_hover b-left transition lh-24 px-24 py-14"
            :class="{ 'bg-white bg-form_hover bc-white' : !activeLinks[link.url], 'bg-form bc-link-darker' : activeLinks[link.url] }"
            @click.prevent="clickMenu(link.url)"
          >
            <Icon
              v-if="!activeLinks[link.url]" :name="link.icon"
              class="color-link"
            />
            <Icon
              v-else :name="link.icon + '-fill'"
              class="color-link"
            />
            <span
              class="ml-10"
              :class="{ 'fs-14' : !activeLinks[link.url], 'color-link fs-13 fw-600' : activeLinks[link.url] }"
            >{{ link.title }}</span>
          </a>
        </li>
      </ul>
    </div>
    <div>
      <a
        class="d-flex align-center color-warning fs-14 lh-24 lh-24 px-24 py-14"
        @click.prevent="logoutMethod"
      >
        <Icon class="w-24px" name="exit" />
        <span class="ml-10">Logout</span>
      </a>
    </div>
  </div>
</template>

<script>
import Icon from '@/components/app/Icon';
import { mapActions } from 'vuex';

export default {
  props: ['links', 'activeLinks'],
  components: {
    Icon
  },
  methods: {
    ...mapActions(['logout']),

    clickMenu (url) {
      this.$router.push({ query: { ...this.$route.query, profile: url } });
    },
    logoutMethod () {
      this.logout();
      this.$router.push('/login');
      location.reload();
    }
  }
};
</script>
