<template>
  <div>
    <button class="sidebar__options-item custom-padding w-100 border-0">
    <div class="d-flex move-block">
      <Icon name="trash" bg="#FFFFFF" class="trash-icon mr-4" @click.stop="removeMultiprofile" />
      <div class="d-flex align-center multiprofile-icon">
        <img :src="sourceAvatar(profiles[0])" @load="avatarLoadHandler(profiles[0])" @error="avatarErrorHandler(profiles[0])" alt="" class="sidebar-avatar" :class="profiles[0]?.loadImage ? 'd-block' : 'd-none'">
        <img src="@/assets/icons/avatar.png" alt="" class="sidebar-avatar" :class="profiles[0]?.loadImage ? 'd-none' : 'd-block'">
      </div>
    </div>
      <div class="d-flex move-block ml-12 fs-13 justify-between custom-m-w-240" @click.prevent="openSublist(index)">
        <span class="sidebar-tagname color-2 mr-12 ellipsis-text">{{ multiprofileName }} multiprofile</span>
        <div class="d-flex align-center">
          <span class="fw-500 mr-6 ellipsis-text">({{profiles.length}} {{profiles.length > 1 ? 'entities' : 'entity'}})</span>
          <Icon name="arrow-down-white" color="#60BCE3" />
        </div>
      </div>
    <div class="right-icon right-icon--0 d-flex" v-if="multiprofile.id === loading">
      <span class="load-circle load-circle--1"></span>
      <span class="load-circle load-circle--2"></span>
      <span class="load-circle load-circle--3"></span>
    </div>
      <div class="right-icon right-icon--23" v-else>
        <input type="checkbox" name="" class="sidebar-checkbox" :checked="multiprofile.checked">
        <label class="sidebar-checkbox__label" @click.stop="checkMultiprofile"></label>
      </div>
    </button>
    <ul class="sidebar-sublist sublist-accordeon pl-24 pr-0" :ref="'sub-' + index">
      <li class="mb-8" v-for="(profile, i) in profiles" :key="profile.oid">
        <button class="d-flex align-center profile-tag">
          <div class="d-flex align-center mr-12">
            <span class="icon-wrap mr-12">
              <Icon :name="socialNetworkIcon(profile)" v-if="socialNetworkIcon(profile)" />
            </span>
            <img :src="sourceAvatar(profile)" @load="avatarLoadHandler(profile)" @error="avatarErrorHandler(profile)" alt="" class="sidebar-avatar" :class="profile.loadImage ? 'd-block' : 'd-none'">
            <img src="@/assets/icons/avatar.png" alt="" class="sidebar-avatar" :class="profile.loadImage ? 'd-none' : 'd-block'">
            <Icon name="user-icon" class="ml--8" v-if="hasUserDetails(profile)" />
            <Icon name="question" class="ml--8" v-else />
            <Icon name="new-connection-icon" class="ml--8" v-if="hasConnections(profile)" />
              <Icon name="question" class="ml--8" v-else />
          </div>
          <span class="typography-base fw-500 tagged-profile-name" @click="openMultiprofile">{{ name(profile) }}</span>
          <div class="right-icon right-icon--23">
            <input type="checkbox" name="" class="sidebar-checkbox" :id="profile.oid + '-' + i" :checked="profile.checked">
            <label class="sidebar-checkbox__label" @click.stop="checkEntity(profile)"></label>
          </div>
        </button>
      </li>
    </ul>
  </div>
</template>

<script>
import Icon from '../app/Icon';
import { getEntityAvatar, getEntityName } from '../../utils/helpers';
import { mapGetters, mapMutations, mapState } from 'vuex';

export default {
  name: 'MultiprofileWidget',
  components: {
    Icon
  },
  data () {
    return {
      loading: ''
    };
  },
  props: ['multiprofile', 'index'],
  computed: {
    ...mapGetters(['resultsMap', 'sortedRelationSearches']),
    ...mapState({
      openMultiprofile: state => state.multiprofile.opened
    }),
    profiles () {
      const profiles = [];
      this.multiprofile.entity_ids.forEach(id => {
        for (const key in this.resultsMap) {
          if (Object.prototype.hasOwnProperty.call(this.resultsMap, key) && key === id) {
            profiles.push(this.resultsMap[key]);
          }
        }
      });
      return profiles;
    },
    multiprofileName () {
      return this.profiles?.length && (this.profiles[0].fields.name?.value || this.profiles[0].fields['person.name']?.value);
    }
  },
  methods: {
    ...mapMutations(['openResults']),
    removeMultiprofile () {
      this.loading = this.multiprofile.id;
      setTimeout(() => {
        const payload = {
          method: 'POST',
          url: '/api/user/multiprofiles/delete',
          body: {
            id: this.multiprofile.id
          }
        };
        this.$store.dispatch('ajaxWithTokenRefresh', payload);
        this.$store.commit('removeMultiprofile', this.multiprofile.id);
        this.loading = '';
        if (this.openMultiprofile) {
          this.$store.commit('openMultiprofile', false);
          this.openResults(true);
        }
      }, 2000);
    },
    avatarLoadHandler (profile = {}) {
      profile.loadImage = true;
    },
    avatarErrorHandler (profile = {}) {
      profile.loadImage = false;
    },
    sourceAvatar (profile) {
      return getEntityAvatar(profile);
    },
    openSublist (index) {
      const elem = this.$refs['sub-' + index];
      if (elem.style.maxHeight) {
        elem.style.maxHeight = null;
        elem.style['margin-bottom'] = '0';
      } else {
        elem.style.maxHeight = elem.scrollHeight + 'px';
        elem.style['margin-bottom'] = '16px';
      }
    },
    socialNetworkIcon (profile) {
      try {
        for (const socialNet of ['instagram', 'Twitter', 'facebook', 'linkedin']) {
          if (profile.typeid.includes(socialNet)) {
            return 'person-card-' + socialNet.toLowerCase();
          }
        }
        return '';
      } catch (e) {
        return '';
      }
    },
    name (profile) {
      return getEntityName(profile);
    },
    openMultiprofile () {
      if (this.$route.name === 'Report') {
        this.$router.push(`/case/${this.$route.params.id}`);
      }
      this.$store.dispatch('openMultiprofile', this.multiprofile);
    },
    checkEntity (profile) {
      this.$store.commit('checkResultsProfile', profile.oid);
    },
    checkMultiprofile () {
      const check = !this.multiprofile.checked;
      this.profiles.forEach(profile => {
        profile.checked = check;
      });
      const obj = {
        id: this.multiprofile.id,
        check: check
      };
      this.$store.commit('checkMultiprofile', obj);
    },
    hasUserDetails (profile) {
      return !!profile.fields['user-details'];
    },
    hasConnections (profile) {
      const set = new Set();
      this.sortedRelationSearches.forEach(relation => {
        relation.targets.forEach(target => {
          set.add(target);
        });
      });
      return set.has(profile.oid);
    }
  }
};
</script>

<style lang="scss" scoped>
.custom-padding {
  padding-right: 50px;
}
.tagged-profile-name {
  width: 200px;
}
.multiprofile-icon {
  margin-left: 25px;
  transition: margin-left .7s
}
.custom-m-w-240 {
  max-width: 240px;
}
</style>
