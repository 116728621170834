export class ConnectionsOriginProfile {
  constructor (entity, type, last, searchID) {
    this.entity = entity;
    this.type = type;
    this.last = last;
    this.searchID = searchID;

    this.photos = {};
    this.posts = {};
    this.profileInfo = {};
    this.connections = [];

    this.postTypes = {
      facebook: 'maltego.facebook.post',
      instagram: 'maltego.instagram.photo',
      linkedin: 'maltego.linkedin.post',
      twitter: 'maltego.Twit'
    };
  }

  parseResponse (response) {
    response.data.posts.forEach(post => { this.posts[post.id] = post; });
    this.connections = response.data.connections;
  }

  setPostsOrPhotosProperties (rid, photos = false) {
    const posts = (photos) ? this.photos : this.posts;
    Object.keys(posts).forEach(key => {
      const post = posts[key];
      post.rid = rid;
      post.typeid = this.postTypes[this.type];
      post.value = post.id?.toString();
      post.fields = {};

      const url = post.url || post.post_url || post.photo_url;
      if (url) {
        post.fields.url = { name: 'url', value: url };
      }

      let image = post.image || post.image_url;
      if (Array.isArray(image) && image.length) {
        image = image[0];
      }
      if (image) {
        post.fields.image = { name: 'image', value: image };
      }

      const text = post.post_content || post.title || post.text;
      if (text) {
        post.fields.text = { name: 'text', value: text };
      }

      const date = post.published_at || post.created_time || post.time || post.publish_time;
      if (date) {
        post.fields.date = { name: 'date', value: date.toString() };
      }

      post.fields.connection = { name: 'connection', value: 'true' };
    });
  }

  getPostsDict (uniqPostIDs) {
    const postsDict = {};
    for (const postID of uniqPostIDs) {
      postsDict[postID] = Object.assign({}, this.posts[postID] || this.photos[postID]);
    }
    return postsDict;
  }
}

export class TwitterConnectionOriginProfile extends ConnectionsOriginProfile {}

export class FacebookConnectionOriginProfile extends ConnectionsOriginProfile {
  parseResponse (response) {
    this.profileInfo = response.data.result.user_info || {};
    this.posts = response.data.result.posts || {};
    this.photos = response.data.result.photos || {};
    this.connections = response.data.result.connections;
  }
}

export class InstagramConnectionOriginProfile extends ConnectionsOriginProfile {
  setPostsOrPhotosProperties (rid, photos = false) {
    const posts = (photos) ? this.photos : this.posts;
    Object.keys(posts).forEach(key => {
      const post = posts[key];
      post.rid = rid;
      post.typeid = this.postTypes[this.name];
      post.value = post.id?.toString();
      post.fields = {};

      const url = post.url || post.post_url || post.photo_url;
      if (url) {
        post.fields.url = { name: 'url', value: url };
      } else {
        post.fields.url = { name: 'url', value: `https://www.instagram.com/p/${post.id}/` };
      }

      let image = post.image || post.image_url;
      if (Array.isArray(image) && image.length) {
        image = image[0];
      }
      if (image) {
        post.fields.image = { name: 'image', value: image };
      }

      const text = post.post_content || post.title || post.text;
      if (text) {
        post.fields.text = { name: 'text', value: text };
      }

      const date = post.published_at || post.created_time || post.time || post.publish_time;
      if (date) {
        post.fields.date = { name: 'date', value: date.toString() };
      }

      post.fields.connection = { name: 'connection', value: 'true' };
    });
  }
}

export class LinkedInConnectionOriginProfile extends ConnectionsOriginProfile {
  setPostsOrPhotosProperties (rid, photos = false) {
    const target = photos ? this.photos : this.posts;
    Object.keys(target).forEach(key => {
      const post = target[key];
      post.rid = rid;
      post.typeid = this.postTypes[this.name];
      post.value = post.id?.toString();
      post.fields = {};

      const url = post.url || post.post_url || post.photo_url;
      if (url) {
        post.fields.url = { name: 'url', value: url };
      } else {
        post.fields.url = { name: 'url', value: post.id };
      }

      let image = post.image || post.image_url;
      if (Array.isArray(image) && image.length) {
        image = image[0];
      }
      if (image) {
        post.fields.image = { name: 'image', value: image };
      }

      const text = post.post_content || post.title || post.text;
      if (text) {
        post.fields.text = { name: 'text', value: text };
      }

      const date = post.published_at || post.created_time || post.time || post.publish_time;
      if (date) {
        post.fields.date = { name: 'date', value: date.toString() };
      }

      post.fields.connection = { name: 'connection', value: 'true' };
    });
  }
}
