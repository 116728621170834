export default {
  state: {
    sidebarLoader: false
  },
  getters: {
    getSidebarLoader (state) {
      return state.sidebarLoader;
    }
  },
  mutations: {
    SET_SIDEBAR_LOADER: (state, value) => {
      state.sidebarLoader = value;
    }
  }
};
