import router from '../../router';

export default {
  state: {
    confirmErrorsEmail: {
      timeoutError: '',
      error: ''
    },
    confirmErrorsPhone: {
      timeoutError: '',
      error: ''
    }
  },
  mutations: {
    setTimeoutEmail (state, timeout) {
      state.confirmErrorsEmail.timeoutError = timeout;
    },
    setErrorEmail (state, error) {
      state.confirmErrorsEmail.error = error;
    },
    setTimeoutPhone (state, timeout) {
      state.confirmErrorsPhone.timeoutError = timeout;
    },
    setErrorPhone (state, error) {
      state.confirmErrorsPhone.error = error;
    }
  },
  getters: {
    emailTimeout (state) {
      return state.confirmErrorsEmail.timeoutError;
    },
    emailError (state) {
      return state.confirmErrorsEmail.error;
    },
    phoneTimeout (state) {
      return state.confirmErrorsPhone.timeoutError;
    },
    phoneError (state) {
      return state.confirmErrorsPhone.error;
    }
  },
  actions: {
    async sendConfirmEmail (ctx, code = null) {
      try {
        ctx.commit('setTimeoutEmail', '');
        if (code) {
          ctx.commit('setErrorEmail', '');
        }
        const payload = {
          method: 'PUT',
          url: '/api/v2/verification/email',
          body: code || {}
        };
        await ctx.dispatch('ajaxWithTokenRefresh', payload);
        if (code) {
          ctx.commit('setAccountConfirmEmail', true);
        }
      } catch (error) {
        const res = error.response;
        if (res.data.non_field_errors && res.data.non_field_errors.length) {
          const str = res.data.non_field_errors[0][0];
          const mutation = (str.indexOf('You can ask new code') !== -1) ? 'setTimeoutEmail' : 'setErrorEmail';
          ctx.commit(mutation, str);
        } else {
          ctx.commit('setErrorEmail', 'Something went wrong, try again later');
        }
        throw error;
      }
    },
    async sendConfirmPhone (ctx, code = null) {
      try {
        ctx.commit('setTimeoutPhone', '');
        if (code) {
          ctx.commit('setErrorPhone', '');
        }
        const payload = {
          method: 'PUT',
          url: '/api/v2/verification/sms',
          body: code || {}
        };
        await ctx.dispatch('ajaxWithTokenRefresh', payload);
        // Set full account info before go to main page
        if (code) {
          await ctx.dispatch('checkTrial');
          await ctx.dispatch('checkAccount');
          router.push({ path: '/' });
        }
      } catch (error) {
        const res = error.response;
        if (res.data.non_field_errors && res.data.non_field_errors.length) {
          const str = res.data.non_field_errors[0][0];
          const mutation = (str.indexOf('You can ask new code') !== -1) ? 'setTimeoutPhone' : 'setErrorPhone';
          ctx.commit(mutation, str);
        } else {
          ctx.commit('setErrorPhone', 'Something went wrong, try again later');
        }
        throw error;
      }
    }
  }
};
