<template>
  <header>
    <div class="d-flex align-center jc-space-between h-80px bg-form bs-default px-48">
      <div>
        <PlanLink />
      </div>
      <div class="p-relative d-flex align-center">
        <!-- <NotificationsLink /> -->
        <LoginLink />
      </div>
    </div>
  </header>
</template>

<script>
import PlanLink from '@/components/app/PlanLink';
import LoginLink from '@/components/app/LoginLink';
// import NotificationsLink from '@/components/app/NotificationsLink';

export default {
  components: {
    PlanLink,
    LoginLink
    // NotificationsLink
  }
};
</script>
