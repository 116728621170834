<template>
  <div class="p-relative">
    <div
      v-if="openTagsTooltip"
      class="p-absolute z-2 w-310px"
      :class="{
        'left-0': positionX === 'left',
        'abs-center-x': positionX === 'center',
        'right-0': positionX === 'right',
        'bottom-100 mb-15': positionY === 'top',
        'top-100 mt-15': positionY === 'bottom'
      }"
    >
      <div class="bs-default br-16 bg-white pt-24 px-24 pb-24">
        <div class="d-flex align-center jc-space-between mb-12">
          <div class="fs-16 fw-600 lh-24">Select tag</div>
          <a
            @click.prevent="openTagsTooltip = false"
            class="text-link lh-20 w-14px h-14px"
          >
            <Icon
              name="cancel-btn-new"
              class="w-100 h-100"
            />
          </a>
        </div>

        <div
          v-if="tagsList.length"
          class="custom-scrollbar mb-12"
        >
          <a
            v-for="tag in tagsList"
            :key="tag.oid"
            @click.prevent="addTag(tag)"
            class="d-flex align-center jc-space-between mb-10"
          >
            <div class="d-flex align-center">
              <div
                :style="{ backgroundColor: tag.color }"
                class="br-circle w-20px h-20px mr-8"
              ></div>
              <div class="text-link color-text-darker">{{ tag.name }}</div>
            </div>
            <button @click.stop.prevent="tagName = tag.name">
              <Icon name="edit" />
            </button>
          </a>
        </div>

        <div class="p-relative">
          <input
            class="input-text pr-60"
            type="text"
            placeholder="Enter tag name"
            v-model.trim="tagName"
            @keyup.enter="addTag()"
          />
          <div class="p-absolute align-center d-flex top-0 bottom-0 right-0">
            <button
              v-show="tagName.length > 2"
              @click.prevent="addTag()"
              class="w-20px"
            >
              <Icon name="ok" />
            </button>
            <button
              v-show="tagName.length"
              @click.prevent="tagName = ''"
              class="w-20px ml-5"
            >
              <Icon name="cancel" />
            </button>
          </div>
        </div>
      </div>
      <div
        class="p-absolute"
        :class="{
          'left-10px': positionX === 'left',
          'abs-center-x': positionX === 'center',
          'right-10px': positionX === 'right',
          'bottom-100': positionY === 'bottom'
        }"
      >
        <img
          class="d-block"
          :class="{ 'rotate--180': positionY === 'bottom' }"
          src="@/assets/icons/tag-triangle.png"
        />
      </div>
    </div>
    <div
      v-if="openTagsTooltip"
      @click="openTagsTooltip = false"
      class="p-fixed top-0 left-0 right-0 bottom-0"
    ></div>

    <a
      class="d-flex align-center transition"
      :class="{
        'text-link color-text color-link_hover': size === 'big',
        'text-caption color-link color-link-lighter_hover': size === 'small'
      }"
      @click.prevent="openTags"
    >
      <div
        :class="{
          'w-32px h-32px bg-body br-circle color-link p-7 mr-12': size === 'big',
          'w-12px h-12px mr-6': size === 'small'
        }"
      >
        <Icon class="w-100 h-100" name="tag-new" />
      </div>
      {{ tagTitle }}
    </a>
    <teleport to="#tag-modal">
      <SendModal
        v-if="tagModal"
        title="Information will be incomplete"
        subtitle="You can get more information about the profile saved in the tag"
        :confirmText="`Get more info (${moreInfoPrice} SLP)`"
        declineText="Save it anyway"
        :loading="tagLoading"
        @confirmAnswer="getMoreInfo"
        @closeModal="saveAnyway"
      />
    </teleport>
  </div>
</template>

<script>
import Icon from '@/components/app/Icon';
import SendModal from '@/components/UI/SendModal';
import { mapGetters, mapActions } from 'vuex';

export default {
  components: {
    Icon,
    SendModal
  },
  props: {
    profiles: { default: [] },
    size: { default: 'big' },
    positionX: { default: 'center' },
    positionY: { default: 'top' }
  },
  data () {
    return {
      tagTitle: 'Save in Tag',
      openTagsTooltip: false,
      tagName: '',
      tagModal: false,
      tagLoading: false,
      tagInfo: null
    };
  },
  watch: {
    lowInfoProfiles: {
      immediate: false,
      handler () {
        if (!this.lowInfoProfiles.length) {
          this.tagLoading = false;
          this.tagModal = false;
          this.setCurrentTagged(true);
        }
      }
    }
  },
  computed: {
    ...mapGetters(['getTaggedList', 'getCurrentTagged']),

    tagsList () {
      if (this.tagName.length) return this.getTaggedList.filter(tag => tag.name.toLowerCase().indexOf(this.tagName.toLowerCase()) > -1);
      else return [];
    },
    lowInfoProfiles () {
      const lowInfoProfiles = [];
      let profiles = this.profiles;
      if (this.profiles.oid) profiles = [this.profiles];
      profiles.forEach(profile => {
        let startCheck = true;
        if (profile.typeid === 'maltego.facebook.profile' && !profile.value) startCheck = false;
        else if (profile.typeid === 'maltego.affiliation.Twitter' && !(profile.fields['twitter.screen-name'] || profile.username)) startCheck = false;
        else if (profile.typeid === 'maltego.instagram.profile' && !profile.value) startCheck = false;
        else if (profile.typeid === 'maltego.linkedin.profile' && !profile.value) startCheck = false;

        if (startCheck && !profile.childProfile && !profile.fields['user-details']) lowInfoProfiles.push(profile);
        if (startCheck && profile.childProfile && !profile.childProfile.fields['user-details']) lowInfoProfiles.push(profile.childProfile);
        if (startCheck && profile.parentProfile && !profile.parentProfile.fields['user-details']) lowInfoProfiles.push(profile.parentProfile);
      });
      return lowInfoProfiles;
    },
    moreInfoPrice () {
      return this.lowInfoProfiles.length * 5;
    }
  },
  methods: {
    ...mapActions(['setTaggedList', 'setAddTagged', 'setProfileInfo', 'setCurrentTagged']),

    openTags () {
      this.openTagsTooltip = true;
      this.setTaggedList();
    },
    async fetchTag (tag) {
      const info = {};
      if (tag) info.id = tag.oid;
      else if (this.getTaggedList.find(tag => tag.name === this.tagName)) {
        info.id = this.getTaggedList.find(tag => tag.name === this.tagName).oid;
      } else info.name = this.tagName;
      let profiles = this.profiles;
      if (this.profiles.oid) profiles = [this.profiles];
      const targets = [];
      profiles.forEach(profile => targets.push({ type: 'entity', id: profile.oid }));
      info.targets = targets;

      await this.setAddTagged(info);
      this.tagTitle = 'Saved in tag';
      this.tagName = '';
      this.tagInfo = null;
      this.openTagsTooltip = false;
    },
    addTag (tag) {
      if (tag) this.tagName = tag.name;
      if (this.tagName.length > 2) {
        if (this.lowInfoProfiles.length) {
          this.tagModal = true;
          this.tagInfo = tag;
        } else this.fetchTag(tag);
      }
    },
    saveAnyway () {
      this.tagModal = false;
      this.tagLoading = false;
      this.fetchTag(this.tagInfo);
    },
    async getMoreInfo () {
      this.tagLoading = true;
      await this.fetchTag(this.tagInfo);
      this.lowInfoProfiles.forEach(profile => {
        this.setProfileInfo(profile);
      });
    }
  }
};
</script>

<style lang="scss" scoped>
  .custom-scrollbar {
    max-height: 90px;
  }
</style>
