import router from '@/router/indexNew.js';

export default {
  state: {
    cases: [],
    casesLoaded: false,
    countCases: 0,
    caseToRemove: null,
    searches: [],
    connections: [],
    loaderBig: false
  },
  getters: {
    getAllCases (state) {
      return state.cases;
    },
    getCurrentCase (state) {
      return state.cases.find(c => c.oid === router.currentRoute.value.params.id);
    },
    getCountCases (state) {
      return state.countCases;
    },
    getSearches (state) {
      const caseID = router.currentRoute.value.params.id;
      if (caseID) return state.searches[caseID] || [];
      else return state.searches;
    },
    getCurrentSearches (state, getters) {
      return getters.getSearches.find(s => s.rid === router.currentRoute.value.params.searchId);
    },
    getConnections (state) {
      const caseID = router.currentRoute.value.params.id;
      if (caseID) return state.connections[caseID] || [];
      else return state.connections;
    },
    getCaseToRemove (state) {
      return state.caseToRemove;
    },
    getLoaderBig (state) {
      return state.loaderBig;
    }
  },
  mutations: {
    SET_ADD_NEW_CASE: (state, value) => { state.cases.push(value); },
    SET_ALL_CASES: (state, value) => { state.cases = value; },
    SET_CASES_LOADED: (state, bool) => { state.casesLoaded = bool; },
    SET_COUNT_CASES: (state, value) => { state.countCases = value; },
    SET_SEARCHES: (state, value) => { Object.assign(state.searches, value); },
    SET_CONNECTIONS: (state, value) => { Object.assign(state.connections, value); },
    ADD_SEARCH: (state, value) => { state.searches[value.fid].unshift(value); },
    SET_CASE_TO_REMOVE: (state, value) => { state.caseToRemove = value; },
    SET_REMOVE_CASE: (state, val) => {
      let targetCase = state.caseToRemove;
      if (val) targetCase = val;

      state.cases.forEach((inv, index) => {
        if (inv.oid === targetCase.oid) {
          state.cases.splice(index, 1);
        }
      });
      state.caseToRemove = null;
    },
    SET_EDIT_CASE: (state, value) => {
      const cases = state.cases.find(c => {
        return c.oid === value.fid;
      });
      cases.tag = value.tag;
    },
    SET_LOADER_BIG: (state, bool) => { state.loaderBig = bool; },
    SET_REMOVE_SEARCH: (state, value) => {
      const searches = state.searches[value.fid];
      searches.forEach((search, index) => {
        if (search.rid === value.rid) searches.splice(index, 1);
      });
    },
    SET_REMOVE_CONNECTION: (state, value) => {
      const connections = state.connections[value.case_id];
      connections.forEach((connection, index) => {
        if (connection.id === value.id) connections.splice(index, 1);
      });
    }
  },
  actions: {
    async setAddNewCase (ctx) {
      ctx.commit('SET_LOADER_BIG', true);
      const response = await ctx.dispatch('ajaxWithTokenRefresh', {
        method: 'POST',
        url: '/api/user/invins',
        body: {
          description: '',
          tag: 'My Case'
        }
      });
      ctx.commit('SET_ADD_NEW_CASE', response.data);
      router.push(`/new-search/${response.data.oid}`);
      ctx.commit('SET_LOADER_BIG', false);
    },
    async setAllCases ({ commit, state, dispatch }) {
      if (!state.casesLoaded) {
        const response = await dispatch('ajaxWithTokenRefresh', {
          method: 'GET',
          url: '/api/user/investigations'
        });
        commit('SET_ALL_CASES', response.data.items);
        commit('SET_COUNT_CASES', response.data.total);
        commit('SET_CASES_LOADED', true);
      }
    },
    async setCurrentCase ({ commit, state, dispatch }) {
      if (!state.casesLoaded) {
        const response = await dispatch('ajaxWithTokenRefresh', {
          method: 'GET',
          url: `/api/user/investigations/${router.currentRoute.value.params.id}`
        });
        commit('SET_ALL_CASES', [response.data]);
      }
    },
    async setCurrentSearches ({ commit, state, dispatch }, oid) {
      let caseID = router.currentRoute.value.params.id;
      if (oid) caseID = oid;

      if (!state.searches[caseID]) {
        const response = await dispatch('ajaxWithTokenRefresh', {
          method: 'POST',
          url: '/api/user/reclst',
          body: {
            fid: caseID
          }
        });
        const searches = {};
        searches[caseID] = response.data || [];
        commit('SET_SEARCHES', searches);
      }
    },
    async setCurrentConnections ({ commit, state, dispatch }, oid) {
      let caseID = router.currentRoute.value.params.id;
      if (oid && oid !== 'hard') caseID = oid;

      if (!state.connections[caseID] || oid === 'hard') {
        const response = await dispatch('ajaxWithTokenRefresh', {
          method: 'POST',
          url: '/api/user/relations/searches/list',
          body: {
            case_id: caseID
          }
        });
        const connections = {};
        connections[caseID] = response.data.Searches || [];
        commit('SET_CONNECTIONS', connections);
      }
    },
    async addSearchToCase ({ commit }, value) {
      commit('ADD_SEARCH', value);
    },
    async setCaseToRemove ({ commit, state, dispatch }, item) {
      if (item === null) {
        commit('SET_CASE_TO_REMOVE', null);
      } else
      if (item === undefined) {
        commit('SET_LOADER_BIG', true);
        await dispatch('ajaxWithTokenRefresh', {
          method: 'POST',
          url: '/api/user/invdel',
          body: {
            fid: state.caseToRemove.oid
          }
        });
        commit('SET_REMOVE_CASE');
        commit('SET_LOADER_BIG', false);
      } else {
        commit('SET_CASE_TO_REMOVE', item);
      }
    },
    async setEditCase ({ commit, state, dispatch }, caseInfo) {
      if (caseInfo.tag.trim() !== '' && caseInfo.tag.length >= 1 && caseInfo.tag !== caseInfo.oldTag) {
        await dispatch('ajaxWithTokenRefresh', {
          method: 'POST',
          url: '/api/user/invtag',
          body: {
            fid: caseInfo.fid,
            tag: caseInfo.tag
          }
        });
        commit('SET_EDIT_CASE', caseInfo);
      }
    },
    async setRemoveCase (ctx, targetCase) {
      await ctx.dispatch('ajaxWithTokenRefresh', {
        method: 'POST',
        url: '/api/user/invdel',
        body: {
          fid: targetCase.oid
        }
      });
      ctx.commit('SET_REMOVE_CASE', targetCase);
    },
    async setRemoveSearch (ctx, search) {
      await ctx.dispatch('ajaxWithTokenRefresh', {
        method: 'POST',
        url: '/api/user/recdel',
        body: {
          rid: search.rid
        }
      });
      await ctx.commit('SET_REMOVE_SEARCH', search);
    },
    async setRemoveConnection (ctx, connection) {
      await ctx.dispatch('ajaxWithTokenRefresh', {
        method: 'POST',
        url: '/api/user/relations/searches/delete',
        body: {
          id: connection.id
        }
      });
      await ctx.commit('SET_REMOVE_CONNECTION', connection);
    },
    setLoaderBig ({ commit, state }, bool) {
      commit('SET_LOADER_BIG', bool);
    }
  }
};
