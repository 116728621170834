<template>
  <div
    class="sidebar__header"
    :class="[$route.name === 'Case' || $route.name === 'Report' ? 'justify-between' : 'justify-center']"
  >
    <img src="@/assets/icons/short-logo.png" alt="">
    <Feature visible="VUE_APP_NSA_1566">
      <button
        id="main-back"
        class="typography-7"
        v-if="($route.name === 'Case' || $route.name === 'Report') && (this.openSearch || this.getAllInvestigationsCount > 0)"
        @click="this.openSearch ? $router.push('/') : emptyNewCaseWarning()">
        Back to ‘Cases’
      </button>
      <DeleteModal v-if="this.openDeleteModal.status" @closeModal="closeDeleteModal"/>
    </Feature>
    <Feature visible="!VUE_APP_NSA_1566">
      <button
        id="main-back"
        class="typography-7"
        v-if="$route.name === 'Case' || $route.name === 'Report'"
        @click="$router.push('/')">
        Back to ‘Cases’
      </button>
    </Feature>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Feature from '@/components/app/Feature';
import DeleteModal from '@/components/modals/DeleteModal';

export default {
  name: 'SidebarHeaderOld',
  components: {
    Feature,
    DeleteModal
  },
  data () {
    return {
      openDeleteModal: {
        status: false
      }
    };
  },
  computed: {
    ...mapGetters(['openSearch', 'getAllInvestigationsCount'])
  },
  methods: {
    emptyNewCaseWarning () {
      this.openDeleteModal.status = true;
    },
    closeDeleteModal () {
      this.openDeleteModal.status = false;
    }
  }
};
</script>
