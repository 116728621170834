<template>
  <header class="header-wrap">
    <div class="main-header">
      <a class="d-flex align-center" @click="$router.push(planLink)" id="main-to-plan">
        <span v-if="subscriptionName" class="d-flex align-center">
          <Icon name="flag" class="mr-16"/>
          Plan “{{ subscriptionName }}“ {{ subscriptionExpires }}
        </span>
      </a>
      <div class="d-flex align-center">
        <div class="d-flex align-center mr-28">
          <Icon name="wallet-old" class="mr-16"/>
          Balance: {{ balance }} slp
        </div>
        <LoginLinkOld />
      </div>
    </div>
  </header>
</template>

<script>
import Icon from '@/components/app/Icon';
import LoginLinkOld from '@/components/app/LoginLinkOld';
import { mapGetters } from 'vuex';

export default {
  components: {
    Icon,
    LoginLinkOld
  },
  computed: {
    ...mapGetters(['accountState']),
    balance () {
      return this.accountState.balance;
    },
    subscriptionName () {
      return this.accountState.subscription?.name;
    },
    subscriptionExpires () {
      const expires = this.accountState.subscription?.expires;
      if (expires) {
        const date = new Date(expires * 1000);
        const day = '0' + date.getDate();
        const month = '0' + (date.getMonth() + 1);
        const year = date.getFullYear();
        return `(Expires ${day.substr(-2)}/${month.substr(-2)}/${year})`;
      }
      return '';
    },
    planLink () {
      if (this.accountState.subscription.name === 'Trial') return '/paywall';
      else return '/plan';
    }
  }
};
</script>

<style lang="scss" scoped>
  #main-to-plan {
    &:hover {
      color: #D6E2F1;
      svg {
        path {
          fill: #D6E2F1;
        }
      }
    }
  }
</style>
