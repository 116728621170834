<template>
    <div class="p-absolute bottom--16px right--16px w-500px d-flex jc-space-between bg-white py-16 px-24 bs-controller b-all bc-form-border br-5">
      <div class="w-48px h-48px d-flex jc-center ai-center br-48" :class="this.error ? 'bg-warning' : 'bg-link'">
        <Icon class="w-34px h-32px color-white" color="#fff" :name="icon" />
      </div>
      <div>
        <h5 class="text-h5 cursor-default" :class="{ 'color-warning': this.error }" >{{ title }}</h5>
        <p class="text-base cursor-default" :class="{ 'color-warning': this.error }">{{ subtitle }}</p>
      </div>
      <Icon class="w-12px h-12px cursor-pointer" name="cancel-btn" @click="closeNotification" />
    </div>
</template>

<script>
import Icon from '@/components/app/Icon';

export default {
  name: 'Notification',
  components: {
    Icon
  },
  props: {
    title: {
      default: 'Some title is here'
    },
    subtitle: {
      default: 'Some subtitle will be here'
    },
    icon: {
      default: 'warning'
    },
    error: {
      default: false
    }
  },
  emits: ['closeNotification'],
  methods: {
    closeNotification () {
      this.$emit('closeNotification');
    }
  }
};
</script>
