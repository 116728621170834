import router from '@/router/indexNew.js';
import axios from 'axios';
import smartlookClient from 'smartlook-client';
import amplitude from 'amplitude-js';

export default {
  state: {
    resultsCancelToken: axios.CancelToken.source(),
    formData: {},
    transforms: [
      {
        template: 'facebook_search_profiles_detailed_onlyby_n2',
        name: 'fbDTCsearchPerson3',
        entityType: 'maltego.Person',
        searchType: 'name'
      },
      {
        template: 'instagram_search_person',
        name: 'instagramSearchPerson',
        entityType: 'maltego.Person',
        searchType: 'name'
      },
      {
        template: 'premium_search_people_linkedin',
        name: 'searchLinkedInPersonNew',
        entityType: 'maltego.Person',
        searchType: 'name'
      },
      {
        template: 'twitter_search_users',
        name: 'twitterSearchUsersFromPerson',
        entityType: 'maltego.Person',
        searchType: 'name'
      },
      {
        template: 'linkedin_lookup_by_email_v2',
        name: 'linkedinLookupByEmail2',
        entityType: 'maltego.EmailAddress',
        searchType: 'email'
      },
      {
        template: 'facebook_search_profiles_detailed_v2_graph',
        name: 'facebookSearchProfilesDetailed2graph',
        entityType: 'maltego.SearchPerson',
        searchType: 'name,education'
      },
      {
        template: 'facebook_search_profiles_detailed_v2_graph',
        name: 'facebookSearchProfilesDetailed2graph',
        entityType: 'maltego.SearchPerson',
        searchType: 'name,company'
      },
      {
        template: 'sm2_facebook_by_searchface',
        name: 'sm2FacebookBySearchFace',
        entityType: 'maltego.SearchFace',
        searchType: 'name,photo'
      },
      {
        template: 'sm2_instagram_by_searchface',
        name: 'sm2InstagramBySearchFace',
        entityType: 'maltego.SearchFace',
        searchType: 'name,photo'
      },
      {
        template: 'sm2_linkedin_by_searchface',
        name: 'sm2LinkedinBySearchFace',
        entityType: 'maltego.SearchFace',
        searchType: 'name,photo'
      },
      {
        template: 'sm2_twitter&name_field=fullname&photo_field=photo',
        name: 'sm2TwitterBySearchFace',
        entityType: 'maltego.SearchFace',
        searchType: 'name,photo'
      },
      {
        template: 'facebook_to_entity_v2&query_field_name=query',
        name: 'aliasToFacebook2',
        entityType: 'maltego.Alias',
        searchType: 'alias'
      },
      {
        template: 'instagram_search_person',
        name: 'instagramSearchUser',
        entityType: 'maltego.Alias',
        searchType: 'alias'
      },
      {
        template: 'twitter_alias_to_user',
        name: 'twitterSearchUsersFromAlias',
        entityType: 'maltego.Alias',
        searchType: 'alias'
      },
      {
        template: 'delayed_facebook_search_users',
        name: 'facebookSearchProfilesDetailedDelayedR',
        entityType: 'maltego.delayed.facebook.search.users',
        searchType: 'name,location'
      },
      {
        template: 'facebook_to_entity_v2&query_field_name=query',
        name: 'aliasToFacebook2',
        entityType: 'maltego.Alias',
        searchType: 'profile_url'
      },
      {
        template: 'alias_to_instagram',
        name: 'aliasToInstagram2',
        entityType: 'maltego.Alias',
        searchType: 'profile_url'
      },
      {
        template: 'twitter_alias_to_user',
        name: 'twitterSearchUsersFromAlias',
        entityType: 'maltego.Alias',
        searchType: 'profile_url'
      },
      {
        template: 'linkedin_profile_details&full=0',
        name: 'LinkedinProfileDetail',
        entityType: 'maltego.URL',
        searchType: 'profile_url'
      },
      {
        template: 'linkedin_profile_details&full=0',
        name: 'LinkedinProfileDetail',
        entityType: 'maltego.URL',
        searchType: 'profile_id'
      },
      {
        template: 'facebook_to_entity_v2',
        name: 'fromFBidToEntityV2',
        entityType: 'maltego.facebook.id',
        searchType: 'profile_id'
      },
      {
        template: 'gamayun_twitter_id_to_user',
        name: 'gamayun_twitter_id_to_user',
        entityType: 'maltego.affiliation.Twitter',
        searchType: 'profile_id'
      },
      {
        template: 'gamayun_instagram_id_to_user',
        name: 'gamayun_instagram_id_to_user',
        entityType: 'maltego.instagram.profile',
        searchType: 'profile_id'
      }
    ],
    SLDBTransforms: [
      {
        template: 'webdata_by_new&type=phone',
        name: 'phoneToSldb',
        entityType: 'maltego.PhoneNumber',
        searchType: 'phone'
      },
      {
        template: 'webdata_by_new&type=email',
        name: 'emailToSldb',
        entityType: 'maltego.EmailAddress',
        searchType: 'email'
      }
    ]
  },

  actions: {
    async getSearchTypes (ctx, input) {
      const searchBy = [];
      const result = {};
      input.forEach(name => { result[name] = name; });

      if (result.name) searchBy.push('name');
      if (result.alias) searchBy.push('alias');
      if (result.email) searchBy.push('email');
      if (result.phone) searchBy.push('phone');
      if (result.profileUrl) searchBy.push('profile_url');
      if (result.profileId) searchBy.push('profile_id');
      if (result.name && (result.photo || result.photoUrl)) searchBy.push('name,photo');
      if (result.name && result.location) searchBy.push('name,location');
      if (result.name && result.education) searchBy.push('name,education');
      if (result.name && result.company) searchBy.push('name,company');

      return searchBy;
    },

    async selectTransforms (ctx, info) {
      const sldb = info.sldb;
      const transforms = sldb ? ctx.state.SLDBTransforms : ctx.state.transforms;
      const selectedTransforms = {};

      for (const searchType of info.searchTypes) {
        selectedTransforms[searchType] = {};
        if (searchType === 'profile_url') {
          const currentProfileUrl = await ctx.dispatch('getTransformNameByProfileUrl');
          selectedTransforms[searchType].transforms = transforms.filter(tr => {
            return tr.searchType === searchType && tr.name === currentProfileUrl;
          });
        } else {
          selectedTransforms[searchType].transforms = transforms.filter(tr => tr.searchType === searchType);
        }
      }

      return selectedTransforms;
    },

    getTransformNameByProfileUrl (ctx, formData) {
      const profileUrl = ctx.state.formData.profileUrl;

      if (/^https?:\/\/(www\.)?instagram.com\/[a-zA-Z0-9._]{1,30}\/?$/.test(profileUrl)) {
        return 'aliasToInstagram2';
      }
      if (/^https?:\/\/(www\.)?twitter.com\/[a-zA-Z0-9_]{4,15}\/?$/.test(profileUrl)) {
        return 'twitterSearchUsersFromAlias';
      }
      if (/^https?:\/\/(www\.)?facebook.com\/profile\.php\?id=[0-9]+$/.test(profileUrl)) {
        return 'aliasToFacebook2';
      }
      if (/^https?:\/\/(www\.)?facebook.com\/[a-zA-Z0-9.]{5,50}\/?$/.test(profileUrl)) {
        return 'aliasToFacebook2';
      }
      if (/^https?:\/\/(www\.)?linkedin.com\/in\/[a-zA-Z0-9-_]+\/?$/.test(profileUrl)) {
        return 'LinkedinProfileDetail';
      }
      return '';
    },

    getLinkedInProfileByEmail (ctx, resultsArr) {
      const linkedInProfiles = resultsArr.filter(ent => ent.typeid === 'maltego.linkedin.profile');
      return linkedInProfiles.find(ent => {
        const searchType = ent.fields.find(fld => {
          return fld.name === 'search-type';
        });
        return searchType && searchType.value === 'email';
      });
    },

    async uploadPhoto (ctx, value) {
      if (typeof (value.photo) === 'string') {
        return {
          label: 'photo',
          name: 'photo',
          rule: null,
          value: value.photo
        };
      }
      const fd = new FormData();
      fd.append('image', value.photo, value.photo.name);

      const data = {
        filename: value.photo.name,
        filesize: value.photo.size,
        filetype: value.photo.type,
        modifytime: value.photo.lastModified,
        fullname: value.name,
        uploadtime: Date.now()
      };

      fd.append('data', JSON.stringify(data));
      fd.append('skip_ml', true);

      try {
        const payload = {
          method: 'POST',
          url: '/api/v2/photos',
          body: fd
        };
        const resp = await ctx.dispatch('ajaxWithTokenRefresh', payload);

        if (resp.status === 201) {
          const photo = resp.data;
          return {
            label: 'photo',
            name: 'photo',
            rule: null,
            value: photo.url
          };
        }
      } catch (e) {
        console.error(e);
      }
    },

    async setSearchEntities (ctx, transforms) {
      for (const searchType of Object.keys(transforms)) {
        const formData = ctx.state.formData;
        const trs = transforms[searchType];
        const entity = { typeid: '', value: '', weight: 100, fields: [] };
        entity.fields.push({ label: 'search-type', name: 'search-type', rule: null, value: searchType });
        if (searchType === 'name') {
          entity.typeid = 'maltego.Person';
          entity.value = formData.name;
          entity.fields.push({ label: searchType, name: searchType, rule: null, value: formData.name });
        } else if (searchType === 'alias') {
          entity.typeid = 'maltego.Alias';
          entity.value = formData.alias;
          entity.fields.push({ label: searchType, name: searchType, rule: null, value: formData.alias });
        } else if (searchType === 'email') {
          entity.typeid = 'maltego.EmailAddress';
          entity.value = formData.email;
          entity.fields.push({ label: searchType, name: searchType, rule: null, value: formData.email });
        } else if (searchType === 'phone') {
          entity.typeid = 'maltego.PhoneNumber';
          entity.value = formData.phone;
          entity.fields.push({ label: searchType, name: searchType, rule: null, value: formData.phone });
        } else if (searchType === 'name,photo') {
          entity.typeid = 'maltego.SearchFace';
          entity.value = formData.name;
          let image;
          if (formData.photo) image = await ctx.dispatch('uploadPhoto', { name: formData.name, photo: formData.photo });
          if (formData.photoUrl) image = { label: 'photo', name: 'photo', rule: null, value: formData.photoUrl };

          entity.fields.push(image);
        } else if (searchType === 'name,location') {
          entity.typeid = 'maltego.delayed.facebook.search.users';
          entity.value = formData.name;
          entity.fields.push({ label: 'location', name: 'location', rule: null, value: formData.location.split(',')[0] });
          entity.fields.push({ label: 'fullname', name: 'fullname', rule: null, value: formData.name });
        } else if (searchType === 'name,education') {
          entity.typeid = 'maltego.SearchPerson';
          entity.value = formData.name;
          entity.fields.push({ label: 'education', name: 'education', rule: null, value: formData.education });
        } else if (searchType === 'name,company') {
          entity.typeid = 'maltego.SearchPerson';
          entity.value = formData.name;
          entity.fields.push({ label: 'company', name: 'company', rule: null, value: formData.company });
        } else if (searchType === 'profile_url') {
          entity.typeid = 'maltego.Alias';
          const regexResults = /^https?:\/\/(www\.)?twitter.com\/(?<username>[a-zA-Z0-9_]{4,15})\/?$/.exec(formData.profileUrl);
          entity.value = regexResults?.groups?.username || formData.profileUrl;
          entity.fields.push({ label: searchType, name: searchType, rule: null, value: formData.profileUrl });
        } else if (searchType === 'profile_id') {
          entity.typeid = 'maltego.URL';
          entity.value = formData.profileId;
          entity.fields.push({ label: searchType, name: searchType, rule: null, value: formData.profileId });
        }
        trs.entity = entity;
      }
    },

    async runSLDBTransforms (ctx, transforms) {
      const sldbTransforms = Object.keys(transforms).reduce((prevSLDBTransforms, searchType) => {
        return [...prevSLDBTransforms, ...transforms[searchType].transforms];
      }, []);
      const allSLDBResults = [];
      return Promise.all(sldbTransforms.map(async tr => {
        const entityType = tr.entityType;
        const entity = {
          typeid: entityType,
          value: (entityType === 'maltego.PhoneNumber') ? ctx.state.formData.phone : ctx.state.formData.email,
          weight: 100,
          fields: []
        };
        const transformData = {
          entity,
          limit: 256,
          entityType,
          additionalFields: [],
          weight: 100
        };
        const results = await ctx.dispatch('runTransform', {
          url: '/endpoint.php?template=' + tr.template,
          transformName: tr.name,
          xml: await ctx.dispatch('createXML', transformData)
        });
        results.forEach(res => { res.search_type = tr.searchType; });
        allSLDBResults.push(...results);
      })).then(() => { return allSLDBResults; });
    },

    updateTransformsBySLDBResults (ctx, info) { // allResults, SLDBResults, transforms
      Object.keys(info.SLDBTransforms).forEach(searchType => { info.SLDBTransforms[searchType].transforms = []; });
      const linkedInProfileByEmail = ctx.dispatch('getLinkedInProfileByEmail', info.results);
      const searchByPhone = {};
      const searchByEmail = {};
      for (const entity of info.SLDBResults) {
        const map = (entity.search_type === 'phone') ? searchByPhone : searchByEmail;
        map.searchType = entity.search_type;
        const facebook = entity.fields.find(field => field.name === 'facebook_id');
        if (facebook) map.facebookID = facebook.value;
        const twitter = entity.fields.find(field => field.name === 'twitter_alias');
        if (twitter) map.twitterAlias = twitter.value;
        const linkedin = entity.fields.find(field => field.name === 'linkedin_alias');
        if (linkedin && (entity.search_type === 'phone' || !linkedInProfileByEmail)) map.linkedinAlias = linkedin.value;
        const instagram = entity.fields.find(field => field.name === 'instagram_alias');
        if (instagram) map.instagramAlias = instagram.value;
      }

      [searchByPhone, searchByEmail].forEach(map => {
        Object.keys(map).forEach(socialNet => {
          const searchType = map.searchType;
          const transform = {
            entityType: 'maltego.Alias',
            searchQuery: map[socialNet],
            searchType
          };
          if (socialNet === 'facebookID') {
            transform.entityType = 'maltego.facebook.id';
            transform.name = 'fromFBidToEntityV2';
            transform.template = 'facebook_to_entity_v2';
            info.SLDBTransforms[map.searchType].transforms.push(transform);
          } else if (socialNet === 'twitterAlias') {
            transform.name = 'twitterAliasToUserV2';
            transform.template = 'twitter_alias_to_user';
            info.SLDBTransforms[map.searchType].transforms.push(transform);
          } else if (socialNet === 'linkedinAlias') {
            transform.name = 'LinkedinAliasToEntities';
            transform.template = 'linkedin_alias_to_entities';
            info.SLDBTransforms[map.searchType].transforms.push(transform);
          } else if (socialNet === 'instagramAlias') {
            transform.name = 'aliasToInstagram2';
            transform.template = 'alias_to_instagram';
            info.SLDBTransforms[map.searchType].transforms.push(transform);
          }
        });
      });
    },

    async setCreateNewSearch ({ state, dispatch, commit }, transforms) {
      try {
        const searchGroups = Object.keys(transforms);
        const payload = {
          method: 'POST',
          url: '/api/user/recnew',
          cancelToken: state.resultsCancelToken.token,
          body: {
            fid: router.currentRoute.value.params.id,
            tag: '',
            query: {}
          }
        };
        searchGroups.forEach(searchType => {
          const entity = transforms[searchType].entity;
          entity.fields.forEach(field => {
            if (!field) return;
            if (field.label === 'name') {
              payload.body.query.name = field.value;
            } else if (field.label === 'alias') {
              payload.body.query.alias = field.value;
            } else if (field.label === 'photo') {
              payload.body.query.image = field.value;
            } else if (field.label === 'location') {
              payload.body.query.location = field.value;
            } else if (field.label === 'phone') {
              payload.body.query.phone = field.value;
            } else if (field.label === 'email') {
              payload.body.query.email = field.value;
            } else if (field.label === 'education') {
              payload.body.query.education = field.value;
            } else if (field.label === 'company') {
              payload.body.query.company = field.value;
            } else if (field.label === 'profile_url') {
              payload.body.query.profile_url = field.value;
            } else if (field.label === 'profile_id') {
              payload.body.query.profile_id = field.value;
            }
          });
        });
        Object.keys(payload.body.query).forEach((queryName, idx, arr) => {
          const splitter = (idx === arr.length - 1) ? '' : ' ';
          const fieldName = queryName.charAt(0).toUpperCase() + queryName.slice(1);
          payload.body.tag += `${fieldName}: ${payload.body.query[queryName]}${splitter}`;
        });

        const resp = await dispatch('ajaxWithTokenRefresh', payload);
        if (resp.status === 200) {
          return resp.data;
        }
      } catch (e) {
        console.error(e);
      }
    },

    async setNewSearch (ctx, formData) {
      ctx.commit('SET_LOADER_BIG', true);
      const event = 'profile_search';
      let totalPrice = 0;
      const eventProperties = { input: [] };
      formData.forEach(option => {
        eventProperties.input.push(option.type);
        totalPrice += option.price;
        ctx.state.formData[option.type] = option.title || option.image;
      });
      eventProperties.slp = totalPrice;
      const userProperties = {
        subscription: ctx.getters.accountState.subscription.name,
        company: ctx.getters.accountState.company,
        role: ctx.getters.accountState.role
      };

      amplitude.getInstance().setUserProperties(userProperties);
      amplitude.getInstance().logEvent(event, eventProperties);

      if (ctx.getters.accountState.balance < 12) {
        // event about buy more SLP
        return;
      }

      const smartLookEventProperties = {};

      formData.forEach(option => {
        if (option.type === 'name') smartLookEventProperties.search_name = option.title;
        if (option.type === 'location') smartLookEventProperties.search_location = option.title;
        if (option.type === 'education') smartLookEventProperties.search_education = option.title;
        if (option.type === 'company') smartLookEventProperties.search_company = option.title;
        if (option.type === 'photo') smartLookEventProperties.search_photo = option.title;
        if (option.type === 'photoUrl') smartLookEventProperties.search_photo_url = option.title;
        if (option.type === 'profileUrl') smartLookEventProperties.search_profile_url = option.title;
        if (option.type === 'profileId') smartLookEventProperties.search_profile_id = option.title;
        if (option.type === 'phone') smartLookEventProperties.search_phone = option.title;
        if (option.type === 'email') smartLookEventProperties.search_email = option.title;
      });

      smartlookClient.track('start_live_search', smartLookEventProperties);
      smartlookClient.track('spent_slp', { where: 'live_search', quantity: totalPrice });

      ctx.commit('updateResultsCancelToken');
      ctx.commit('clearCurrentSearchResults');

      const searchTypes = await ctx.dispatch('getSearchTypes', eventProperties.input);
      const transforms = await ctx.dispatch('selectTransforms', { searchTypes, sldb: false });

      await ctx.dispatch('setSearchEntities', transforms);

      try {
        const search = await ctx.dispatch('setCreateNewSearch', transforms);
        ctx.dispatch('addSearchToCase', search);
        search.nodes = [];
        ctx.commit('openedSearch', search);
        ctx.commit('setSearchID', search.rid);
        ctx.commit('addToLoadedSearches', search.rid);
        const entities = Object.keys(transforms).map(searchType => transforms[searchType].entity);
        await ctx.dispatch('createEntities', { search, entities });
        const results = await ctx.dispatch('fetchResults', { transforms, entities });
        if (ctx.state.formData.phone || ctx.state.formData.email) {
          const searchTypes = [];
          if (ctx.state.formData.phone) searchTypes.push('phone');
          if (ctx.state.formData.email) searchTypes.push('email');
          const SLDBTransforms = await ctx.dispatch('selectTransforms', { searchTypes, sldb: true });
          await ctx.dispatch('setSearchEntities', SLDBTransforms);
          const entities = Object.keys(SLDBTransforms).map(searchType => SLDBTransforms[searchType].entity);
          await ctx.dispatch('createEntities', { search, entities });
          const SLDBResults = await ctx.dispatch('runSLDBTransforms', SLDBTransforms);
          ctx.dispatch('updateTransformsBySLDBResults', { results, SLDBResults, SLDBTransforms });
          const additionalResults = await ctx.dispatch('fetchResults', { transforms: SLDBTransforms, entities });
          results.push(...additionalResults);
        }

        if (!results.length) {
          ctx.dispatch('addZeroResultsUrlParameter');
        }

        await ctx.dispatch('saveProfiles', { search, entities: results });
        const profiles = await ctx.dispatch('convertEntityFieldsToMap', results);
        ctx.commit('pushResults', profiles);
        ctx.commit('pushToCurrentSearchResults', profiles);
        ctx.commit('updateResultsMap', profiles);
        ctx.commit('SET_LOADER_BIG', false);
        router.push({ path: `/case/${search.fid}/${search.rid}` });
      } catch (e) {
        console.error(e);
        ctx.commit('SET_LOADER_BIG', false);
      }
    }
  }
};
