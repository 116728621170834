<template>
  <div class="p-relative">
    <a
      id="header-menu-plan"
      @click.prevent="dropdown = !dropdown"
      class="d-flex align-center px-16 h-48px br-40"
      :class="{ 'bg-white color-link bs-light' : !dropdown, 'bg-link bs-none color-white' : dropdown }"
    >
      <Icon name="wallet" />
      <span class="fs-13 fw-600 ml-10">{{ balance }} SLP</span>
    </a>

    <div
      class="p-absolute min-w top-100 left-0 z-101 mt-24"
      v-if="dropdown"
    >
      <div class="p-absolute left-0 bottom-100 ml-12">
        <img class="d-block" src="@/assets/icons/tooltip-arrow-white.svg" />
      </div>
      <div class="relative bs-default bg-white fs-14 br-8 p-16">
        <div class="fs-16 fw-600 color-text-darker lh-24">Plan "{{ subscriptionName }}"</div>
        <div class="fs-14 lh-24 mb-2">{{ subscriptionExpires }}</div>
        <div>
          <a
            id="main-to-plan"
            @click.prevent="clickMenu(planLink)"
            class="fs-13 lh-16 fw-600 link-blue"
          >Go to plan page</a>
        </div>
      </div>
    </div>
    <div
      class="p-fixed pos-all-0 z-100"
      v-if="dropdown" @click="dropdown = !dropdown"
    ></div>
  </div>

</template>

<script>
import Icon from '@/components/app/Icon';
import { mapGetters } from 'vuex';

export default {
  data () {
    return {
      dropdown: false
    };
  },
  components: {
    Icon
  },
  computed: {
    ...mapGetters(['accountState']),
    balance () {
      return this.accountState.balance;
    },
    subscriptionName () {
      return this.accountState.subscription?.name;
    },
    subscriptionExpires () {
      const expires = this.accountState.subscription?.expires;
      if (expires) {
        const date = new Date(expires * 1000);
        const day = '0' + date.getDate();
        const month = '0' + (date.getMonth() + 1);
        const year = date.getFullYear();
        return `Expires ${day.substr(-2)}/${month.substr(-2)}/${year}`;
      }
      return '';
    },
    planLink () {
      if (this.accountState.subscription.name === 'Trial') return '/paywall';
      else return '/plan';
    }
  },
  methods: {
    clickMenu (url) {
      this.dropdown = false;
      this.$router.push({ path: url });
    }
  }
};
</script>

<style lang="scss" scoped>
  .min-w {
    min-width: 180px;
  }
</style>
