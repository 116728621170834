export default {
  state: {
    multiprofiles: [],
    opened: false,
    currentMultiprofile: null
  },
  mutations: {
    addMultiprofiles (state, multiprofile) {
      state.multiprofiles.push(multiprofile);
    },
    removeMultiprofile (state, id) {
      state.multiprofiles = state.multiprofiles.filter(item => item.id !== id);
    },
    openMultiprofile (state, value) {
      state.opened = value;
    },
    checkMultiprofile (state, obj) {
      const multiprofile = state.multiprofiles.find(multiprofile => {
        return multiprofile.id === obj.id;
      });
      multiprofile.checked = obj.check;
    },
    setCurrentMultiprofile (state, value) {
      state.currentMultiprofile = value;
    },
    uncheckMultiProfiles (state) {
      state.multiprofiles.forEach(multiprofile => {
        multiprofile.checked = false;
      });
    },
    clearMultiprofiles (state) {
      state.multiprofiles = [];
      state.currentMultiprofile = null;
    },
    setMultiprofileScore (state, val) {
      const multiprofile = state.multiprofiles.find(mp => {
        return val.mp === mp.id;
      });
      multiprofile.scoreToShow = val.score;
    }
  },
  getters: {
    multiprofiles (state) {
      return state.multiprofiles;
    },
    currentMultiprofile (state) {
      return state.currentMultiprofile;
    },
    profilesOfCurrentMultiprofile (state, rootState) {
      const profiles = [];
      state.currentMultiprofile.entity_ids.forEach(id => {
        for (const key in rootState.resultsMap) {
          if (Object.prototype.hasOwnProperty.call(rootState.resultsMap, key) && key === id) {
            profiles.push(rootState.resultsMap[key]);
          }
        }
      });
      return profiles;
    }
  },
  actions: {
    async getMultiprofilesWithData (ctx, id) {
      try {
        ctx.commit('clearMultiprofiles');
        const payload = {
          method: 'POST',
          url: '/api/user/multiprofiles/list',
          body: {
            case_id: id
          }
        };
        const multiprofiles = await ctx.dispatch('ajaxWithTokenRefresh', payload);
        for (const multiprofile of multiprofiles.data) {
          multiprofile.checked = false;
          const results = await ctx.dispatch('convertEntityFieldsToMap', multiprofile.entities);
          results.forEach(res => {
            res.checked = false;
            ctx.commit('addProfileToResultsMap', res);
          });
          ctx.commit('addResultsFromTags', results);
          ctx.commit('addMultiprofiles', multiprofile);
        }
      } catch (error) {
        console.error(error);
      }
    },
    async openMultiprofile ({ commit, dispatch, getters }, currentMultiprofile) {
      commit('setSearching', true);
      commit('activateRelationsSection', false);
      commit('openMultiprofile', true);
      commit('openResults', false);
      commit('openSavedInTagsPage', false);
      commit('openConnections', false);
      commit('setOpenFastReport', false);
      commit('setCurrentMultiprofile', currentMultiprofile);
      commit('clearConnections', false);
      commit('clearEdges', false);
      const promises = [];
      const set = new Set();
      getters.profilesOfCurrentMultiprofile.forEach(profile => set.add(profile.oid));
      const setOfLoadedRelations = new Set();
      getters.sortedRelationSearches.forEach(relation => {
        relation.targets.forEach(target => {
          if (set.has(target)) {
            if (setOfLoadedRelations.has(target)) return;
            setOfLoadedRelations.add(target);
            promises.push(dispatch('loadConnections', relation));
          }
        });
      });
      Promise.all(promises).then(() => {
        commit('setSearching', false);
        commit('uncheckAllResults');
      });
    }
  }
};
