<template>
  <div class="p-48">
    <h4 class="text-h4">Follow the link</h4>
    <p class="text-h5 color-text mt-4">You received at {{ email }}</p>
    <p class="text-base mt-16">If you haven't received the letter be sure you've entered the correct email, your inbox isn't full, and your mail server's available to receive letters. If you face any troubles to get an email, please contact us via form or chat.</p>

    <div class="d-flex jc-space-between mt-12">
      <div class="text-base">Did not get the link?</div>
      <div class="text-link cursor-pointer" @click="sendAgain">Send again</div>
    </div>

    <div class="d-flex jc-space-between mt-16">
      <button class="br-5 bw-2 b-style-solid py-10 px-16 fw-600 fs-13 lh-16 let-space-08 transition w-260px color-link-darker bc-link-darker bg-link-darker_hover color-white_hover jc-center" @click="this.$emit('closeEmail')">
        <Icon name="arrow-left" />
        &nbsp;&nbsp;Back
      </button>
    </div>

    <div v-if="notification" class="d-flex jc-space-between mt-24 p-16" :class="error ? 'color-warning input-error' : 'color-link-darker input-info'">
      <div>
        <h5 class="text-h5 text-initial" :class="error ? 'color-warning' : 'color-link'">{{ notificationTitle }}</h5>
        <p class="text-base text-initial fw-400 pt-8" :class="{'color-warning': error}">{{ notificationText }}</p>
      </div>
      <Icon class="w-12px h-12px cursor-pointer" :color="error ? '#FF7A7A' : '#51AEE2'" name="cancel-btn" @click="notification = false" />
    </div>
  </div>
</template>

<script>
import Icon from '@/components/app/Icon.vue';

export default ({
  name: 'Email',
  props: ['email'],
  emits: ['closeEmail'],
  components: {
    Icon
  },
  data () {
    return {
      notification: true,
      notificationTitle: 'Please confirm email',
      notificationText: "We've updated your profile information with the exception of your email.",
      error: false
    };
  },
  methods: {
    async sendAgain () {
      const payload = {
        method: 'PATCH',
        url: '/api/v3/account/update',
        body: {
          email: this.email
        }
      };
      try {
        const response = await this.$store.dispatch('ajaxWithTokenRefresh', payload);
        this.notificationTitle = 'Please confirm email';
        this.notificationText = "We've updated your profile information with the exception of your email.";
        this.notification = true;
        console.log(response);
      } catch (error) {
        this.notificationTitle = 'Something went wrong';
        this.notificationText = 'Contact support team for help';
        this.notification = true;
        this.error = true;
      }
    }
  }
});
</script>
