export async function sortDataFromUserDetailsFb (data) {
  const targetObj = {};
  targetObj.Bio = [];
  targetObj.Contacts = [];
  targetObj.Lifestyle = [];
  targetObj.Profile = [];
  targetObj['Life events'] = [];
  targetObj.Relatives = [];
  targetObj['Social events'] = [];
  const set = new Set();
  for (const key of Object.keys(data)) {
    if ((data[key] && typeof (data[key]) !== 'object') || (data[key] && data[key].length) || data[key] === false) {
      switch (key) {
        case 'about':
          targetObj.Bio.push({ About: data[key] });
          break;
        case 'age':
          targetObj.Bio.push({ Age: data[key] });
          break;
        case 'birth_day':
          targetObj.Bio.push({ Birthday: data[key] });
          break;
        case 'gender':
          targetObj.Bio.push({ Gender: data[key] });
          break;
        case 'name':
          targetObj.Bio.push({ Name: data[key] });
          break;
        case 'other_names':
          if (data[key][0].name) {
            const arr = [];
            data[key].forEach(obj => arr.push(obj.name));
            targetObj.Bio.push({ 'Other names': arr });
          } else {
            targetObj.Bio.push({ 'Other names': data[key] });
          }
          break;
        case 'headline':
          targetObj.Bio.push({ 'Profile headline': data[key] });
          break;

        case 'address':
          targetObj.Contacts.push({ Address: data[key] });
          break;
        case 'alias':
          targetObj.Contacts.push({ 'Username (alias)': data[key] });
          break;
        case 'email':
          targetObj.Contacts.push({ Email: data[key] });
          break;
        case 'phones':
          targetObj.Contacts.push({ Phones: data[key] });
          break;
        case 'phones_in_whatsapp':
          targetObj.Contacts.push({ WhatsApp: data[key] });
          break;

        case 'interested_in':
          targetObj.Lifestyle.push({ 'Interested in': data[key] });
          break;
        case 'languages':
          targetObj.Lifestyle.push({ Languages: data[key] });
          break;
        case 'hobbies':
          targetObj.Lifestyle.push({ Hobbies: data[key] });
          break;
        case 'political_views':
          targetObj.Lifestyle.push({ 'Political Views': data[key] });
          break;
        case 'quotes':
          targetObj.Lifestyle.push({ Quotes: data[key] });
          break;
        case 'religious_views':
          targetObj.Lifestyle.push({ 'Religion views': data[key] });
          break;
        case 'skills':
          targetObj.Lifestyle.push({ Skills: data[key] });
          break;

        case 'followed_count':
          targetObj.Profile.push({ Friends: data[key] });
          break;
        case 'id':
          targetObj.Profile.push({ 'Facebook ID': data[key] });
          break;
        case 'joined':
          targetObj.Profile.push({ 'Joined since': data[key] });
          break;
        case 'locked':
          targetObj.Profile.push({ 'Locked profile': data[key] });
          break;

        case 'life_events':
          data[key].forEach((item, index) => {
            targetObj['Life events'].push({ [item.year]: data[key][index].event });
          });
          break;

        case 'relatives':
          data[key].forEach(item => {
            targetObj.Relatives.push({ [item.relation]: `<a href="${item.url}" target="_blank" class="color-theme">` + item.name + ' (id ' + item.id + ') </a>' });
          });
          break;

        case 'family_status':
          data[key].forEach(item => {
            if (item.name || item.user) {
              const withUrl = `<a href="${item.url}" target="_blank" class="color-theme">` + item.name || item.user + ' (id ' + item.id + ') </a>';
              const withoutUrl = item.name || item.user + ' (id ' + item.id + ')';
              targetObj.Relatives.push({ [item.rel || item.relation]: item.url ? withUrl : withoutUrl });
            }
          });
          break;

        case 'social':
          data[key].forEach(item => {
            if (item.value === 'Works' || item.value === 'Studied') {
              const description = item.info.description ? '<br>' + item.info.description : '';
              targetObj['Social events'].push({ [item.info.date || 'No Date']: item.value + ' at ' + item.info.name + description });
            }
            if (item.value === 'Lives' || item.value === 'From') {
              targetObj.Bio.push({ [item.value_plain]: item.info.name });
            }
          });
          break;

        case 'social_links':
          data[key].forEach(item => {
            if (!set.has(item.type)) {
              targetObj.Contacts.push({ [item.type]: `<a href="${item.url}" target="_blank" class="color-theme">${item.alias}</a>` });
            }
            set.add(item.type);
          });
          break;
      }
    }
  }
  if (!targetObj.Bio.length) {
    delete targetObj.Bio;
  }
  if (!targetObj.Contacts.length) {
    delete targetObj.Contacts;
  }
  if (!targetObj.Lifestyle.length) {
    delete targetObj.Lifestyle;
  }
  if (!targetObj.Profile.length) {
    delete targetObj.Profile;
  }
  if (!targetObj['Life events'].length) {
    delete targetObj['Life events'];
  }
  if (!targetObj.Relatives.length) {
    delete targetObj.Relatives;
  }
  if (!targetObj['Social events'].length) {
    delete targetObj['Social events'];
  }
  return targetObj;
}
export async function sortDataFromUserDetailsTw (data) {
  const targetObj = {};
  targetObj.Bio = [];
  targetObj.Profile = [];
  for (const key of Object.keys(data)) {
    if ((data[key] && typeof (data[key]) !== 'object') || (data[key] && data[key].length) || data[key] === false) {
      switch (key) {
        case 'name':
          targetObj.Bio.push({ Name: data[key] });
          break;
        case 'screen_name':
          targetObj.Bio.push({ 'Username (alias)': data[key] });
          break;
        case 'location':
          targetObj.Bio.push({ Location: data[key] });
          break;
        case 'description':
          targetObj.Bio.push({ About: data[key] });
          break;
        case 'created_at':
          targetObj.Bio.push({ 'Joined since': data[key] });
          break;

        case 'listed_count':
          targetObj.Profile.push({ 'Listed count': data[key] });
          break;
        case 'advertiser_account_service_levels':
          targetObj.Profile.push({ 'Account level': data[key] });
          break;
        case 'advertiser_account_type':
          targetObj.Profile.push({ 'Account type': data[key] });
          break;
        case 'business_profile_state':
          targetObj.Profile.push({ 'Business profile': data[key] });
          break;
        case 'favourites_count':
          targetObj.Profile.push({ Favorites: data[key] });
          break;
        case 'followers_count':
          targetObj.Profile.push({ Followers: data[key] });
          break;
        case 'friends_count':
          targetObj.Profile.push({ 'Followeing by': data[key] });
          break;
      }
    }
  }
  if (!targetObj.Bio.length) {
    delete targetObj.Bio;
  }
  if (!targetObj.Profile.length) {
    delete targetObj.Profile;
  }
  return targetObj;
}
export async function sortDataFromUserDetailsIn (data) {
  const targetObj = {};
  targetObj.Bio = [];
  for (const key of Object.keys(data)) {
    if ((data[key] && typeof (data[key]) !== 'object') || (data[key] && data[key].length) || data[key] === false) {
      switch (key) {
        case 'name':
          targetObj.Bio.push({ Name: data[key] });
          break;
        case 'alias':
          targetObj.Bio.push({ 'Username (alias)': data[key] });
          break;
        case 'followers_count':
          targetObj.Bio.push({ Followers: data[key] });
          break;
        case 'following_count':
          targetObj.Bio.push({ 'Following by': data[key] });
          break;
        case 'id':
          targetObj.Bio.push({ ID: data[key] });
          break;
        case 'photos_count':
          targetObj.Bio.push({ 'Photos count': data[key] });
          break;
      }
    }
  }
  if (!targetObj.Bio.length) {
    delete targetObj.Bio;
  }
  return targetObj;
}
export async function sortDataFromUserDetailsLn (data) {
  const targetObj = {};
  targetObj.Bio = [];
  targetObj.Contacts = [];
  targetObj.Profile = [];
  targetObj.Education = [];
  targetObj.Experience = [];
  targetObj['Volunteer expirience'] = [];
  targetObj.Certificates = [];
  targetObj.Links = [];
  targetObj.Projects = [];
  const arr = [];
  for (const key of Object.keys(data)) {
    if ((data[key] && typeof (data[key]) !== 'object') || (data[key] && data[key].length) || data[key] === false) {
      switch (key) {
        case 'first_name':
          targetObj.Bio.push({ Name: data[key] });
          break;
        case 'alias':
          targetObj.Bio.push({ 'Username (alias)': data[key] });
          break;
        case 'birthday':
          targetObj.Bio.push({ Birthday: data[key] });
          break;
        case 'industry':
          targetObj.Bio.push({ Industry: data[key] });
          break;
        case 'skills':
          targetObj.Bio.push({ Skills: data[key] });
          break;
        case 'languages':
          data[key].forEach(item => {
            arr.push(item.name);
          });
          targetObj.Bio.push({ Languages: arr });
          break;

        case 'address':
          targetObj.Contacts.push({ Address: data[key] });
          break;
        case 'country_code':
          targetObj.Contacts.push({ 'Country code': data[key] });
          break;
        case 'email':
          targetObj.Contacts.push({ Email: data[key] });
          break;
        case 'location':
          targetObj.Contacts.push({ Location: data[key] });
          break;
        case 'phone_numbers':
          targetObj.Contacts.push({ Phones: data[key] });
          break;
        case 'postal_codeil':
          targetObj.Contacts.push({ 'Postal code': data[key] });
          break;
        case 'twitter_aliases':
          targetObj.Contacts.push({ 'Twitter alias': data[key] });
          break;
        case 'we_chat_contact_info':
          targetObj.Contacts.push({ 'We chat': data[key] });
          break;

        case 'connections_count':
          targetObj.Profile.push({ Connections: data[key] });
          break;
        case 'followers_count':
          targetObj.Profile.push({ Followers: data[key] });
          break;

        case 'education':
          data[key].forEach(item => {
            const logo = item.logo ? `<img src="${item.logo}" class="circle-image"><br>` : '';
            const date = item.date_from + ' - ' + item.date_to + '<br>';
            const study = item.field_of_study || '';
            const degreeName = item.degree_name ? '<br>' + item.degree_name : '';
            const description = item.description ? '<br>' + item.description : '';
            const url = item.url ? '<br>' + item.url : '';
            targetObj.Education.push({ [logo + date + item.school_name]: study + degreeName + description + url });
          });
          break;

        case 'experience':
          data[key].forEach(item => {
            const logo = item.logo ? `<img src="${item.logo}" class="circle-image"><br>` : '';
            const date = item.date_from + ' - ' + item.date_to + '<br>';
            const location = item.location || '';
            const position = item.position || '';
            const countOfEmployers = item.employee_count_range ? '<br>' + 'Employers count ' + item.employee_count_range : '';
            const description = item.description ? '<br>' + item.description : '';
            const industries = item.industries?.length ? '<br>' + item.industries.reduce((str, current) => {
              return str + ', ' + current;
            }) : '';
            const companyUrl = item.company_url ? '<br>' + item.company_url : '';
            targetObj.Experience.push({
              [logo + date + item.company_name + location]: position + countOfEmployers + description + industries + companyUrl
            });
          });
          break;

        case 'volunteer_experience':
          data[key].forEach(item => {
            const logo = item.logo ? `<img src="${item.logo}" class="circle-image"><br>` : '';
            const date = item.date_from + ' - ' + (item.date_to || 'no date') + '<br>';
            const role = item.role || '';
            const cause = item.cause || '';
            const description = item.description ? '<br>' + item.description : '';
            targetObj['Volunteer expirience'].push({
              [logo + date + item.company_name]: role + cause + description
            });
          });
          break;

        case 'certificates':
          data[key].forEach(item => {
            const licenseNumber = item.licenseNumber ? '<br>' + 'License number ' + item.licenseNumber : '';
            const dateFrom = item.date_from ? '<br>' + item.date_from : '';
            const dateTo = item.date_to ? ' - ' + item.date_to : '';
            targetObj.Certificates.push({ [item.name]: item.company_name + licenseNumber + dateFrom + dateTo });
          });
          break;

        case 'websites':
          data[key].forEach(item => {
            const index = item.providerName ? item.providerName.indexOf('.') : -1;
            if (index !== -1) {
              item.providerName = item.providerName.substr(0, index);
            }
            targetObj.Links.push({ [item.providerName || item.category]: `<a href="${item.url}" target="_blank" class="color-theme">${item.title || item.url}</a>` });
          });
          break;

        case 'projects':
          data[key].forEach(item => {
            const dateFrom = item.date_from || '';
            const dateTo = item.date_to ? ' - ' + item.date_to : '';
            const url = item.url ? '<br>' + item.url : '';
            let str = dateFrom + dateTo + '<br>' + item.description + url;
            if (item.members?.length) {
              str = str + '<br><span>Members:</span>';
              item.members.forEach(member => {
                str = str + `<br><a href="${member.url}" target="_blank" class="color-theme">${member.first_name + ' ' + member.last_name}</a>`;
              });
            }
            targetObj.Projects.push({ [item.name]: str });
          });
          break;
      }
    }
  }
  if (!targetObj.Bio.length) {
    delete targetObj.Bio;
  }
  if (!targetObj.Contacts.length) {
    delete targetObj.Contacts;
  }
  if (!targetObj.Profile.length) {
    delete targetObj.Profile;
  }
  if (!targetObj.Education.length) {
    delete targetObj.Education;
  }
  if (!targetObj.Experience.length) {
    delete targetObj.Experience;
  }
  if (!targetObj['Volunteer expirience'].length) {
    delete targetObj['Volunteer expirience'];
  }
  if (!targetObj.Certificates.length) {
    delete targetObj.Certificates;
  }
  if (!targetObj.Links.length) {
    delete targetObj.Links;
  }
  if (!targetObj.Projects.length) {
    delete targetObj.Projects;
  }
  return targetObj;
}
