import { createStore } from 'vuex';
import results from './modules/results';
import confirmRegistration from './modules/confirmRegistration';
import authAndRegistration from './modules/authAndRegistration';
import tags from './modules/tags';
import connections from './modules/connections';
import connectionsHack from './modules/connectionsHack';
import cases from './modules/cases';
import report from './modules/report';
import edges from './modules/edges';
import products from './modules/products';
import map from './modules/map';
import connectionSearches from './modules/connectionSearches';
import multiprofile from './modules/multiprofile';
import userDetails from './modules/userDetails';
import monitoring from './modules/monitoring';

import resultsNew from './modules/resultsNew';
import connectionsNew from './modules/connectionsNew';
import casesNew from './modules/casesNew';
import rightSidebar from './modules/rightSidebar';
import tagsNew from './modules/tagsNew';
import profileResults from './modules/profileDetails';

import search from './modules/search';
import searchHack from './modules/searchHack';
import checkedValues from './modules/checkedValues';
import loaders from './modules/loaders';
import profileNew from './modules/profileNew';

let newStoreSearch = {
  resultsNew,
  connectionsNew,
  casesNew,
  rightSidebar,
  searchHack,
  tagsNew,
  profileResults,
  connectionsHack,
  profileNew
};
if (localStorage.getItem('newSearch') === 'false') {
  newStoreSearch = {
    connections,
    search
  };
}

export default createStore({
  state: {
    searching: false,
    searchText: 'Searching...',
    searchTimerID: null,
    hideSearch: true,
    loadUrl: false,
    openSubscribeModal: false,
    noSlPoints: false,
    noSubscribe: false,
    openIssuesModal: false,
    isRelationsSectionActive: false,
    smartLookConsentText: 'We can record your IP and use the API to identify you to provide better support.',
    checkedEntities: [],
    openShareModal: false,
    acceptSharingForm: false,
    caseSharingOwner: null,
    openCookieModal: !JSON.parse(localStorage.getItem('acceptedCookie')),
    loadingDirectUrl: false,
    selectedEntity: '',
    hideCheckboxes: false
  },
  mutations: {
    checkSelectedEntities (state, item) {
      if (item.checked) {
        state.checkedEntities.push(item);
        if (item?.fields?.relation?.value) {
          state.checkedEntities.forEach(element => {
            if (!element?.fields?.relation?.value) {
              element.checked = false;
              state.checkedEntities = state.checkedEntities.filter(el => el !== element);
            }
          });
        }
        if (!item?.fields?.relation?.value) {
          state.checkedEntities.forEach(element => {
            if (element?.fields?.relation?.value) {
              element.checked = false;
              state.checkedEntities = state.checkedEntities.filter(el => el !== element);
            }
          });
        }
      } else {
        state.checkedEntities = state.checkedEntities.filter(el => el !== item);
      }
    },
    checkedTagEntities (state, item) {
      state.checkedEntities.push(item);
    },
    activateRelationsSection (state, value) {
      state.isRelationsSectionActive = value;
    },
    selectEntity (state, value) {
      state.selectedEntity = value;
    },
    openIssuesModal (state, value) {
      state.openIssuesModal = value;
    },
    clearSearchTimer (state) {
      clearTimeout(state.searchTimerID);
    },
    setSearching (state, value) {
      state.searchText = 'Searching...';
      state.searching = value;
      let timerID;
      if (value) {
        state.searchTimerID = timerID = setTimeout(() => {
          if (state.searchTimerID === timerID) {
            state.searchText = 'Looks like it’s taking a while to get your results, please wait';
          }
        }, 15000);
      }
    },
    hideSearch (state, value) {
      state.hideSearch = value;
    },
    setLoadUrl (state, value) {
      state.loadUrl = value;
    },
    setConditionsOfSubscribeModal (state, value) {
      state.openSubscribeModal = value;
    },
    setNoSlPoints (state, value) {
      state.noSlPoints = value;
    },
    setNoSubscribe (state, value) {
      state.noSubscribe = value;
    },
    setLoadingDirectUrl (state, value) {
      state.loadingDirectUrl = value;
    },
    setOpenShareModal (state, value) {
      state.openShareModal = value;
    },
    acceptSharingForm (state, value) {
      state.acceptSharingForm = value;
    },
    setCaseSharingOwner (state, value) {
      state.caseSharingOwner = value;
    },
    SET_HIDE_CHECKBOXES (state, value) {
      state.hideCheckboxes = value;
    }
  },
  getters: {
    openIssuesModal (state) {
      return state.openIssuesModal;
    },
    getSearching (state) {
      return state.searching;
    },
    loadUrl (state) {
      return state.loadUrl;
    },
    openSubscribeModal (state) {
      return state.openSubscribeModal;
    },
    getSubscribe (state) {
      return state.noSubscribe;
    },
    noSlPoints (state) {
      return state.noSlPoints;
    },
    searchText (state) {
      return state.searchText;
    },
    openShareModal (state) {
      return state.openShareModal;
    },
    acceptSharingForm (state) {
      return state.acceptSharingForm;
    },
    caseSharingOwner (state) {
      return state.caseSharingOwner;
    },
    hideCheckboxes (state) {
      return state.hideCheckboxes;
    }
  },
  actions: {
    async enoughBalance ({ rootState, commit }, cost) {
      if (+rootState.authAndRegistration.account.balance < cost) {
        commit('setNoSlPoints', true);
        commit('setConditionsOfSubscribeModal', true);
        return false;
      }
      return true;
    }
  },
  modules: {
    results,
    confirmRegistration,
    authAndRegistration,
    tags,
    cases,
    report,
    edges,
    products,
    connectionSearches,
    map,
    multiprofile,
    userDetails,
    monitoring,
    checkedValues,
    loaders,

    ...newStoreSearch
  }
});
