export default {
  state: {
    tags: [],
    tagColors: ['#CC73E1', '#65D938', '#15B1F3', '#EBBB01', '#A2845E', '#FF2967', '#FF9501', '#0DE396', '#4255FF', '#FF87AB'],
    loading: false,
    showAllSavedInTags: false,
    openSavedInTagsPage: false,
    tagsLoading: false,
    savedInTagsProfiles: [],
    savedInTagsConnections: []
  },
  mutations: {
    addProfileToSavedInTagsPage (state, profile) {
      state.savedInTagsProfiles.push(profile);
    },
    addConnectionToSavedInTagsPage (state, edge) {
      state.savedInTagsConnections.push(edge);
    },
    clearAllSavedInTagsEntities (state) {
      state.savedInTagsProfiles = [];
      state.savedInTagsConnections = [];
    },
    openSavedInTagsPage (state, value) {
      state.openSavedInTagsPage = value;
    },
    setTagsLoading (state, value) {
      state.tagsLoading = value;
    },
    showAllSavedInTags (state, value) {
      state.showAllSavedInTags = value;
    },
    updateTags (state, tags) {
      state.tags = tags;
    },
    editTag (state, tagInfo) {
      state.tags.forEach(tag => {
        if (tag.oid === tagInfo.id) {
          tag.name = tagInfo.name;
        }
      });
    },
    setLoading (state, value) {
      state.loading = value;
    },
    uncheckTags (state) {
      state.tags.forEach(tag => {
        tag.checked = false;
      });
    },
    checkTag (state, oid) {
      state.tags.forEach(tag => {
        if (tag.oid === oid) {
          tag.checked = !tag.checked;
        }
      });
    }
  },
  getters: {
    tagsLoading (state) {
      return state.tagsLoading;
    },
    savedInTagsProfiles (state) {
      return state.savedInTagsProfiles;
    },
    savedInTagsConnections (state) {
      return state.savedInTagsConnections;
    },
    getTags (state) {
      return state.tags;
    },
    getLoader (state) {
      return state.loading;
    },
    notEmptyTags (state, getters, rootState) {
      const set = new Set();
      const addTag = (entity) => {
        if (entity.tags && entity.tags.length) {
          entity.tags.forEach(tag => {
            set.add(tag);
          });
        }
      };
      const addTags = (entities) => {
        if (Array.isArray(entities)) {
          entities.forEach(ent => { addTag(ent); });
        } else {
          Object.keys(entities).forEach(oid => { addTag(entities[oid]); });
        }
      };
      addTags(rootState.results.results);
      rootState.results.results.forEach(ent => { addTags(ent.children || []); });
      addTags(getters.connectionEntitiesMap);
      addTags(getters.edgesMap);
      const arr = [];
      if (state.tags && state.tags.length) {
        state.tags.forEach(tag => {
          if (set.has(tag.oid)) {
            arr.push(tag);
          }
        });
      }
      return arr;
    }
  },
  actions: {
    async getUserTags (ctx) {
      try {
        const payload = {
          method: 'GET',
          url: '/api/user/tags'
        };
        const tags = await ctx.dispatch('ajaxWithTokenRefresh', payload);
        const tagsWithColor = await ctx.dispatch('setTagColors', tags.data);
        tags.data.forEach(tag => {
          tag.checked = false;
        });
        ctx.commit('updateTags', tagsWithColor);
      } catch (error) {
        console.error(error);
      }
    },
    async editTag (ctx, tag) {
      try {
        const payload = {
          method: 'PUT',
          url: '/api/user/tags',
          body: {
            id: tag.id,
            name: tag.name
          }
        };
        await ctx.dispatch('ajaxWithTokenRefresh', payload);
        ctx.commit('editTag', payload.body);
      } catch (error) {
        console.error(error);
      }
    },
    setTagColors (ctx, tags) {
      let colorIndex = 0;
      tags.forEach(tag => {
        tag.color = ctx.state.tagColors[colorIndex];
        colorIndex++;
        if (colorIndex >= ctx.state.tagColors.length) {
          colorIndex = 0;
        }
      });
      return tags;
    },
    async getUserTagsData (ctx, id) {
      try {
        const payload = {
          method: 'GET',
          url: `/api/user/tags/tagged/${id}`
        };
        const tagsWithData = await ctx.dispatch('ajaxWithTokenRefresh', payload);
        const results = await ctx.dispatch('convertEntityFieldsToMap', tagsWithData.data.nodes);
        results.forEach(res => {
          res.checked = false;
          ctx.commit('addProfileToResultsMap', res);
        });
        ctx.commit('addResultsFromTags', results);
      } catch (error) {
        console.error(error);
      }
    }
  }
};
