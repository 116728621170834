<template>
  <div>
    <RightSidebarProfile
      v-if="getProfileParent"
      :profile="getProfileParent"
      :class="{
        'hide': !getVisibleSidebarParent,
        'show': getVisibleSidebarParent
      }"
    />
    <RightSidebarProfile
      v-if="getProfileChild"
      :profile="getProfileChild"
      :class="{
        'hide': !getVisibleSidebarChild,
        'show': getVisibleSidebarChild
      }"
      child="true"
    />

    <div
      @click="setCloseRightSidebar"
      class="p-fixed z-1001 top-0 left-0 w-100 h-100 bg-form-darker opacity-8 transition"
      :class="{ 'd-none': !getVisibleSidebar }"
    ></div>
  </div>
</template>

<script>
import RightSidebarProfile from '@/components/right-sidebar/Profile';
import { mapActions, mapGetters } from 'vuex';

export default {
  components: {
    RightSidebarProfile
  },
  watch: {
    $route () {
      this.setCloseRightSidebar();
    }
  },
  computed: {
    ...mapGetters(['getVisibleSidebar', 'getVisibleSidebarChild', 'getVisibleSidebarParent', 'getProfileParent', 'getProfileChild'])
  },
  methods: {
    ...mapActions(['setCloseRightSidebar'])
  }
};
</script>
