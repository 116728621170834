<template>
  <div class="cookie-form d-flex align-center justify-between" v-if="$store.state.openCookieModal">
    <div>
      <h3>We use cookies to collect information about the service's usability</h3>
      <p>It includes data files that are placed on your device or computer and include an anonymous unique identifier.</p>
    </div>
    <div class="d-flex">
      <button class="cookie-accept mr-15" @click="cookieAccept"><Icon name="ok" /></button>
    </div>
  </div>
  <div id="layout" style="display: block;">
    <div class="grid-layout p-fixed z-1003 top-0 left-0 right-0">
      <SidebarHeader />
      <HeaderMain />
    </div>
    <router-view />
    <SendModal
      v-if="getCaseToRemove"
      :title="`Are you sure you want to delete ${getCaseToRemove.tag}?`"
      confirmText="Yes"
      declineText="No"
      test-info="confirm-delete"
      @closeModal="setCaseToRemove(null)"
      @confirmAnswer="setCaseToRemove()"
    />
    <div id="tag-modal"></div>
    <div class="loader-block" v-if="getLoaderBig">
      <img src="@/assets/icons/searching.gif" alt="" class="h-78">
    </div>
    <Feature visible="VUE_APP_NSA_1684">
      <ProfileModal />
    </Feature>
    <RightSidebar />
  </div>
  <div id="no-mobile">
    <img src="@/assets/images/no-mobile.png" alt="" class="mb-40">
    <span class="typography-4 color-theme">
      Unfortunately, the service does not support the mobile version and does not work on screens with a width of less than 1150 pixels
    </span>
  </div>
</template>

<script>
import Icon from '@/components/app/Icon';
import HeaderMain from '@/components/app/HeaderMain';
import SidebarHeader from '@/components/SidebarHeader';
import Feature from '@/components/app/Feature';
import SendModal from '@/components/UI/SendModal';
import ProfileModal from '@/components/profile/Modal';
import RightSidebar from '@/components/right-sidebar/Template';
import { mapActions, mapGetters } from 'vuex';

export default {
  components: {
    Icon,
    HeaderMain,
    SidebarHeader,
    Feature,
    SendModal,
    ProfileModal,
    RightSidebar
  },
  mounted () {
    if (this.$store.state.openCookieModal) {
      setTimeout(() => {
        if (document.querySelector('.intercom-lightweight-app-launcher')) {
          document.querySelector('.intercom-lightweight-app-launcher').style.bottom = 90 + 'px';
        }
      }, 200);
    }
  },
  computed: {
    ...mapGetters(['getCaseToRemove', 'getLoaderBig'])
  },
  methods: {
    ...mapActions(['setCaseToRemove']),

    cookieAccept () {
      this.$store.state.openCookieModal = false;
      localStorage.setItem('acceptedCookie', true);
      document.querySelector('.intercom-lightweight-app-launcher').style.bottom = 20 + 'px';
    }
  }
};
</script>
