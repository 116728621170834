import { createApp } from 'vue';
import App from './App.vue';
import routerOld from './router';
import routerNew from './router/indexNew.js';
import * as Sentry from '@sentry/vue';
import { Integrations } from '@sentry/tracing';
import store from './store';
import axios from 'axios';
import VueAxios from 'vue-axios';
import linkify from 'vue-linkify';
import PrimeVue from 'primevue/config';
import amplitude from 'amplitude-js';

import { convertDate } from '@/utils/helpers';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

import './scss/grid.scss';
import 'intl-tel-input/build/css/intlTelInput.css';
import './css/flex-grid.css';
import './scss/style.scss';

import 'primevue/resources/themes/saga-blue/theme.css';
import 'primevue/resources/primevue.min.css';
import 'primeicons/primeicons.css';

let router = routerNew;
if (localStorage.getItem('newSearch') === 'false') router = routerOld;

const app = createApp(App);

Sentry.init({
  app,
  dsn: 'https://92db0711908c4827b74c5e695042f977@sentry.sl-int.team/2',
  release: `gamayun-frontend@${convertDate(Date.now())}`,
  environment: process.env.VUE_APP_SENTRY_ENVIRONMENT,
  integrations: [
    new Integrations.BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      tracingOrigins: ['gamayun-testing.sl-int.team', 'gamayun.sociallinks.io', /^\//]
    })
  ],
  tracesSampleRate: 1.0
});

amplitude.getInstance().init(process.env.VUE_APP_AMPLITUDE_KEY);

app
  .use(store)
  .use(router)
  .use(VueAxios, axios)
  .use(PrimeVue)
  .directive('linkified', linkify)
  .component('font-awesome-icon', FontAwesomeIcon)
  .mount('#app');
