<template>
  <div class="pb-24">
    <button
      @click="opened = !opened"
      class="d-flex align-center text-base"
    >
      <span class="color-text-darker fw-600 mr-6">{{ title }}</span>
      <Icon
        class="color-link"
        :class="{
          'rotate-180': opened
        }"
        name="arrow-down"
      />
    </button>
    <div
      v-show="opened"
      class="br-5 overflow-hidden mt-12"
    >
      <ProfileInfoContent
        v-for="(content, index) in tab"
        :key="index"
        :index="index + 1"
        :content="content"
        :showTitle="showTitle"
      />
    </div>
  </div>
</template>

<script>
import Icon from '@/components/app/Icon';
import ProfileInfoContent from '@/components/right-sidebar/ProfileInfoContent';

export default {
  components: {
    Icon,
    ProfileInfoContent
  },
  props: {
    title: {
      default: ''
    },
    tab: {
      default: []
    },
    showTitle: {
      default: true
    }
  },
  data () {
    return {
      opened: true
    };
  }
};
</script>
