export class ConnectionRequest {
  constructor (sourceProfile) {
    this.sourceProfile = sourceProfile;
    this.params = { query: sourceProfile.value };
    this.setParams();
    this.socialNet = '';
  }

  setParams () {
    this.params.query = this.sourceProfile.value;
  }

  get url () {
    return `/proxy/socializer/${this.socialNet}/connections?` + new URLSearchParams(this.params).toString();
  }
}

export class TwitterConnectionRequest extends ConnectionRequest {
  constructor (sourceProfile) {
    super(sourceProfile);
    this.socialNet = 'twitter';
  }

  setParams () {
    const twitterID = this.sourceProfile.fields?.['twitter.id'];
    if (twitterID) this.params.query = twitterID.value;
  }
}

export class FacebookConnectionRequest extends ConnectionRequest {
  constructor (sourceProfile) {
    super(sourceProfile);
    this.socialNet = 'facebook';
  }

  setParams () {
    this.params.timeout = 30;
    this.params.delayed = 1;
    this.params.return_found_result = 1;
    this.params.queue_name = 'gamayun-facebook-many-threads';
  }
}

export class InstagramConnectionRequest extends ConnectionRequest {
  constructor (sourceProfile) {
    super(sourceProfile);
    this.socialNet = 'instagram';
  }
}

export class LinkedInConnectionRequest extends ConnectionRequest {
  constructor (sourceProfile) {
    super(sourceProfile);
    this.socialNet = 'linkedin';
  }
}
