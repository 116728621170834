import { TinyEmitter } from 'tiny-emitter';
import { unicodeEmail } from './punycoder';
import moment from 'moment';

// Shared application event bus
export const eventListener = new TinyEmitter();

export function getReadableSearchQuery (queryName, query) {
  if (queryName === 'email') {
    return unicodeEmail(query);
  }
  if (queryName === 'image' && (query.startsWith(process.env.VUE_APP_IMAGES_SERVER_PROD) || query.startsWith(process.env.VUE_APP_IMAGES_SERVER_TEST))) {
    return decodeURI(query.split('/').pop());
  }
  return query;
}

// old

export function parseFacebookLocation (text) {
  const index = text.value.indexOf('Lives in');
  if (index !== -1) {
    let location = text.value.substr(index);
    const cutIndex = location.indexOf('###');
    if (cutIndex !== -1) {
      location = location.substring(0, cutIndex);
    }
    location = location.substr(9);
    return location;
  }
  return '';
}

export function getLocation (entity) {
  const fields = entity.fields;
  const userDetails = entity.fields['user-details'];

  if (fields.location) {
    return fields.location.value;
  }
  if (entity?.location?.name) {
    return entity.location.name;
  }
  if (fields.lives) {
    return fields.lives.value;
  }
  if (fields.location_name) {
    return fields.location_name.value;
  }
  if (userDetails && userDetails.value.length) {
    const parsedDetails = JSON.parse(userDetails.value).Bio.find(i => i.Location);
    if (parsedDetails) {
      return parsedDetails.Location;
    }
  }

  if (fields.info) {
    return parseFacebookLocation(fields.info);
  }

  const typeID = entity.typeid;
  if (
    typeID === 'maltego.Location' ||
    typeID === 'maltego.city' ||
    typeID === 'maltego.country' ||
    typeID === 'maltego.Location.offshore' ||
    typeID === 'maltego.offshore.address' ||
    typeID === 'maltego.place'
  ) {
    return entity.value;
  }
  return '';
}

export function getEntityName (entity) {
  try {
    const name = entity.fields.name || entity.fields['person.name'];
    return (name) ? name.value : entity.value;
  } catch (e) {
    return '';
  }
}

export function getEntityAvatar (entity) {
  try {
    const avatar = entity.fields['profile-image'];
    if (entity.typeid === 'maltego.instagram.profile') {
      return document.location.origin + '/proxy/cors/' + avatar.value;
    }
    return avatar && avatar.value;
  } catch (e) {
    return '';
  }
}

export function getEntitySocialNetIcon (entity) {
  const typeID = entity.typeid.toLowerCase();
  if (typeID.includes('facebook')) {
    return 'facebook-f';
  }
  if (typeID.includes('twitter')) {
    return 'twitter';
  }
  if (typeID.includes('instagram')) {
    return 'instagram';
  }
  if (typeID.includes('linkedin')) {
    return 'linkedin-in';
  }
  return '';
}

export function getEntityUrl (entity) {
  const name = entity.fields.url || entity.fields.surl || entity.fields['affiliation.profile-url'] || entity.fields['surl-0'];
  return name?.value;
}
// end old

// new

export function parseFacebookLocationNew (text) {
  const index = text.indexOf('Lives in');
  if (index !== -1) {
    let location = text.substr(index);
    const cutIndex = location.indexOf('###');
    if (cutIndex !== -1) {
      location = location.substring(0, cutIndex);
    }
    location = location.substr(9);
    return location;
  }
  return '';
}

export function getProfileLocation (entity) {
  const fields = entity.fields;
  const userDetails = entity.fields['user-details'];

  if (fields.location) {
    return fields.location;
  }
  if (entity?.location?.name) {
    return entity.location.name;
  }
  if (fields.lives) {
    return fields.lives;
  }
  if (fields.location_name) {
    return fields.location_name;
  }
  if (userDetails && userDetails.length) {
    const parsedDetails = JSON.parse(userDetails).Bio.find(i => i.Location);
    if (parsedDetails) {
      return parsedDetails.Location;
    }
  }

  if (fields.info) {
    return parseFacebookLocationNew(fields.info);
  }

  const typeID = entity.typeid;
  if (
    typeID === 'maltego.Location' ||
    typeID === 'maltego.city' ||
    typeID === 'maltego.country' ||
    typeID === 'maltego.Location.offshore' ||
    typeID === 'maltego.offshore.address' ||
    typeID === 'maltego.place'
  ) {
    return entity.value;
  }
  return '';
}

export function getProfileName (entity) {
  try {
    const name = entity.fields.name || entity.fields['person.name'];
    return (name) || entity.value;
  } catch (e) {
    return '';
  }
}

export function getProfileAvatar (entity) {
  try {
    const avatar = entity.fields['profile-image'] || entity.fields.photo || entity.fields.image;
    if (entity.typeid === 'maltego.instagram.profile') {
      return document.location.origin + '/proxy/cors/' + avatar;
    }
    return avatar;
  } catch (e) {
    return '';
  }
}

export function getProfileSocialNetIcon (entity) {
  const typeID = entity.typeid.toLowerCase();
  if (typeID.includes('facebook')) {
    return 'facebook-f';
  }
  if (typeID.includes('twitter')) {
    return 'twitter';
  }
  if (typeID.includes('instagram')) {
    return 'instagram';
  }
  if (typeID.includes('linkedin')) {
    return 'linkedin-in';
  }
  return '';
}

export function getProfileUrl (entity) {
  const name = entity.fields.url || entity.fields.surl || entity.fields['affiliation.profile-url'] || entity.fields['surl-0'];
  return name;
}

// end new

export function dateFormat (val) {
  if (String(val).length === 10) {
    val = val * 1000;
  }
  return moment(val).startOf(val).fromNow();
}

export function exactDateFormat (val) {
  if (String(val).length === 10) {
    val = val * 1000;
  }
  const date = new Date(val);
  const currentMonth = date.getMonth() + 1;

  const years = date.getFullYear();
  const months = (currentMonth < 10 ? '0' : '') + currentMonth;
  const days = (date.getDate() < 10 ? '0' : '') + date.getDate();
  const hours = (date.getHours() < 10 ? '0' : '') + date.getHours();
  const minutes = (date.getMinutes() < 10 ? '0' : '') + date.getMinutes();

  return `${days}.${months}.${years} ${hours}:${minutes}`;
}

export function convertDate (val) {
  if (String(val).length === 10) {
    val = val * 1000;
  }
  const date = new Date(val);
  const currentMonth = date.getMonth() + 1;

  const years = date.getFullYear();
  const months = (currentMonth < 10 ? '0' : '') + currentMonth;
  const days = (date.getDate() < 10 ? '0' : '') + date.getDate();
  const hours = (date.getHours() < 10 ? '0' : '') + date.getHours();
  const minutes = (date.getMinutes() < 10 ? '0' : '') + date.getMinutes();

  return `${years}.${months}.${days}+${hours}.${minutes}`;
}

export function dateConstructor (val, str) {
  if (String(val).length === 10) {
    val = val * 1000;
  }
  const date = new Date(val);
  const currentMonth = date.getMonth() + 1;

  const getDate = {
    years: date.getFullYear(),
    months: (currentMonth < 10 ? '0' : '') + currentMonth,
    days: (date.getDate() < 10 ? '0' : '') + date.getDate(),
    hours: (date.getHours() < 10 ? '0' : '') + date.getHours(),
    minutes: (date.getMinutes() < 10 ? '0' : '') + date.getMinutes()
  };

  let resultDate = str;
  const matches = str.match(/\{[^}]+}/g);
  matches.forEach(m => {
    const match = m.replace('{', '').replace('}', '');
    resultDate = resultDate.replace(m, getDate[match]);
  });

  return resultDate;
}

export function clearingString (str) {
  if (typeof (str) === 'string') {
    str = str.split('<br>').join(' ');
    str = str.split('<span>').join(' ');
    str = str.split('</span>').join(' ');
    str = str.split('<b>').join(' ');
    str = str.split('</b>').join(' ');
    str = str.split('</a>').join(' ');

    // Deleting the <A> tag
    let pos = 0;
    while (true) {
      const startPos = str.indexOf('<a', pos);
      if (startPos === -1) break;
      const endPos = str.indexOf('>', startPos);
      pos = startPos;
      str = str.slice(0, startPos) + str.slice(endPos + 1, str.length);
    }
  }
  return str;
}

export function arrayToObject (arr, target, key) {
  const newObj = {};
  arr[target].forEach(t => {
    const obj = {};
    obj[t[key]] = t.value;
    Object.assign(newObj, obj);
  });

  arr[target] = newObj;
}
